import React, {Component} from 'react';
import ScrollUpButton from 'react-scroll-up-button';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Button,
  Badge,
} from 'reactstrap';
import ResultsBreadcrumbs from './ResultsBreadcrumbs';
import HelpModal from './HelpModal';
import localization from '../../lib/localization';
import ReactGA from 'react-ga';
import TeacherStatistics from './TeacherStatistics';
import QuestionTab from './QuestionTab';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCommentDots} from '@fortawesome/free-solid-svg-icons';
import SemesterOverview from './SemesterOverview';
import CardTooltip from './CardTooltip';

class TeacherDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  handleExpandAll = () => {
    this.setState({
      showAll: true,
    });
  };

  render() {
    if (this.props.isLoading) {
      return (
        <>
          <Row className='justify-content-center'>
            <Spinner color='primary' />
          </Row>
        </>
      );
    } else
      return (
        <>
          <ScrollUpButton style={{top: 30, zIndex: 1500}} />
          <Row>
            <Col>
              <ResultsBreadcrumbs
                teacherName={this.props.teacher.fullName}
                surveyName={this.props.surveyName}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='float-right'>
                <div style={{display: 'inline-flex'}}>
                  {/* <ReactSelect className='smaller-select' /> */}
                  {/* <HelpModal
                    className='ml-3'
                    markdownContent={localization('courses_help_markdown')}
                  /> */}
                </div>
              </div>

              <h2>
                {this.props.teacher.fullName}{' '}
                <Badge color='primary' className='ml-2'>
                  {this.props.teacher.userName}
                </Badge>
              </h2>
            </Col>
          </Row>
          {/* <Row className='mt-3'>
              <Col>
                <Card>
                  <CardHeader>Obsah</CardHeader>
                  <CardBody>aa</CardBody>
                </Card>
              </Col>
            </Row> */}
          <Row className='mt-3'>
            <Col>
              <TeacherStatistics
                averageRating={this.props.teacher.averageRating}
                series={this.props.series}
              />
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col>
              <SemesterOverview
                isMe={this.props.isTeacher}
                myReaction={this.props.reaction}
                onAddSemesterReaction={this.props.onAddSemesterReaction}
                onDeleteSemesterReaction={this.props.onDeleteSemesterReaction}
                teacher={this.props.teacher}
                semesterCode={this.props.semesterCode}
                survey={this.props.surveyId}
              />
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col>
              <Card id='questions'>
                <CardHeader>
                  <FontAwesomeIcon
                    icon={faCommentDots}
                    size='lg'
                    className='mr-2'
                  />
                  {localization('course_detail_questions')}
                  <span className='float-right'>
                    <Button
                      onClick={this.handleExpandAll}
                      color='primary'
                      size='sm'
                      className='mr-3'
                      disabled={this.state.showAll}
                    >
                      {localization('show_all')}
                    </Button>
                    <CardTooltip
                      content={localization('tooltip_teacher_questions')}
                      id='tooltip-teacher-questions'
                    />
                  </span>
                </CardHeader>
                <CardBody>
                  {this.props.teacher.courses.length !== 0
                    ? this.props.teacher.courses.map((q, i) => (
                        <Row
                          className='mt-0'
                          id={`question-${i}`}
                          key={`question-${i}`}
                        >
                          <Col>
                            <QuestionTab
                              noVoting
                              showAll={this.state.showAll}
                              onChangeVote={this.props.onChangeVote}
                              question={q.name}
                              answers={q.answers}
                              onAddReaction={this.props.onAddReaction}
                              questionNumber={i}
                              isTeacher={this.props.isTeacher}
                              userName={this.props.userName}
                              onDelete={this.props.onDelete}
                              survey={this.props.surveyId}
                              semesterCode={this.props.semesterCode}
                              onDelete={this.props.onDelete}
                              courseCode={q.code}
                            />
                          </Col>
                        </Row>
                      ))
                    : ''}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      );
  }
}

export default TeacherDetail;
