import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Alert} from 'reactstrap';
import {withRouter} from 'react-router-dom';
import localization from '../lib/localization';
import {formatSurvey} from '../lib/stringUtils';
import config from '../config';
import ReactGA from 'react-ga';

class SurveysOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertIsOpen: this.props.location.search.includes('?alert'),
    };
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.title = `${localization('sideNav_mainPage')} | ${localization(
      'title'
    )}`;
  }

  closeAlert = (e) => {
    this.setState({alertIsOpen: false});
  };

  renderSurveys = (type) => {
    const list = [];
    this.props.surveys.surveys
      .filter((e) => e.type === type)
      .forEach((e) => {
        let formated = formatSurvey(e);
        list.push(
          <li
            key={formated.id}
            className='list-group-item list-group-item-action d-flex justify-content-between align-items-center'
          >
            <Link to={'/surveys/' + formated.id}>{formated.longName}</Link>
          </li>
        );
      });
    if (list.length === 0) {
      list.push(
        <li
          key='default'
          className='list-group-item list-group-item-action d-flex justify-content-between align-items-center'
        >
          {localization('surveys_noActiveSurvey')}
        </li>
      );
    }
    return list;
  };

  render() {
    return (
      <div>
        <Alert
          color='success'
          isOpen={this.state.alertIsOpen}
          toggle={this.closeAlert}
        >
          {localization('alerts_survey_send_success')}
        </Alert>
        <h3>{localization('surveys_subject_group_heading')}</h3>
        <ul className='list-group my-3'>
          {this.renderSurveys(config.courseSurveyTypeName)}
        </ul>
        <h3>{localization('surveys_general_group_heading')}</h3>
        <ul className='list-group my-3'>
          {this.renderSurveys(config.generalSurveyTypeName)}
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    surveys: state.surveys,
    refresh: state.refresh,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SurveysOverview)
);
