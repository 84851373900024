import React, { Component } from 'react';
import PictoRating from './PictoRating';
import { Tooltip } from 'reactstrap';
import help from '../Assets/help.svg';

class QuestionsValue extends Component {

    constructor(props) {
        super(props);
        let toggleState = {};
        props.questions.forEach(e => {
            toggleState[e.id] = false;
        });
        this.state = {
            isOpen: toggleState
        }
    }

    toggle = id => {
        const newOpen = Object.assign({}, this.state.isOpen)
        newOpen[id] = !this.state.isOpen[id]
        this.setState({
            isOpen: newOpen
        })
    }

    renderTooltip = q => {
        if (q.textHelp !== null && q.textHelp !== undefined) {
            return (
                <div>
                    <img alt="" style={{width: "20px", float: "left"}} src={help} id={"tooltipHelp_" + q.id}></img>
                    <Tooltip isOpen={this.state.isOpen[q.id]} target={"tooltipHelp_" + q.id} placement="right" toggle={() => this.toggle(q.id)}>{q.textHelp}</Tooltip>
                </div>
            )
        }
    }

    renderQuestions = () => {
        const {questions, handleClickRating, primaryOnly} = this.props;
        const list = [];
        questions.sort((a, b) => a.priority - b.priority).forEach(e => {
            if (e.isValue && !e.isProf && (!primaryOnly || e.isPrimary)) {
                list.push(
                    <div key={e.id} className="form-group row overall-rating">
                        <div className="col-lg-7 col-form-label">
                            <label className={e.isPrimary ? "font-weight-bold" : ""} style={e.textHelp !== null && e.textHelp !== undefined ? {float: "left"} : {}} >{e.textName}</label>
                            {this.renderTooltip(e)}
                        </div>
                        <div className="col">
                            <PictoRating selected={e.value} handleClick={handleClickRating(e.id)}/>
                        </div>
                    </div>
                )
            }
        });
        return list;
    }

    render() {
        return (
            <div>
                {this.renderQuestions()}
            </div>
        );
    }
}

export default QuestionsValue;