import * as actionTypes from '../Actions/actionTypes';
import Axios from 'axios';
import { dangerAction } from '../Actions/alertActions';
import { localize } from '../lib/localization';

export const getTeachers = dispatch => {
   return () => {
        dispatch({
            type: actionTypes.TEACHERS_LOADING
        });
        return Axios.get('/teachers')
        .then(res => {
            const converted = res.data.data.map(mapTeacher);
            dispatch({
                type: actionTypes.TEACHERS_LOADED,
                data: converted
            });
        })
        .catch( err => {
            if(err.response.status === 401){
                dispatch(dangerAction(localize("alerts_not_authenticated")))
            }
            else {
                dispatch(dangerAction(localize("alerts_connection_failure")));
            }
        });
    }
}

const mapTeacher = data => {
    return {
        id: data.id.toString(),
        name: data.fullName
    }
}