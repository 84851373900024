import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBriefcase} from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import localization from '../../lib/localization';
import {Link} from 'react-router-dom';

class QuestionCommentTeacherReactions extends Component {
  render() {
    return (
      <div className='media comment ml-1 mt-3 teacher-comment'>
        <div className='media-body'>
          <div className='mb-1'>
            <small>
              <Link
                to={`/results/semesters/${this.props.semester}/surveys/${this.props.survey}/teachers/${this.props.teacherId}`}
              >
                <FontAwesomeIcon
                  size='sm'
                  icon={faBriefcase}
                  className='mr-1'
                />{' '}
                {this.props.teacherName}
              </Link>
            </small>
          </div>
          <ReactMarkdown
            source={this.props.markdown}
            className='markdown-body'
          />

          {/* <Voting /> */}
          {this.props.showDelete ? (
            <div className='comment-actions'>
              <small>
                <a
                  href='javascript:void(0)'
                  className='ml-1 mr-1'
                  onClick={() => {
                    this.props.onDelete();
                  }}
                >
                  {localization('delete')}
                </a>
              </small>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

export default QuestionCommentTeacherReactions;
