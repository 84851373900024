 import * as actionTypes from '../Actions/actionTypes';
 
 const defaultState = {
    textOptions: [],
    loaded: false
 };

 const textOptions = (state = defaultState, action) => {
     switch(action.type) {
         case actionTypes.TEXT_OPTIONS_LOADED: {
             return {
                 textOptions: action.data,
                 loaded: true
             };
         }
         default: {
             return state;
         }
     }
 }

 export default textOptions;