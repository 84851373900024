import config from '../config';

const locale_EN = {
  locale: 'en',
  language_name: 'English',
  title: 'Survey',
  user_logout_button: 'Logout',
  user_login_button: 'Login',
  user_login_sso: 'Login',
  error_404_heading: 'Page not found',
  error_404_button: 'home',
  footer_copyright: '2019 Anketa ČVUT',
  footer_helpLink: 'How it works',
  footer_contact_text: 'Help improve the site',
  footer_contact_link: 'Contact developer.',
  sideNav_mainPage: 'Overview',
  sideNav_homepage: 'Home',
  sideNav_results: 'Results',
  surveys_subject_allSubjectsRated: 'All subjects are rated',
  surveys_noActiveSurvey: 'No more surveys',
  surveys_subject_group_heading: 'Subjects surveys',
  surveys_general_group_heading: 'Other surveys',
  alerts_survey_send_success: 'Answers sent successfully',
  alerts_connection_failure: 'Connection error!',
  alerts_not_authenticated: 'Alert text here',
  surveys_teachers_dropdown_noItems: 'no more teachers available',
  surveys_teachers_dropdown_text: 'Add teacher',
  surveys_answer_anonymity_warnign:
    'Only {0} students found with those settings', // number of students in combination
  surveys_answer_textField_teacher_toAdd_text: 'Add text for {0}', // teacher name
  surveys_answer_textField_question_toAdd_text: 'Add text for {0}', // question text name
  surveys_answer_textField_toggles_heading: 'Reveal:',
  surveys_answer_textField_toggles_name: 'Name',
  surveys_answer_textField_toggles_department: 'Department',
  surveys_answer_textField_toggles_year: 'Year of study',
  surveys_answer_textField_toggles_marks: 'Marks average',
  surveys_answer_textField_toggles_role: 'Role in study plan',
  surveys_subject_title: 'Evaluating',
  surveys_buttons_submit: 'Submit',
  surveys_buttons_delete: 'clear filled data',
  surveys_modal_warning:
    "You won't be able to return after submitting the survey. Are you sure you want to proceed?",
  subject_buttons_save: 'Save for later',
  markdown_editor: {
    write: 'Write',
    preview: 'Preview',
  },
  chars_remaining_1: '',
  chars_remaining_2: ' characters remaining.',
  chars_remaining_help: 'Help',
  chars_remaining_markdown_tutorial:
    'Only the commands that are provided by the editor are supported. Hyperlinks not ending at .cvut.cz domain will be deleted.',
  landing_page_markdown: `
## News:

## Students and teachers

- **[Please proceed to SSO login](${process.env.REACT_APP_URL_SUBDIR}/login)**

## [Survey information](https://anketa.cvut.cz/reports/)

- Plan of opening and closing of surveys for faculties
- Questions
- Courses in surveys
- Teachers in surveys
- Percentage of filled questionnaires
`,
  homepage_markdown: `
## News:


## Students

- **Overview** - here You see all available questionaries
- Surveys for each faculty **are not opened simultaneously**.
  - look at reports (link bellow) to see plan for Your faculty
  - some surveys are in mode "for finished**, so You can only see questionaries for already finshed courses.
- Only not yet filled questionaries are displayed to You.


## [Survey information](https://anketa.cvut.cz/reports/)

- Plan of opening and closing of surveys for faculties
- Questions
- Courses in surveys
- Teachers in surveys
- Percentage of filled questionnaires


## Teachers, management, (students) - results

- At the moment we have implemented only interface to fill questionnaires for students.
- You can sign in to the survey application using SSO, but there is nothing interesting related to your roles.
- In the next phase of the project - july 2019 - you will be able to see the results and submit comments.
`,
  landing_title: 'Survey ČVUT',
  remains: 'remaining',
  semesters_help_markdown: `# New version of questionary results
Welcome to the new interface for viewing CTU survey results. If you don't know what to do with the new interface, you'll always find ** this help ** in the top right corner. If you are still unclear or have any comments, please contact us (mailto: valenta@fit.cvut.cz? Subject = [new-poll]).
## Breadcrumb Navigation
When viewing the results, you have a direct return to higher levels of the application using ** breadcrumb navigation** above.
## Choice of semester
In the following table you can see a list of semesters for which we have the results to display.

** Please click on a semester code to continue. **
 `,
  courses_help_markdown: `# List of subjects and teachers
On the current page you can see a guidepost of individual results pages. It contains all the information we have for your username, so you will see here all the results that are relevant to you.


## Filtration
We tried to add advanced filtering options for subjects and teachers to the new interface.

You can switch between lists using ** bookmarks **.

With the ** drop-down menus **, you can only select subjects and teachers from certain faculties or departments (teachers also according to the subjects in which they are engaged).

** Checkboxes ** allow you to show only subjects that you have rated (unless it was anonymous) or that you had enrolled in the semester you are currently viewing.
## Table
You can search the table using the ** boxes above the columns **. It is also possible to sort in ascending or descending order, while holding \`shift\` it is possible to sort by multiple columns at once.`,
  courses_help_header: 'Help',
  courses_help_close: 'Close',
  coursefilter_placeholder_faculty: 'Faculty',
  coursefilter_placeholder_department: 'Department',
  coursefilter_placeholder_course: 'Course',
  coursefilter_no_options: 'No options.',
  coursefilter_only_attended: 'Attended by me',
  coursefilter_only_rated: 'Rated by me',
  coursefilter_only_teached: 'Lectured by me',
  courses_results_of_semester: 'Results of semester ',
  courses_results_of_semesters: 'Results of surveys',
  courses_tab_courses: 'Courses',
  courses_tab_teachers: 'Teachers',
  courses_placeholder_semester: 'Semester',
  table_previous: 'Previous',
  table_next: 'Next',
  table_loading: 'Loading...',
  table_no_data: 'No rows found.',
  table_page: 'Page',
  table_of: 'of',
  table_rows: 'rows',
  table_page_jump: 'jump to page',
  table_rows_selector: 'rows on page',
  table_head_grade: 'Rating',
  table_head_fullname: 'Full name',
  table_head_code: 'Code',
  table_head_name: 'Name',
  table_head_success: 'Success rate',
  table_head_filled: 'Fill rate',
  table_head_semester: 'Semester',
  table_head_rating_distribution: 'Distribution',
  table_head_ratings: '# Ratings',
  table_head_seen: 'Seen',
  table_head_answered: '# Reactions',
  table_head_received: '# Received',
  table_head_grade_tooltip:
    'Average rating received by the teacher across all the courses he teached.',
  table_head_ratings_tooltip:
    'Number of ratings received by the teacher across the courses he teached.',
  table_head_received_tooltip:
    'Number of text comments received by the teacher across the courses he teached.',
  table_head_answered_tooltip:
    "Number of reactions written by the teacher. Reaction can be either to a whole course or to a semester or direct reply to a student's comment (to a course or to a teacher).",
  table_head_seen_tooltip:
    "Icon in this column means that the teacher has seen some of the students' comments.",
  res_bread_semesters: 'Semesters',
  course_detail_overview: 'deprecated',
  course_detail_questions: 'Questions',
  vis_statistics: 'Statistics',
  vis_fill_rate: 'Fill rate',
  vis_success_rate: 'Success rate',
  vis_course_rating: 'Ratings in Survey',
  vis_grades: 'Received grades',
  tooltip_course_visualization: `In this card you can see basic statistics about this course in latest semester.

  Filled rate is calculated as received tickets / offered tickets.

  The following chart contains distribution of ratings (smileys) received by the course in the survey. Each user could have sent up to two smileys. Grade 1 = the best smiley.

  On the right is the donut chart which contains ditribution of final received grades.`,
  tooltip_course_history_chart: `This is how the course performed in previous semesters. Not every time there's sufficient data for displaying the chart, so the lines some times contain spaces.`,
  tooltip_course_questions: `Answers of respondents to questions from survey tickets. If you attended the course you now have ability to rate the comments by giving thumbs up or down. The comments will be then sorted by their scores.

  For easier searching through the comments you have the ability to display all of them by clicking "Show all".`,
  tooltip_course_teachers: `List of teachers of the course. The teachers are able to post their reaction. The reaction will be displayed under their name.`,
  tooltip_teacher_questions: `Comments of respondents on the teacher.

Roles of teacher (lecturer, instructor, examiner...) are not being differentiated in the new Survey. Teacher is rated within course by one rating (smiley) and optionally by one comment.


  For easier searching through the comments you have the ability to display all of them by clicking "Show all".`,
  tooltip_teacher_overview: `The teacher is able to post his reaction on the whole semester. After he does so, you will find it here.
  `,
  tooltip_teacher_statistics: `Union of all received smileys by the teacher. Grade 1 = the best smiley.


Roles of teacher (lecturer, instructor, examiner...) are not being differentiated in the new Survey.Teacher is rated within course by one rating (smiley) and optionally by one comment.`,
  answer_name: 'Name',
  answer_grade: 'Grade',
  answer_year: 'Year',
  answer_branch: 'Study branch',
  answer_average: 'Study average',
  history_chart_rating: 'Rating',
  history_chart_success_rate: 'Success Rate',
  history_chart_evolution: 'History',
  version: 'version',
  bread_home: 'Home',
  bread_results: 'Results',
  bread_survey: 'Survey',
  bread_course: 'Course',
  bread_in_semester: 'in semester',
  bread_teacher: 'Teacher',
  answers: 'text answers',
  toc: 'Table of Contents',
  show_all: 'Show all',
  answer: 'react',
  edit: 'edit answer',
  delete: 'delete',
  global_react: 'React',
  global_change: 'Edit',
  global_delete: 'Remove',
  teachers: 'Teachers',
  send: 'Send',
  semesters_page: 'Results',
  login: 'Sign in',
  survey_login: 'Sign in',
  username: 'Username',
  email: 'ID',
  password: 'Password',
  log_in: 'Sign in',
  log_in_sso: 'Sign in with SSO',
  log_in_test: 'Testing sign in',
  semester: 'Semester',
  rating: 'Grade',
  no_teachers_to_show: 'No teachers to show.',
  more_comments: 'Show more',
  about_markdown: `# About application

The current version of the Enquiry system CTU is being developed with the support of IP 2019 (CTU Internal Projects). According to the implementation and deployment plan, it should be fully operational in 2020.

The application Anketa ČVUT is working according to methodological instruction number [3/2022 Anketa ČVUT: dokumentace a metodika provozu systému](https://www.cvut.cz/sites/default/files/content/d1dc93cd-5894-4521-b799-c7e715d3c59e/cs/20221007-metodicky-pokyn-c-32022.pdf).

We are currently in the phase of gradual implementation and pilot testing of individual parts (modules).

* Polling ticket module (2nd pilot run).
* Module for viewing the results and commenting on the results by teachers (1st pilot run in September 2019).
* The module for faculty administrators will be operational in early 2020.


Compared to previous versions, this version has the following objectives:

* Attracting the "silent majority" - increasing the completion of surveys at all faculties
* Modern user interface also usable from mobile devices
* Centralized approach to filling in polls and previewing results
* More flexible responses from teachers and leadership
* View historical trends
* Better support for departmental or institute-level analysis of results
* Better support for further processing of poll results

## Requirements

The application runs in web browser on client side, so it is required to have execution javascript enabled. It also requires access to the [sessionStorage](https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage) and [cookies](https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies) API. Not meeting any of these requirements might result in incorrect behavior (or no behavior at all).

These features are being used by almost every modern web application. They are enabled by default in browser's settings.

We use [Google Analytics](https://analytics.google.com/analytics/web/) for improving user experience and [Sentry](https://sentry.io) to track errors and crashes.

## Versions history

### 3.0.0

It is based on user surveys, analysis and design provided by Bachelor (FIT) and Master thesis (FEE) of David Knap.

The design of the overall application architecture and the initial implementation of the questionnaire filling module were done in the diploma thesis of Vojtěch Štecha (FIT).

System architecture and database are designed from scratch.


#### Deveopment team

* Michal Valenta
* Jakub Jun
* Nguyen Duc Thang
* Šimon Valenta
* Kristýna Sanická

As bachelor and master thesis:

* David Knap
* Vojtěch Štecha

### 2.0.0

It was implemented in Java technology. Initially it used JSP, later other frameworks, which were based on this technology.

The presentation of the results remained in the original PHP technology. The evaluation resulted in a set of static pages that were delivered to the relevant faculty.

Initially it was a transcription of the original Enquiry system (version 1) into the new technology. The development was realized in the form of bachelor and master theses at FEE.

With the increasing demand for changes to the polling interface, as well as the overall logic of results processing and presentation, the system has become increasingly sustainable.

In 2018 the development of this version was frozen and the development of version 3 started.

### 1.0.0

It was created as a pilot project for FEL needs. It was written in PHP technology. From the beginning it was based on KOS data. Approximately in 2005 the application was put into operation for all CTU faculties.

## Contacts
- [Michal Valenta](mailto:valenta@fit.cvut.cz)

## Used technlogie
`,
  about_app: 'About App',
  feedback_translations: {
    'trigger.text': 'Help us improve the site!',
  },
  feedback_bug: 'Bug',
  feedback_improvement: 'Improvement',
  feedback_opinion: 'Opinion',
  back_home: 'back to homepage',
  data_loss_markdown: `
# Partial data loss

### Can You see the opportunity to fill in the questionnaire again that you have already filled in and sent?

You can choose one of two scenarios below:

#### Scenario 1: Ignore the queistionaire

Your course evaluation is counted, only the teacher's evaluation in this course has been lost. Questionaire is incomplete, but counts.

#### Scenario 2: Supplementary questionaire

- U 189 anketních lístků došlo k situaci, že identitu jednoho z hodnocených vyučujících jsme odhalili a doplnili (vyučující byl explicitně jmenován v textovém komentáři), u dalšího vyučujícího na tomtéž anketním lístku jsem ovšem již neuspěli. Pokud tedy "svůj" textový komentář k vyučujícímu vidíte ve výsledcích (a nevidíte jej na výše uvedené stránce ztracených textových komentářů), pak tohoto vyučujícího již znovu nehodnotťe. Ohodnťte pouze ty zbývající, jejichž hodnocení jsme ztratili.

In the new (supplementary) questionaire you will only add what has been lost.

- Only fill in the teacher section. Do not fill out the remaining parts of the questionnaire (ie questions about the content of the subject and the subject classification), they are already included in your previous (incomplete) questionnaire and get into the overall results.

- If you entered a text comment on the teacher and you don't want to re-invent it, you have a [lost comment page] (https://anketa.cvut.cz/nova/ztracene_komentare.html) where you can find and copy it.

- The only duplication that will result will be in the "smiley" evaluation of the question "Overall Impression". We expect you to answer with the same smiley and we will be able to partially eliminate this duplication when evaluating the poll.

- In 189 questionnaires, the identity of one of the evaluated teachers was revealed and filled in the db (the teacher was explicitly named in the text commentary), but another teacher on the same questionaire was not recognized. So if you see "your" textual commentary on the teacher in the results (and you do not see it on the above page of lost textual commentaries), then does not re-evaluate that teacher. Rate only the remaining ones that we have lost.

## Explanation

In June, during the second test run, an error occurred while deploying a new version of the system. The mistake was that references to teachers in questionaire were not stored in the DB. Before the error was recorded and corrected, 8,005 incomplete questionaires were stored.

We tried to minimize the impact of this error and we managed to completely correct about 20% of the incomplete questionnaires (ie to add missing references to the teacher).

Teachers' evaluation consists of two parts: a "smiley face" that expresses the "grading" of the teacher, and an optional textual commentary. When repairing incomplete questionairies, we could use the following clues:

1. The subject has only one teacher in the survey.

2. In the text commentary, the name of the teacher was explicitly stated.

We have 1,658 textual comments left to which we are unable to assign the teacher. These comments are located on the [lost comments page] (https://anketa.cvut.cz/nova/ztracene_komentare.html). They are sorted by faculties and the subjects they belong to so you can easily trace them and reuse them if necessary.

We would like to further reduce data loss. However, we cannot do without the help of those who filled in the survey.

## The way to further mitigate data loss

In the case of incomplete questionnaires we tried to estimate the student who filled them in. This cannot be done exactly because the survey is anonymous and the association between the questionairy and the student who filled it in is not saved (except when the student "signs" the poll).

For such students, we have deleted information that the student has already filled in the questionnaire from the subject. In the questionnaire filling interface, he / she will be able to fill it in again.

If you have this situation, i.e. in the questionnaire fill-in interface you are offered a subject for the second time, you can choose from two scenarios listed at the top of this page.

## Excuse

Za způsobenou chybu se velmi omlouváme. Podnikáme kroky, aby se již neopakovala.

Věříme, že nové rozhraní pro vyplňování anketních lístků a zejména pro náhled výsledků anket, kterému věnujeme velké úsilí, bude pro vás určitou satisfakcí za tuto naši chybu, a hlavně, že vás nepřipraví o motivaci anketu vyplňit a zpětnou vazbu vyučujícím a fakultám poskytnout.

Michal Valenta (právce Anekty ČVUT)

We apologize for the mistake. We are taking steps to prevent it from happening again.

We believe that the new interface for filling out the surveys, and especially for previewing the results of the surveys, which we devote a lot of effort, will give you some satisfaction for this mistake, and above all that it will not deprive you of the motivation to fill in the polls and provide feedback to teachers and faculties.

Michal Valenta (adminstrator of CTU enquiry system)

  `,
  overall_statistics: 'Overall statistics',
  average_obtained_rating: 'Average obtained rating',
  teacher_has_not_reacted: "The teacher hasn't posted a reaction yet.",
  semester_reaction: "Teacher's reaction to the last semester",
  data_loss: 'Partial data loss',
  non_course: 'Non-course',
  number: 'Total',
  my_teacher_page: 'My teacher page',
  changelog: 'Changelog',
  changelog_markdown: `# Changelog
## 3.0.5
- Added results of non-course surveys
- Added option to login without SSO

## 3.0.4
- Minor bugfixes
- New columns in list of teachers
- Departments in the menu are now sorted by ID
- Tables now remember their state after pressing the "back" button

## 3.0.3
- Minor bugfixes
- Added department id to the select menu
- Sorting by rating now leaves course and teacher last

## 3.0.2
- Added this changelog
- Rows in tables of teachers and courses are now numbered
- Added column with filled rate in table of courses
- More continuous gradient in the "success rate" column
- Minor graphic change of overall teacher's reaction on course page
- Improved chart of received ratings on teacher's page
- Added visible numbers to the graph of received ratings on course page
- History chart on course page now includes current semester
- If a teacher sees a comment, an icon of eye will appear next to the comment. Hover over the icon to see when did it happen.

## 3.0.1
- Increased size of "show more" button on course page
- Remove "ČVUT" from header
- Added texts to tooltips
- Temporarily removed help buttons
- Fixed the error caused by submitting feedback of the "improvement" type
- Fixed config of webserver that caused errors by sharing links
- Added an option to show up to 1000 rows in the courses and teachers tables`,
  censored:
    "This comment has been hidden by decision of the respective faculty, or hasn't been approved by the teacher.",
  seen: 'Seen by teacher on ',
  ratings: 'ratings',
  teacher_has_seen_some_comments: 'The teacher has seen some comments.',
  filter: 'Filter',
  
  tooltip_LDAP_login:'It will be used by a student who has already completed their studies (typically after state studies) or by an external teacher who does not currently have a valid DPP (Work Performance Agreement).'
};

export default locale_EN;
