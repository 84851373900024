import React, {Component} from 'react';
import {login, logout, loginWithToken} from '../Api/auth';
import {Button} from 'reactstrap';
import {connect} from 'react-redux';
import {getSurveys} from '../Api/surveyApi';
import {getQuestions} from '../Api/questionApi';
import {getTeachers} from '../Api/teacherApi';
import {getSubjects} from '../Api/subjectsApi';
import {getOptions} from '../Api/textOptionsApi';
import localization from '../lib/localization';
import {withRouter} from 'react-router-dom';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from 'reactstrap';
import cookie from 'react-cookies';
import Countdown from 'react-countdown-now';
import * as Sentry from '@sentry/browser';

class Login extends Component {
  componentDidMount = () => {
    let token = undefined;
    // const parameters = this.props.location.search;
    // if (parameters !== '' && parameters !== undefined && parameters !== null) {
    //   token = parameters
    //     .substr(1)
    //     .split('&')
    //     .find((e) => e.includes(config.queryTokenName));
    //   if (token !== undefined) {
    //     token = token.split('=')[1];
    //   } else {
    //     token = undefined;
    //   }
    // }
    // if (token === undefined) {
    token = cookie.load('Authorization');
    // }
    if (token !== undefined && token !== 'undefined') {
      this.props.loginWithToken(token).then(() => {
        this.props.getSurveys();
        this.props.getQuestions();
        this.props.getTeachers();
        this.props.getSubjects();
        this.props.getOptions();
      });
      // /this.props.history.push('/a');
    }
  };

  render() {
    return this.props.user.state.loaded ? this.renderIn() : this.renderOut();
  }

  handleLogout = () => {
    cookie.remove('Authorizaton', {path: '/'});
    this.props.history.push('/');
    this.props.logout();
    window.location.reload();
  };

  redirectToLogin = () => {
    this.props.history.push('/login');
  };

  renderOut() {
    return (
      <div>
        {/* <Button onClick={this.handleLogin} color="primary">{localization("user_login_button")}</Button> */}
        <Button onClick={this.redirectToLogin} color='primary'>
          {localization('user_login_sso')}
        </Button>
      </div>
    );
  }

  renderIn() {
    if (process.env.REACT_APP_SENTRY_DSN) {
      Sentry.setUser({
        username: this.props.user.userName,
      });
    }
    return (
      <div>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret color='primary'>
            {this.props.user.userName}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem>
              <Countdown date={Date.parse(cookie.load('authorizedUntil'))} />
            </DropdownItem>
            <DropdownItem onClick={this.handleLogout}>
              {localization('user_logout_button')}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        {/* <UncontrolledTooltip
          placement='right'
          target='UncontrolledTooltipExample'
        >
          Hello world!
        </UncontrolledTooltip> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    refresh: state.refresh,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginWithToken: loginWithToken(dispatch),
    login: login(dispatch),
    logout: logout(dispatch),
    getSurveys: getSurveys(dispatch),
    getQuestions: getQuestions(dispatch),
    getSubjects: getSubjects(dispatch),
    getTeachers: getTeachers(dispatch),
    getOptions: getOptions(dispatch),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
);
