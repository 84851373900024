import React, {Component} from 'react';
import Chart from 'react-apexcharts';
import {Card, CardBody, CardHeader, Progress, Row, Col} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChartPie} from '@fortawesome/free-solid-svg-icons';
import CardTooltip from './CardTooltip';
import localization from '../../lib/localization';

class VisualizationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        legend: {
          formatter: function(seriesName, opts) {
            return `${seriesName}: ${opts.w.globals.series[opts.seriesIndex]}`;
          },
        },
        chart: {
          width: '100%',
        },
        colors: [
          '#52D726',
          '#007ED6',
          '#7CDDDD',
          '#FFEC00',
          '#FF7300',
          '#FF0000',
          '#808080',
        ],
        labels: ['A', 'B', 'C', 'D', 'E', 'F', 'N'],
      },
    };
  }

  render() {
    const options = {
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: [
          localization('rating') + ' 1',
          localization('rating') + ' 2',
          localization('rating') + ' 3',
          localization('rating') + ' 4',
          localization('rating') + ' 5',
        ],
      },
      chart: {
        toolbar: {
          show: false,
        },
        stacked: true,
      },
    };

    return (
      <>
        <Card id='statistics'>
          <CardHeader>
            <FontAwesomeIcon className='mr-2' size='lg' icon={faChartPie} />
            {localization('vis_statistics')}
            <CardTooltip
              content={localization('tooltip_course_visualization')}
              className='float-right'
              id='tooltip-statistics'
            />
          </CardHeader>
          <CardBody>
            <Row>
              <Col xs='12' sm='7'>
                {/* <div className='mt-2'>
                  Uděleno zápočtů
                  <span className='float-right'>
                    75 / {this.props.subscribed}
                  </span>
                  <Progress value={75} max={this.props.subscribed} />
                </div> */}

                <div className='mt-1'>
                  {localization('vis_fill_rate')}
                  <span className='float-right'>
                    {this.props.filled} / {this.props.subscribed} (
                    {(
                      (this.props.filled / this.props.subscribed) *
                      100
                    ).toFixed(2)}{' '}
                    %)
                  </span>
                  <Progress
                    value={this.props.filled}
                    max={this.props.subscribed}
                    className='mt-1'
                  />
                </div>

                <div className='mt-3'>
                  {localization('vis_success_rate')}
                  <span className='float-right'>
                    {this.props.finished} / {this.props.subscribed} (
                    {(
                      (this.props.finished / this.props.subscribed) *
                      100
                    ).toFixed(2)}{' '}
                    %)
                  </span>
                  <Progress
                    value={this.props.finished}
                    max={this.props.subscribed}
                    className='mt-1'
                    color='danger'
                  />
                </div>

                <div className='mt-3'>
                  {localization('vis_course_rating')}
                  <span className='float-right'>
                    ø {this.props.averageRating}
                  </span>
                  <Chart
                    options={options}
                    series={[
                      {
                        name: `${localization('number')}`,
                        data: this.props.rating,
                      },
                    ]}
                    type='bar'
                    height='350'
                  />
                </div>
              </Col>
              <Col xs='12' sm='5' className='mt-3 mt-sm-0'>
                {localization('vis_grades')}
                <Chart
                  options={this.state.options}
                  series={this.props.grades}
                  type='pie'
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default VisualizationCard;
