import * as actionTypes from '../Actions/actionTypes';
import Axios from 'axios';
import { localeKeys, LOCALIZEDPROPERTIES, localize } from '../lib/localization';
import { dangerAction } from '../Actions/alertActions';

export const getQuestions = dispatch => {
    return () => {
        dispatch({
            type: actionTypes.QUESTIONS_LOADING
        });
        Axios.get('/questions')
        .then(res => {
            dispatch({
                type: actionTypes.QUESTIONS_LOADED,
                data: res.data.data.map(mapQuestion)
            })
        })
        .catch( err => {
            if(err.response.status === 401){
                dispatch(dangerAction(localize("alerts_not_authenticated")))
            }
            else {
                dispatch(dangerAction(localize("alerts_connection_failure")));
            }
        });
    }
}

const mapQuestion = data => {
    const question = {
        id: data.id.toString(),
        isValue: data.hasValueAnswer,
        isText: data.hasTextAnswer,
        isMandatory: data.mandatory,
        isTeacher: data.teacherQuestion,
        isPrimary: data.primary,
        priority: data.priority === null || data.priority === undefined || data.teacherQuestion ? Number.MAX_SAFE_INTEGER : data.priority,
    }

    question[LOCALIZEDPROPERTIES] = {};

    question[LOCALIZEDPROPERTIES][localeKeys.cz] = {
        textName: data.valueQuestionCz,
        textHelp: data.textContextCz,
        textDescription: data.textExplNormalCz,
        textDescriptionBad: data.textExplBadCz,
        textQuestion: data.textQuestionNormalCz,
        textQuestionBad: data.textQuestionBadCz,
        textPlaceholder: data.placeholderCz,
        textPlaceholderBad: data.placeholderCz
    };
        
    question[LOCALIZEDPROPERTIES][localeKeys.en] = {
        textName: data.valueQuestionEn,
        textHelp: data.textContextEn,
        textDescription: data.textExplNormalEn,
        textDescriptionBad: data.textExplBadEn,
        textQuestion: data.textQuestionNormalEn,
        textQuestionBad: data.textQuestionBadEn,
        textPlaceholder: data.placeholderEn,
        textPlaceholderBad: data.placeholderEn
    }

    return question;
}