import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
    surveys: [],
    loaded: false
}

const surveys = (state = defaultState, action) => {
    switch(action.type) {
        case actionTypes.SURVEYS_LOADED: {
            return Object.assign({}, state, {surveys: action.data, loaded: true});
        }
        case actionTypes.SUBJECT_SUBMIT: {
            const newState = Object.assign({}, state);
            newState.surveys.find(x => x.id === action.surveyId).subjectToRateIds = newState.surveys.find(x => x.id === action.surveyId).subjectToRateIds.filter(x => x !== action.subjectId)
            if (newState.surveys.find(x => x.id === action.surveyId).subjectToRateIds.length === 0) {
                newState.surveys = state.surveys.filter(x => x.id !== action.surveyId);
                return newState;
            }
            newState.surveys.find(x => x.id === action.surveyId).subjectRatedIds = [...state.surveys.find(x => x.id === action.surveyId).subjectRatedIds, action.id]
            return newState
        }   
        case actionTypes.SURVEY_SUBMIT: {
            const newState = Object.assign({}, state);
            newState.surveys = state.surveys.filter(x => x.id !== action.surveyId)
            return newState
        }
        default: return state;
    }
}

export default surveys;

