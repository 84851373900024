// import * as actionTypes from '../Actions/actionTypes';

// only for testing pruposes, can be deleted

const defaultState = {
  efg: '0',
};

const semesterCache = (state = defaultState, action) => {
  return state;
};

export default semesterCache;
