import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { close } from '../Actions/alertActions';


class AlertBoard extends Component {
    render() {
        const { color, isOpen, message } = this.props.alert;
        return (
            <div className="container" style={{marginTop: "20px"}}>
                <Alert color={color} isOpen={isOpen} toggle={this.props.close}>{message}</Alert>
            </div>
        );
    }
}


const  mapStateToProps = state => {
    return {
		alert: state.alert
    };
}

const mapDispatchToProps = dispatch => {
    return {
        close: close(dispatch)
    }   
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertBoard);