import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Progress, UncontrolledTooltip, Button} from 'reactstrap';
import TeachersFilters from './TeachersFilters';
import ReactTable from 'react-table';
import localization from '../../lib/localization';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faEye,
  faQuestion,
  faFileDownload,
} from '@fortawesome/free-solid-svg-icons';
import {saveAs} from 'file-saver';
import {Parser} from 'json2csv';

class TeachersTab extends Component {
  render() {
    function getColor(value) {
      //value from 0 to 1
      var hue = (value * 120).toString(10);
      return ['hsl(', hue, ',100%,40%)'].join('');
    }
    const colors = ['#52D726', '#007ED6', '#7CDDDD', '#FFEC00', '#FF0000'];
    const columns = [
      {
        Header: '',
        id: 'row',
        width: 50,
        filterable: false,
        sortable: false,
        Cell: (row) => {
          return (
            <small style={{color: '#999'}}>
              {row.viewIndex + row.page * row.pageSize}
            </small>
          );
        },
      },

      {
        Header: localization('table_head_fullname'),
        accessor: 'fullName', // String-based value accessors!
        // Filter: ({filter, onChange}) => (
        //   <input
        //     onChange={(event) => onChange(event.target.value)}
        //     value={filter ? filter.value : ''}
        //     style={{
        //       width: '100%',
        //     }}
        //     placeholder={localization('filter')}
        //   />
        // ),
        Cell: (row) => {
          return (
            <Link
              to={
                '/results/semesters/' +
                this.props.semesterCode +
                `/surveys/${this.props.surveyId}` +
                '/teachers/' +
                row.original.id
              }
            >
              {row.value}
            </Link>
          );
        },
        filterMethod: (filter, row) =>
          row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
        sortMethod: (a, b, desc) => {
          return a.localeCompare(b, 'cs', {sensitivity: 'base'});
        },
      },

      {
        Header: (
          <>
            <span id='header-grade'>
              {localization('table_head_grade')}{' '}
              <FontAwesomeIcon size='sm' icon={faQuestion} />
            </span>
            <UncontrolledTooltip target='header-grade'>
              {localization('table_head_grade_tooltip')}
            </UncontrolledTooltip>
          </>
        ),
        accessor: 'rating',
        width: 120,
        style: {textAlign: 'center'},
        filterable: false,
        sortMethod: (a, b, desc) => {
          if (desc) {
            a = a === undefined || a === null ? -Infinity : a;
            b = b === null || b === undefined ? -Infinity : b;
          } else {
            a = a === undefined || a === null ? Infinity : a;
            b = b === null || b === undefined ? Infinity : b;
          }
          if (a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
          // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
          return 0;
        },
      },
      {
        Header: () => (
          <>
            <span id='header-ratings'>
              {localization('table_head_ratings')}{' '}
              <FontAwesomeIcon size='sm' icon={faQuestion} />
            </span>
            <UncontrolledTooltip target='header-ratings'>
              {localization('table_head_ratings_tooltip')}
            </UncontrolledTooltip>
          </>
        ),
        accessor: 'valueAnswer',
        width: 120,
        filterable: false,
        style: {textAlign: 'center'},
      },
      {
        Header: localization('table_head_rating_distribution'),
        accessor: 'ratings',
        width: 120,
        filterable: false,
        sortable: false,
        Cell: (row) => (
          <>
            <Progress id={`progress-${row.viewIndex}`} multi>
              {row.value.map((r, i) => (
                <div
                  className='progress-bar'
                  style={{
                    width:
                      (r /
                        row.value.reduce((sum, num) => {
                          return sum + num;
                        })) *
                        100 +
                      '%',
                    backgroundColor: colors[i],
                    // backgroundColor: getColor(r / ratingsSum),
                  }}
                  role='progressbar'
                  aria-valuenow={r}
                  aria-valuemin='0'
                  aria-valuemax={row.value.reduce((sum, num) => {
                    return sum + num;
                  })}
                ></div>
              ))}
            </Progress>
            <UncontrolledTooltip
              placement='right'
              target={`progress-${row.viewIndex}`}
            >
              {colors.map((c, i) =>
                row.value[i] ? (
                  <div>
                    <div
                      style={{
                        display: 'inline-block',
                        backgroundColor: c,
                        width: '10px',
                        height: '10px',
                      }}
                    />{' '}
                    {localization('answer_grade')} {i + 1}: {row.value[i]}
                  </div>
                ) : (
                  ''
                )
              )}
            </UncontrolledTooltip>
          </>
        ),
      },
      {
        Header: (
          <>
            <span id='header-received'>
              {localization('table_head_received')}{' '}
              <FontAwesomeIcon size='sm' icon={faQuestion} />
            </span>
            <UncontrolledTooltip target='header-received'>
              {localization('table_head_received_tooltip')}
            </UncontrolledTooltip>
          </>
        ),
        accessor: 'studentsAnswersReceived',
        filterable: false,
        width: 120,
        style: {textAlign: 'center'},
      },
      {
        Header: (
          <>
            <span id='header-answered'>
              {localization('table_head_answered')}{' '}
              <FontAwesomeIcon size='sm' icon={faQuestion} />
            </span>
            <UncontrolledTooltip target='header-answered'>
              {localization('table_head_answered_tooltip')}
            </UncontrolledTooltip>
          </>
        ),
        accessor: 'reactionsCount',
        filterable: false,
        width: 140,
        style: {textAlign: 'center'},
      },
      {
        Header: (
          <>
            <span id='header-seen'>
              {localization('table_head_seen')}{' '}
              <FontAwesomeIcon size='sm' icon={faQuestion} />
            </span>
            <UncontrolledTooltip target='header-seen'>
              {localization('table_head_seen_tooltip')}
            </UncontrolledTooltip>
          </>
        ),
        accessor: 'seen',
        filterable: false,
        width: 120,
        style: {textAlign: 'center'},
        Cell: (row) =>
          row.value ? (
            <>
              <FontAwesomeIcon
                style={{color: 'gray'}}
                id={`seen-${row.viewIndex}`}
                icon={faEye}
              />
              <UncontrolledTooltip
                placement='right'
                target={`seen-${row.viewIndex}`}
              >
                {localization('teacher_has_seen_some_comments')}
              </UncontrolledTooltip>
            </>
          ) : (
            ''
          ),
      },
    ];

    return (
      <>
        <Row className='mb-3 mt-3 pl-2 pr-2'>
          <TeachersFilters
            facultiesOptions={this.props.facultiesOptions}
            departmentsOptions={this.props.departmentsOptions}
            coursesOptions={this.props.coursesOptions}
            onSelectedDepartments={this.props.onSelectedDepartments}
            onSelectedFaculties={this.props.onSelectedFaculties}
            onSelectedCourses={this.props.onSelectedCourses}
            isLoading={this.props.isLoading}
            selectedFaculties={this.props.selectedFaculties}
            selectedDepartments={this.props.selectedDepartments}
            selectedCourses={this.props.selectedCourses}
          />
        </Row>
        <Row className='mb-3 mt-3 mb-lg-3 pl-2 pr-2'>
          <Col>
            <Button
              color='primary'
              onClick={() => {
                if (!this.table) return;
                const parser = new Parser();
                const csv = parser.parse(
                  this.table
                    .getResolvedState()
                    .sortedData.map((e) => e._original)
                );

                const blob = new Blob([csv], {
                  type: 'text/csv;charset=utf-8;',
                });
                saveAs(
                  blob,
                  this.props.surveyName.replace(/ /g, '_') + '_teachers.csv'
                );
              }}
            >
              Export <FontAwesomeIcon icon={faFileDownload} className='ml-1' />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <ReactTable
              ref={(r) => (this.table = r)}
              loading={this.props.isLoading}
              filterable
              previousText={localization('table_previous')}
              nextText={localization('table_next')}
              loadingText={localization('table_loading')}
              noDataText={localization('table_no_data')}
              pageText={localization('table_page')}
              ofText={localization('table_of')}
              rowsText={localization('table_rows')}
              // Accessibility Labels
              pageJumpText={localization('table_page_jump')}
              rowsSelectorText={localization('table_rows_selector')}
              data={this.props.teacherRows}
              columns={columns}
              className='-highlight'
              minRows='1'
              pageSizeOptions={[15, 25, 50, 100, 1000]}
              defaultPageSize={25}
              page={this.props.page}
              pageSize={this.props.pageSize}
              sorted={this.props.sorted}
              filtered={this.props.filtered}
              onSortedChange={this.props.onSortedChange}
              onFilteredChange={this.props.onFilteredChange}
              onPageSizeChange={this.props.onPageSizeChange}
              onPageChange={this.props.onPageChange}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default TeachersTab;
