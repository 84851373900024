import React, {Component} from 'react';
import axios from 'axios';
import CoursesPage from '../CoursesPage';
import NonCoursal from '../NonCoursal';
import ReactGA from 'react-ga';
import localization from '../../../lib/localization';
import {connect} from 'react-redux';
import {danger} from '../../../Actions/alertActions';
import {addUrlProps, UrlQueryParamTypes} from 'react-url-query';

const urlPropsQueryConfig = {
  attended: {type: UrlQueryParamTypes.boolean},
  lectured: {type: UrlQueryParamTypes.boolean},
  rated: {type: UrlQueryParamTypes.boolean},
  tab: {type: UrlQueryParamTypes.string},
  coursesPage: {type: UrlQueryParamTypes.number},
  teachersPage: {type: UrlQueryParamTypes.number},
  coursesPageSize: {type: UrlQueryParamTypes.number},
  teachersPageSize: {type: UrlQueryParamTypes.number},
  coursesFilters: {type: UrlQueryParamTypes.json},
  teachersFilters: {type: UrlQueryParamTypes.json},
  departments: {type: UrlQueryParamTypes.json},
  courses: {type: UrlQueryParamTypes.json},
  teachersSorts: {type: UrlQueryParamTypes.json},
  coursesSorts: {type: UrlQueryParamTypes.json},
};

// Kontejner pro stranku s vypisem predmetu ucitelu, zde se deje veskera logika - nacitani dat, priprava dat, filtrace

class SemesterResults extends Component {
  static defaultProps = {
    attended: false,
    rated: false,
    lectured: false,
    departments: [],
    courses: [],
    tab: '1',
    coursesPage: 0,
    teachersPage: 0,
    coursesFilters: [],
    teachersFilters: [],
    coursesSorts: [],
    teachersSorts: [],
    coursesPageSize: 25,
    teachersPageSize: 25,
  };
  constructor(props) {
    super(props);

    this.state = {
      isCoursal: true,
      activeTab: '1',
      faculties: [],
      courses: [],
      filteredCourses: [],
      teachersByFaculties: [],
      semesters: [],
      facultiesOptions: [],
      departments: [],
      departmentsOptions: [],
      coursesOptions: [],
      selectedFaculties: null,
      selectedDepartments: null,
      selectedCourses: null,
      filteredCoursesOptions: [],
      filteredDepartmentsOptions: [],
      isLoading: true,
      teachers: [],
      filteredTeachers: [],
      filterAttendedChecked: false,
      filterRatedChecked: false,
      filterTeachedChecked: false,
      isTeacher: false,
      surveyNameCz: '',
      surveyNameEn: '',
      teachedByMe: null,
    };
  }

  toggle = (tab) => {
    this.props.onChangeTab(tab);
  };

  prepareTeacherRows = () => {
    var newTeachers = [];

    this.state.faculties.map((f) => {
      f.teachers.map((t) => {
        t.faculty = f.shortName;
        newTeachers.push(t);
        return true;
      });
      return true;
    });

    newTeachers.sort((a, b) => {
      return a.fullName.localeCompare(b.fullName, 'cs', {sensitivity: 'base'});
    });

    this.setState(
      {
        teachers: newTeachers,
        filteredTeachers: newTeachers,
      },
      () => {
        this.filterTeacherRows();
      }
    );

    return true;
  };

  prepareCourseRows = () => {
    var newCourses = [];

    this.state.faculties.map((faculty) => {
      faculty.departments.map((department) => {
        department.courses.map((course) => {
          course.faculty = faculty.shortName;
          course.department = department.id;
          newCourses.push(course);
          return true;
        });
        return true;
      });
      return true;
    });

    newCourses.sort((a, b) => {
      return a.code.localeCompare(b.code, 'cs', {sensitivity: 'base'});
    });

    this.setState(
      {
        courses: newCourses,
        filteredCourses: newCourses,
      },
      () => {
        this.filterCourseRows();
      }
    );

    return true;
  };

  prepareFilterOptions = () => {
    // TODO: jeste filtrace pres courses!
    var newDepartmentsOptions = [];
    var newFacultiesOptions = [];
    var newCourseOptions = [];

    this.state.faculties.map((f) => {
      var newFaculty = {label: f.shortName, value: f.shortName};
      newFacultiesOptions.push(newFaculty);

      var deps = f.departments.concat().sort((a, b) => {
        if (Number(a.id) > Number(b.id)) return 1;
        if (Number(a.id) < Number(b.id)) return -1;
        return 0;
      });

      deps.map((d) => {
        var newDep = {
          label:
            localization('locale') === 'cz'
              ? d.nameCz + ` (${d.id})`
              : d.nameEn + ` (${d.id})`,
          value: d.id,
        };
        newDepartmentsOptions.push(newDep);

        d.courses.map((c) => {
          var newCourse = {label: c.code, value: c.code};
          newCourseOptions.push(newCourse);
          return true;
        });
        return true;
      });
      return true;
    });

    this.setState(
      {
        filteredDepartmentsOptions: newDepartmentsOptions,
        facultiesOptions: newFacultiesOptions,
        coursesOptions: newCourseOptions,
        filteredCoursesOptions: newCourseOptions,
      },
      () => {
        //this.filterCourseRows();
        //this.filterTeacherRows();
      }
    );

    return true;
  };

  filterTeacherRows = () => {
    var filtered = this.state.teachers.filter((t) => {
      var pass = true;
      if (
        this.state.selectedFaculties !== null &&
        this.state.selectedFaculties.length !== 0
      ) {
        pass = false;
        this.state.selectedFaculties.map((fac) => {
          if (fac.value === t.faculty) pass = true;
          return fac;
        });
      }

      if (!pass) return false;

      if (
        this.state.selectedDepartments !== null &&
        this.state.selectedDepartments.length !== 0
      ) {
        pass = false;
        this.state.selectedDepartments.map((dep) => {
          if (t.departments.includes(dep.value)) pass = true;
          return true;
        });
      }

      if (!pass) return false;

      if (
        this.state.selectedCourses !== null &&
        this.state.selectedCourses.length !== 0
      ) {
        pass = false;
        this.state.selectedCourses.map((cou) => {
          if (t.courses.includes(cou.value)) pass = true;
          return true;
        });
      }

      return pass;
    });

    if (
      filtered.length <
      (this.props.teachersPage + 1) * this.props.teachersPageSize
    ) {
      this.props.onChangeTeachersPage(
        Math.floor(filtered.length / this.props.teachersPageSize)
      );
    }

    this.setState({
      filteredTeachers: filtered,
    });

    return true;
  };

  filterCourseRows = () => {
    var filtered = this.state.courses.filter((c) => {
      var pass = true;
      if (
        this.state.selectedFaculties !== null &&
        this.state.selectedFaculties.length !== 0
      ) {
        pass = false;
        this.state.selectedFaculties.map((fac) => {
          if (fac.value === c.faculty) pass = true;
          return fac;
        });
      }

      if (!pass) return false;

      if (
        this.state.selectedDepartments !== null &&
        this.state.selectedDepartments.length !== 0
      ) {
        pass = false;
        this.state.selectedDepartments.map((dep) => {
          if (dep.value === c.department) pass = true;
          return dep;
        });
      }

      if (!pass) return false;

      if (this.state.filterTeachedChecked) {
        pass = false;
        this.state.teachedByMe.map((myCourse) => {
          if (myCourse === c.code) pass = true;
          return myCourse;
        });
      }

      if (!pass) return false;

      if (this.state.filterAttendedChecked && !c.attended) return false;

      if (this.state.filterRatedChecked && !c.rated) return false;

      return pass;
    });

    if (
      filtered.length <
      (this.props.coursesPage + 1) * this.props.coursesPageSize
    ) {
      this.props.onChangeCoursesPage(
        Math.floor(filtered.length / this.props.coursesPageSize)
      );
    }

    this.setState({
      filteredCourses: filtered,
    });

    return true;
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.title = `${localization('title')}`;
    this.loadSemesterData(
      this.props.match.params['semester'],
      this.props.match.params['survey']
    );

    this.setState({
      selectedDepartments: this.props.departments,
      selectedCourses: this.props.courses,
      filterAttendedChecked: this.props.attended,
      filterRatedChecked: this.props.rated,
      filterTeachedChecked: this.props.lectured,
    });
  }

  loadSemesterData = (semesterCode, surveyId) => {
    axios
      .get(`results/semesters/${semesterCode}/surveys/${surveyId}`)
      .then((r) => {
        if (r.data.data.isCoursalSurvey)
          this.setState(
            {
              faculties: r.data.data.faculties,
              //teachersByDepartments: r.data.data.teachers,
              //attendedCourses: r.data.data.attendedCourses,
              isLoading: false,
              teachedByMe:
                r.data.data.teachByMe.length === 0
                  ? null
                  : r.data.data.teachByMe,
              surveyNameCz: r.data.data.surveyNameCz,
              surveyNameEn: r.data.data.surveyNameEn,
              teacherId: r.data.data.id
            },
            () => {
              document.title = `${
                localization('locale') === 'cz'
                  ? this.state.surveyNameCz
                  : this.state.surveyNameEn
              } | ${localization('title')}`;

              this.prepareCourseRows();
              this.prepareTeacherRows();
              this.prepareFilterOptions();
            }
          );
        else {
          document.title = `${
            localization('locale') === 'cz'
              ? r.data.data.surveyCz
              : r.data.data.surveyEn
          } | ${localization('title')}`;
          this.setState({
            isCoursal: false,
            isLoading: false,
            nonCoursal: r.data.data,
          });
        }
      })
      .catch((e) => {
        this.props.danger(localization('alerts_connection_failure'));
      });
  };

  handleSelectedFaculties = (e) => {
    this.setState(
      {
        selectedFaculties: e,
      },
      () => {
        this.filterCourseRows();
        this.filterTeacherRows();
      }
    );
  };

  handleSelectedDepartments = (e) => {
    this.props.onChangeDepartments(e);
    this.setState(
      {
        selectedDepartments: e,
      },
      () => {
        this.filterCourseRows();
        this.filterTeacherRows();
      }
    );
  };

  handleSelectedCourses = (e) => {
    this.props.onChangeCourses(e);
    this.setState(
      {
        selectedCourses: e,
      },
      () => {
        this.filterTeacherRows();
      }
    );
  };

  handleCheckRated = (e) => {
    this.props.onChangeRated(!this.state.filterRatedChecked);
    this.setState(
      {
        filterRatedChecked: !this.state.filterRatedChecked,
      },
      () => {
        this.filterCourseRows();
      }
    );
  };

  handleCheckAttended = (e) => {
    this.props.onChangeAttended(!this.state.filterAttendedChecked);

    this.setState(
      {
        filterAttendedChecked: !this.state.filterAttendedChecked,
      },
      () => {
        this.filterCourseRows();
      }
    );
  };

  handleCheckTeached = (e) => {
    this.props.onChangeLectured(!this.state.filterTeachedChecked);

    this.setState(
      {
        filterTeachedChecked: !this.state.filterTeachedChecked,
      },
      () => {
        this.filterCourseRows();
      }
    );
  };

  addNoncoursalReaction = (questionId, commentId, markdown, questionKey = null) => {
    var comment = this.state.nonCoursal.questions[questionId].textAnswer.textAnswer.find(
      (a) => a.studentEvaluationKey === commentId
    );

    if (markdown)
      axios
        .put('reactions/faculty', {
          studentEvaluationKey: commentId,
          semesterCode: this.props.match.params['semester'],
          surveyKey: Number(this.props.match.params['survey']),
          questionKey: questionKey,
          text: markdown,
        })
        .then( () => {
          var alreadyExistingReaction = comment.reactions.find(
            (e) => e.userName === this.props.user.userName
          );

          if (alreadyExistingReaction) {
            alreadyExistingReaction.text = markdown;
          } else {
            comment.reactions.push({
              studentEvaluationKey: commentId,
              fullName: this.props.user.userName,
              text: markdown,
              userName: this.props.user.userName,
              id: null
            });
          }

          this.forceUpdate();
        })
        .catch((e) => {
          this.props.danger(localization('alerts_connection_failure'));
        });
    else
      axios
        .delete('reactions/faculty', {
          studentEvaluationKey: commentId,
          semesterCode: this.props.match.params['semester'],
          surveyKey: Number(this.props.match.params['survey']),
          questionKey: questionKey,
        })
        .then( () => {
          var alreadyExistingReaction = comment.reactions.find(
            (e) => e.userName === this.props.user.userName
          );

          if (alreadyExistingReaction) {
            alreadyExistingReaction.text = markdown;
          } else {
            comment.reactions.push({
              fullName: this.props.user.userName,
              text: markdown,
              userName: this.props.user.userName,
              id: null
            });
          }

          this.forceUpdate();
        })
        .catch((e) => {
          this.props.danger(localization('alerts_connection_failure'));
        });
  };

  deleteNoncourseReaction = (questionId, commentId, answerId, teacherId, reactionIndex, questionKey = null ) => {

    var filtered = this.state.nonCoursal.questions[questionId].textAnswer.textAnswer[
      answerId
      ].reactions.filter((reaction, index) => index !== reactionIndex);


    axios
      .delete('reactions/faculty', {
        data: {
          studentEvaluationKey: commentId,
          semesterCode: this.props.match.params['semester'],
          surveyKey: Number(this.props.match.params['survey']),
          questionKey: questionKey,
        },
      })
      .then( () => {
        this.state.nonCoursal.questions[questionId].textAnswer.textAnswer[answerId].reactions = filtered;
        this.forceUpdate();
      })
      .catch((e) => {
        this.props.danger(localization('alerts_connection_failure'));
      });

  };

  render() {
    if (this.state.isLoading || this.state.isCoursal)
      return (
        <CoursesPage
          surveyName={
            localization('locale') === 'cz'
              ? this.state.surveyNameCz
              : this.state.surveyNameEn
          }
          facultiesOptions={this.state.facultiesOptions}
          departmentsOptions={this.state.filteredDepartmentsOptions}
          coursesOptions={this.state.filteredCoursesOptions}
          onSelectedFaculties={this.handleSelectedFaculties}
          onSelectedDepartments={this.handleSelectedDepartments}
          onSelectedCourses={this.handleSelectedCourses}
          onCheckAttended={this.handleCheckAttended}
          onCheckRated={this.handleCheckRated}
          onCheckTeached={this.handleCheckTeached}
          isCheckedAttended={this.state.filterAttendedChecked}
          isCheckedRated={this.state.filterRatedChecked}
          isCheckedTeached={this.state.filterTeachedChecked}
          semesterCode={this.props.match.params['semester']}
          surveyId={this.props.match.params['survey']}
          filteredTeachers={this.state.filteredTeachers}
          filteredCourses={this.state.filteredCourses}
          isLoading={this.state.isLoading}
          selectedFaculties={this.state.selectedFaculties}
          selectedDepartments={this.state.selectedDepartments}
          selectedCourses={this.state.selectedCourses}
          toggle={this.toggle}
          activeTab={this.props.tab}
          isTeacher={this.state.teachedByMe}
          teacherId={this.state.teacherId}
          coursesPage={this.props.coursesPage}
          teachersPage={this.props.teachersPage}
          coursesPageSize={this.props.coursesPageSize}
          teachersPageSize={this.props.teachersPageSize}
          coursesSorts={this.props.coursesSorts}
          teachersSorts={this.props.teachersSorts}
          coursesFilters={this.props.coursesFilters}
          teachersFilters={this.props.teachersFilters}
          onCoursesPageChange={this.props.onChangeCoursesPage}
          onTeachersPageChange={this.props.onChangeTeachersPage}
          onCoursesFiltersChange={this.props.onChangeCoursesFilters}
          onTeachersFiltersChange={this.props.onChangeTeachersFilters}
          onCoursesSortsChange={this.props.onChangeCoursesSorts}
          onTeachersSortsChange={this.props.onChangeTeachersSorts}
          onCoursesPageSizeChange={(p) => {
            this.props.onChangeCoursesPageSize(p);
            if (
              this.state.filteredCourses.length <
              (this.props.coursesPage + 1) * p
            ) {
              this.props.onChangeCoursesPage(
                Math.floor(this.state.filteredCourses.length / p)
              );
            }
          }}
          onTeachersPageSizeChange={(p) => {
            this.props.onChangeTeachersPageSize(p);
            if (
              this.state.filteredTeachers.length <
              (this.props.teachersPage + 1) * p
            ) {
              this.props.onChangeTeachersPage(
                Math.floor(this.state.filteredTeachers.length / p)
              );
            }
          }}
        />
      );
    else {
      return (
        <NonCoursal
          semesterCode={this.props.match.params['semester']}
          surveyId={this.props.match.params['survey']}
          survey={this.state.nonCoursal}
          isRepresentative={this.state.nonCoursal.isRepresentative}
          userName={this.props.user.userName}
          onAddReaction={this.addNoncoursalReaction}
          onDeleteReaction={this.deleteNoncourseReaction}
        />
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    danger: danger(dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    user: state.user,
    refresh: state.refresh,
  };
};

export default addUrlProps({urlPropsQueryConfig})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SemesterResults)
);
