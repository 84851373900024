import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
    color: "primary",
    isOpen: false,
    message: " "
}


const alert = (state = defaultState, action) => {
    switch(action.type) {
        case actionTypes.ALERT: {
            return {color: action.color, isOpen: action.isOpen, message: action.message};
        }
        default: return state;
    }
}

export default alert;