import React, {Component} from 'react';
import {Jumbotron, Button} from 'reactstrap';
import {withRouter} from 'react-router-dom';
import localization from '../lib/localization';
import ReactGA from 'react-ga';

class Error404 extends Component {
  handleClick = (e) => {
    this.props.history.push('/');
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div>
        <Jumbotron>
          <h1 className='display-3'>{localization('error_404_heading')}</h1>
          <hr className='my-2' />
          <p className='lead'>
            <Button onClick={this.handleClick} color='primary'>
              {localization('error_404_button')}
            </Button>
          </p>
        </Jumbotron>
      </div>
    );
  }
}

export default withRouter(Error404);
