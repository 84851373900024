import * as types from './actionTypes';


export const success = dispatch => message => {
    dispatch(successAction(message));
}

export const danger = dispatch => message => {
    dispatch(dangerAction(message));
}

export const close = dispatch => () => {
    dispatch( {
        type: types.ALERT,
        message: "",
        color: "",
        isOpen: false
    });
}

export const dangerAction = message => {
    return {
        type: types.ALERT,
        message: message,
        color: "danger",
        isOpen: true
    }
}

export const successAction = message => {
    return {
        type: types.ALERT,
        message: message,
        color: "success",
        isOpen: true
    }
}