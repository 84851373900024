import React, {Component} from 'react';
import QuestionCommentReactionRow from './QuestionCommentReactionRow';
import QuestionCommentTeacherReactions from './QuestionCommentTeacherReactions';
import Voting from './Voting';
import ReactMarkdown from 'react-markdown';
import localization from '../../lib/localization';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEyeSlash, faEye} from '@fortawesome/free-solid-svg-icons';
import {UncontrolledTooltip} from 'reactstrap';

class QuestionComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditor: false,
    };
  }

  getUserInfo = (name, grade, year, branch, averageGrade) => {
    var data = [];

    if (name) data.push(`${localization('answer_name')}: ` + name);

    if (grade) data.push(`${localization('answer_grade')}: ` + grade);

    if (year) data.push(`${localization('answer_year')}: ` + year);

    if (branch) data.push(`${localization('answer_branch')}: ` + branch);

    if (averageGrade)
      data.push(`${localization('answer_average')}: ` + averageGrade);

    return data.map((e, i) => {
      return (
        <>
          {e}
          {i !== data.length - 1 ? ', ' : ''}
        </>
      );
    });
  };

  showEditor = () => {
    this.setState({
      showEditor: true,
    });
  };

  render() {
    var findContent = (r) => {
      var text = r.find((e) => e ? (e.userName === this.props.user.userName) : false);

      return text ? text.text : '';
    };

    const showActionBar = !this.props.noVoting || this.props.isTeacher;

    const voting = !this.props.noVoting ? (
      <Voting
        karma={this.props.karma}
        top={this.props.top}
        voted={this.props.vote}
        id={this.props.id}
        onChangeVote={this.props.onChangeVote}
        semesterCode={this.props.semesterCode}
        surveyKey={this.props.survey}
      />
    ) : (
      ''
    );

    const teacherControls = this.props.isTeacher ? (
      <small>
        <a href='javascript:void(0)' className='mr-1' onClick={this.showEditor}>
          {this.props.answerAlreadyExists
            ? localization('edit')
            : localization('answer')}
        </a>
      </small>
    ) : (
      ''
    );

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    const seenDate = new Date(this.props.seen);

    return (
      <div className='media comment w-100 mt-3 mb-3'>
        <div className='media-body'>
          <div className='mb-1'>
            <small style={{color: '#999'}}>
              {this.getUserInfo(
                this.props.name,
                this.props.grade,
                this.props.year,
                this.props.programCode,
                this.props.averageGrade
              )}
              {this.props.seen ? (
                <>
                  <FontAwesomeIcon
                    icon={faEye}
                    id={`seen-${this.props.questionNumber}-${this.props.index}`}
                    className='ml-2'
                  />
                  <UncontrolledTooltip
                    placement='right'
                    target={`seen-${this.props.questionNumber}-${this.props.index}`}
                  >
                    {localization('seen')}{' '}
                    {seenDate.toLocaleDateString(
                      localization('locale') === 'cz' ? 'cs-CZ' : 'en-US',
                      options
                    )}
                  </UncontrolledTooltip>
                </>
              ) : (
                ''
              )}
            </small>
            <small style={{color: '#999'}} className='float-right'>
              #{this.props.index}
            </small>
          </div>
          {this.props.isCensored ? (
            <div style={{color: 'lightgray'}}>
              <FontAwesomeIcon icon={faEyeSlash} className='mr-2' />
              <i>{localization('censored')}</i>
            </div>
          ) : (
            <ReactMarkdown
              className='markdown-body'
              source={this.props.markdown}
            />
          )}

          {showActionBar ? (
            <div className='comment-actions'>
              {voting}
              {teacherControls}
            </div>
          ) : (
            <>
              {voting}
              {teacherControls}
            </>
          )}

          <QuestionCommentReactionRow
            show={this.state.showEditor}
            id={this.props.id}
            onAddReaction={(questionId, commentId, markdown) => {
              this.props.onAddReaction(questionId, commentId, markdown);
              this.setState({
                showEditor: false,
              });
            }}
            questionNumber={this.props.questionNumber}
            content={findContent(this.props.reactions)}
          />
          {this.props.reactions
            ? this.props.reactions.map((r, i) => (
                <QuestionCommentTeacherReactions
                  teacherName={r.fullName || r.userName}
                  teacherId={r.id}
                  semester={this.props.semesterCode}
                  survey={this.props.survey}
                  markdown={r.text}
                  questionNumber={this.props.questionNumber}
                  showDelete={
                    r.userName === this.props.user.userName.toUpperCase()
                  }
                  onDelete={() => {
                    this.props.onDelete(
                      this.props.questionNumber,
                      this.props.id,
                      this.props.index - 1,
                      r.id,
                      i
                    );
                  }}
                />
              ))
            : ''}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(QuestionComment);
