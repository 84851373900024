import React, {Component} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader, Button} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClipboardList} from '@fortawesome/free-solid-svg-icons';
import localization from '../lib/localization';
import {Link} from 'react-router-dom';

class Version extends Component {
  constructor(props) {
    super(props);
    this.state = {modal: false};
  }

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  render() {
    return (
      <>
        <div>
          <Link to='/changelog'>
            {localization('version')} {process.env.REACT_APP_VERSION}
          </Link>
        </div>
        {/* <Modal size='lg' isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            <FontAwesomeIcon icon={faClipboardList} className='mr-2' />
            Changelog
          </ModalHeader>
          <ModalBody>
            <ReactMarkdown className='markdown-body' source='abc' />
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={this.toggle}>
              {localization('courses_help_close')}
            </Button>
          </ModalFooter>
        </Modal> */}
      </>
    );
  }
}

export default Version;
