import * as actionTypes from '../Actions/actionTypes';

const defaultState = { 
    professors: []
}

const professors = (state = defaultState, action) => {
    switch(action.type) {
        case actionTypes.TEACHERS_LOADED: {
            return Object.assign({}, {professors: action.data});
        }
        default: return state;
    }
};

export default professors;