import React, {Component} from 'react';
import {Row, Button, Col} from 'reactstrap';
import ReactMde, {commands} from 'react-mde';
import * as Showdown from 'showdown';
import 'react-mde/lib/styles/css/react-mde-all.css';
import xssFilter from 'showdown-xss-filter';
import localization from '../../lib/localization';

const CHAR_LIMIT = 3500;

const listCommands = [
  {
    commands: [
      commands.headerCommand,
      commands.boldCommand,
      commands.italicCommand,
      commands.strikeThroughCommand,
      commands.linkCommand,
      commands.quoteCommand,
      commands.orderedListCommand,
      commands.unorderedListCommand,
    ],
  },
];

class QuestionCommentReactionRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.content ? this.props.content : '',
      tab: 'write',
    };
    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true,
      extensions: [xssFilter],
    });
  }

  handleTabChange = (tab) => {
    this.setState({tab});
  };

  render() {
    return this.props.show ? (
      <>
        <Row className={this.props.className}>
          <Col>
            <ReactMde
              value={this.state.value}
              onChange={(e) => {
                if (e.length <= CHAR_LIMIT)
                  this.setState({
                    value: e,
                  });
              }}
              generateMarkdownPreview={(markdown) =>
                Promise.resolve(this.converter.makeHtml(markdown))
              }
              commands={listCommands}
              selectedTab={this.state.tab}
              onTabChange={(tab) => {
                this.setState({tab});
              }}
              l18n={localization('markdown_editor')}
              disabled={this.state.value.length >= 3500}
            />
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col>
            <i>
              {localization('chars_remaining_1')}{' '}
              {CHAR_LIMIT - this.state.value.length}{' '}
              {localization('chars_remaining_2')}
            </i>
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col className='mt-3'>
            <Button
              disabled={!this.state.value.length}
              color='primary'
              onClick={() => {
                this.props.onAddReaction(
                  this.props.questionNumber,
                  this.props.id,
                  this.state.value
                );
              }}
            >
              {localization('send')}
            </Button>
          </Col>
        </Row>
      </>
    ) : (
      ''
    );
  }
}

export default QuestionCommentReactionRow;
