import React, {Component} from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Badge,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import localization from '../../lib/localization';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChartPie} from '@fortawesome/free-solid-svg-icons';
import CardTooltip from './CardTooltip';

import Chart from 'react-apexcharts';

class TeacherStatistics extends Component {
  render() {
    const options = {
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: [
          localization('rating') + ' 1',
          localization('rating') + ' 2',
          localization('rating') + ' 3',
          localization('rating') + ' 4',
          localization('rating') + ' 5',
        ],
      },
      chart: {
        toolbar: {
          show: false,
        },
        stacked: true,
      },
    };

    const colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'];

    function getSum(total, num) {
      return total + Math.round(num);
    }

    return (
      <Card>
        <CardHeader>
          <FontAwesomeIcon className='mr-2' size='lg' icon={faChartPie} />
          {localization('overall_statistics')}
          <CardTooltip
            content={localization('tooltip_teacher_statistics')}
            className='float-right'
            id='tooltip-statistics'
          />
        </CardHeader>
        <CardBody>
          <ListGroup flush>
            <ListGroupItem>
              {localization('average_obtained_rating')}{' '}
              <span className='float-right'>
                ø {this.props.averageRating}{' '}
                <small>
                  (
                  {this.props.series.reduce((total, num) => {
                    return total + num.data.reduce(getSum, 0);
                  }, 0)}{' '}
                  {localization('ratings')})
                </small>
              </span>
            </ListGroupItem>
            {this.props.series.map((s, i) => {
              return (
                <ListGroupItem>
                  {s.fullName}
                  <Badge
                    className='ml-2'
                    style={{backgroundColor: colors[i % colors.length]}}
                  >
                    {s.name}
                  </Badge>
                  <span className='float-right'>
                    {s.average ? <>ø {s.average} </> : ''}
                    <small>
                      ({s.data.reduce(getSum, 0)} {localization('ratings')})
                    </small>
                  </span>
                </ListGroupItem>
              );
            })}
          </ListGroup>
          <Row>
            <Col>
              <Chart
                options={options}
                series={this.props.series}
                type='bar'
                height='350'
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default TeacherStatistics;
