import {combineReducers} from 'redux';
import subjects from './subjects';
import professors from './professors';
import questions from './questions';
import user from './user';
import surveys from './surveys';
import locale from './locale';
import refresh from './refresh';
import textOptions from './textOptions';
import alert from './alert';
import semesterCache from './semesterCache';
import * as actionTypes from '../Actions/actionTypes';

const appReducer = combineReducers({
  professors,
  subjects,
  questions,
  user,
  surveys,
  locale,
  refresh,
  textOptions,
  alert,
  semesterCache,
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.STORE_RESET: {
      state = Object.assign({}, {locale: state.locale});
      return appReducer(state, action);
    }
    default: {
      return appReducer(state, action);
    }
  }
};

export default rootReducer;
