import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {changeLocale} from '../Actions/userActions';
import localization from '../lib/localization';
import {withRouter} from 'react-router-dom';

class LocaleSwitcher extends Component {
  handleClick = (locale) => {
    return (e) => {
      this.props.changeLocale(locale).then(() => {
        this.props.history.push('/');
        // temp fix
        document.title = `${localization('sideNav_homepage')} | ${localization(
          'title'
        )}`;
      });
    };
  };

  renderItems = () => {
    const list = [];
    let c = 0;
    this.props.locale.options.forEach((l) => {
      list.push(
        <DropdownItem key={c++} onClick={this.handleClick(l)}>
          {l.name}
        </DropdownItem>
      );
    });
    return list;
  };

  render() {
    return (
      <div>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret color='info'>
            {localization('language_name')}
          </DropdownToggle>
          <DropdownMenu right>{this.renderItems()}</DropdownMenu>
        </UncontrolledDropdown>
        {/* <ButtonDropdown toggle={this.toggle} isOpen={this.state.isOpen}>
                    <DropdownToggle caret={true} color="info">{localization("language_name")}</DropdownToggle>
                    <DropdownMenu>
                        {this.renderItems()}
                    </DropdownMenu>
                </ButtonDropdown> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    locale: state.locale,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeLocale: changeLocale(dispatch),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LocaleSwitcher)
);
