import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Card, CardBody } from 'reactstrap';

import localization from '../lib/localization';
import axios from 'axios';

const ReactMarkdown = require('react-markdown');

export default class Homepage extends Component {
  constructor(props) {

    super(props)
    this.state = {
      markdown: 'loading'
    }
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.title = `${localization('locale')} | ${localization(
      'title'
    )}`;
    axios
      .get(`noticeBoard/homePage/${localization('locale')}`)
      .then((r) => {
        this.setState({
          markdown: r.data.text
        })
      })
      .catch((e) => {
        this.props.danger(localization('alerts_connection_failure'));
      });
  }
  render() {
    return (
      <div>
        <Card>
          <CardBody>
            <ReactMarkdown
              className='markdown-body'
              source={this.state.markdown}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}
