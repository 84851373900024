import React, {Component} from 'react';
import localization from '../lib/localization';
import Modal from 'react-modal';

// taken from https://github.com/reactjs/react-modal

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

class TicketControls extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  render() {
    return (
      <div className='mt-3 pr-3 pb-3'>
        <div className='float-right'>
          {/* <input
            type="button"
            onClick={this.props.reset}
            className="m-1 btn btn-link"
            value={localization("surveys_buttons_delete")}
          /> */}
          <input
            type='button'
            onClick={this.openModal}
            className='m-1 btn btn-primary'
            value={localization('surveys_buttons_submit')}
            disabled={this.props.submitDisabled}
          />
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel='Warning Modal'
          >
            <div className='container'>
              <div className='row'>{localization('surveys_modal_warning')}</div>

              <div className='row justify-content-center'>
                <input
                  type='button'
                  onClick={this.props.submit}
                  className='m-1 btn btn-warning btn-lg'
                  value={localization('surveys_buttons_submit')}
                  disabled={this.props.submitDisabled}
                />
              </div>
            </div>

            {/* <input type="button" onClick={this.props.save} className="m-1 btn btn-outline-primary" value={localization("subject_buttons_save")} disabled/> */}
          </Modal>
        </div>
      </div>
    );
  }
}

export default TicketControls;
