import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
    subjects: []
};


const subjects = (state = defaultState, action) => {
    switch(action.type) {
        case actionTypes.SUBJECTS_LOADED: {
            return Object.assign({}, state, { subjects: action.data });
        }
        default: return state; 
    }
}

export default subjects;