import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
    state: {
        loading: false,
        loaded: false,
        error: null
    }
}

const user = (state = defaultState, action) => {
    switch(action.type) {
        case actionTypes.AUTH_LOGGING: {
            return Object.assign({}, state, { state: { loading: true, loaded: false, error: null}})
        }
        case actionTypes.AUTH_LOGIN_FAILURE: {
            return Object.assign({}, state, { state: { loading: false, loaded: false, error: action.data}})
        }
        case actionTypes.AUTH_LOGIN_SUCCESS: {
            return Object.assign({}, state, { state: { loading: false, loaded: true, error: null}}, { userName: action.data.sub})
        }
        case actionTypes.AUTH_LOGOUT: {
            return defaultState;
        }
        default: return state; 
    }
}

export default user;