import React, {Component} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestion} from '@fortawesome/free-solid-svg-icons';

import localization from '../../lib/localization';

// import test from '../../Assets/test.png';

const ReactMarkdown = require('react-markdown');

class HelpModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      markdownContent: this.props.markdownContent,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  render() {
    return (
      <div>
        <Button
          color='primary'
          className={this.props.className}
          onClick={this.toggle}
        >
          <FontAwesomeIcon icon={faQuestion} />
        </Button>
        <Modal size='lg' isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            {localization('courses_help_header')}
          </ModalHeader>
          <ModalBody>
            <ReactMarkdown
              className='markdown-body'
              source={this.state.markdownContent}
            />
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={this.toggle}>
              {localization('courses_help_close')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default HelpModal;
