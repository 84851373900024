import React from 'react';
import localization from '../lib/localization';
import config from '../config';
import Version from './Version';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopyright} from '@fortawesome/free-regular-svg-icons';
import {Link} from 'react-router-dom';

const Footer = () => {
  return (
    <div className='container'>
      <div className='row text-center p-3 mt-3 border-top text-muted'>
        <p className='text-center' style={{width: '100%'}}>
          {/* {localization("footer_copyright")} <ExternalLink href={config.help} text={localization("footer_helpLink")}/> */}
          <span>
            <FontAwesomeIcon size='xs' className='mr-1' icon={faCopyright} />
            {localization('footer_copyright')}
          </span>{' '}
          <Link to={'/about'}>{localization('footer_helpLink')}</Link>
          <Version />
        </p>
      </div>
    </div>
  );
};

export default Footer;
