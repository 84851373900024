import React, {Component} from 'react';
import CourseDetailPage from '../CourseDetailPage';
import {connect} from 'react-redux';
import localization from '../../../lib/localization';
import ReactGA from 'react-ga';
import axios from 'axios';
import {danger} from '../../../Actions/alertActions';

class CourseDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseName: '',
      courseCode: '',
      finishedStudents: 0,
      grades: [],
      subscribedStudents: 0,
      surveyFilledCount: 0,
      teachers: [],
      questions: [],
      averageRating: 0,
      otherCoursesInThisSemester: [],
      courseAvailableInSemesters: [],
      isLoading: true,
      rating: [],
      isTeacher: false,
    };
  }

  loadData = (semester, course, survey) => {
    //semester = 'B182';
    // faculty = 'FIT';
    axios
      .get(
        `results/semesters/${semester}/surveys/${survey}/courses/${course.toUpperCase()}`
      )
      .then((r) => {
        // r.data.data.questions.map((a) => {
        //   a.answers.map((aa) => {
        //     aa.reactions = [];
        //     return true;
        //   });
        //   return true;
        // });

        r.data.data.teachers.forEach((t) => {
          if (!t.userName) t.userName = '';
        });

        var teacher = r.data.data.teachers.find((a) => {
          return a.userName.toUpperCase() === this.props.user.userName;
        });

        r.data.data.history.push({
          averageRating: r.data.data.averageRating,
          courseFilledCount: r.data.data.surveyFilledCount,
          finished: r.data.data.finishedStudents,
          semesterCode: this.props.match.params['semester'],
          subscribed: r.data.data.subscribedStudents,
          successRate:
            (r.data.data.finishedStudents / r.data.data.subscribedStudents) *
            100,
        });

        this.setState(
          {
            isLoading: false,
            subscribedStudents: r.data.data.subscribedStudents,
            finishedStudents: r.data.data.finishedStudents,
            grades: r.data.data.grades,
            rating: r.data.data.rating,
            surveyFilledCount: r.data.data.surveyFilledCount,
            questions: r.data.data.questions,
            averageRating: r.data.data.averageRating,
            courseName: r.data.data.courseName,
            teachers: r.data.data.teachers,
            isTeacher: r.data.data.isTeacher,
            history: r.data.data.history,
            surveyNameCz: r.data.data.surveyNameCz,
            surveyNameEn: r.data.data.surveyNameEn,
            myReaction: teacher ? teacher.answer : '',
            courseId: r.data.data.courseId,
          },
          () => {
            document.title = `${localization('bread_course')} ${
              this.props.match.params['course']
            } - ${
              localization('locale') === 'cz'
                ? this.state.surveyNameCz
                : this.state.surveyNameEn
            } | ${localization('title')}`;
          }
        );
      })
      .catch((e) => {
        this.props.danger(localization('alerts_connection_failure'));
      });
  };

  deleteGlobalReaction = (a, b, markdown) => {
    var teacher = this.state.teachers.find(
      (a) => a.userName.toUpperCase() === this.props.user.userName
    );

    this.setState({
      myReaction: null,
    });
    axios
      .delete('reactions/overall', {
        data: {
          idCourse: this.state.courseId,
          idTeacher: teacher.id,
          semesterCode: this.props.match.params['semester'],
          surveyKey: Number(this.props.match.params['survey']),
        },
      })
      .catch((e) => {
        this.props.danger(localization('alerts_connection_failure'));
      });

    teacher.answer = markdown;
    this.forceUpdate();
  };
  addGlobalReaction = (a, b, markdown) => {
    var teacher = this.state.teachers.find(
      (a) => a.userName.toUpperCase() === this.props.user.userName
    );

    this.setState({
      myReaction: markdown,
    });

    if (markdown)
      axios
        .put('reactions/overall', {
          idCourse: this.state.courseId,
          idTeacher: teacher.id,
          semesterCode: this.props.match.params['semester'],
          surveyKey: Number(this.props.match.params['survey']),
          text: markdown,
        })
        .catch((e) => {
          this.props.danger(localization('alerts_connection_failure'));
        });
    else
      axios
        .delete('reactions/overall', {
          data: {
            idCourse: this.state.courseId,
            idTeacher: teacher.id,
            semesterCode: this.props.match.params['semester'],
            surveyKey: Number(this.props.match.params['survey']),
          },
        })
        .catch((e) => {
          this.props.danger(localization('alerts_connection_failure'));
        });

    teacher.answer = markdown;
    this.forceUpdate();
  };

  addReaction = (questionId, commentId, markdown) => {
    var comment = this.state.questions[questionId].answers.find(
      (a) => a.courseEvaluationAnswerKey === commentId
    );

    var teacher = this.state.teachers.find(
      (a) => a.userName.toUpperCase() === this.props.user.userName
    );

    if (markdown)
      axios
        .put('reactions', {
          evaluationAnswerKey: commentId,
          idTeacher: teacher.id,
          semesterCode: this.props.match.params['semester'],
          surveyKey: Number(this.props.match.params['survey']),
          text: markdown,
        })
        .catch((e) => {
          this.props.danger(localization('alerts_connection_failure'));
        });
    else
      axios
        .delete('reactions', {
          evaluationAnswerKey: commentId,
          idTeacher: teacher.id,
          semesterCode: this.props.match.params['semester'],
          surveyKey: Number(this.props.match.params['survey']),
        })
        .catch((e) => {
          this.props.danger(localization('alerts_connection_failure'));
        });

    var alreadyExistingReaction = comment.reactions.find(
      (e) => e.userName === this.props.user.userName
    );

    if (alreadyExistingReaction) {
      alreadyExistingReaction.text = markdown;
    } else {
      comment.reactions.push({
        fullName: teacher.fullName,
        text: markdown,
        userName: teacher.userName,
        id: teacher.id,
      });
    }

    this.forceUpdate();
  };

  handleChangeVote = (id, state) => {
    this.state.questions.map((q) => {
      var answer = q.answers.find((a) => a.courseEvaluationAnswerKey === id);
      if (answer) {
        if (answer.vote === 'up') {
          if (state === '') {
            answer.karma -= 1;
          } else {
            answer.karma -= 2;
          }
        } else if (answer.vote === 'down') {
          if (state === '') {
            answer.karma += 1;
          } else {
            answer.karma += 2;
          }
        } else {
          if (state === 'up') {
            answer.karma += 1;
          } else {
            answer.karma -= 1;
          }
        }
        answer.vote = state;
        this.forceUpdate();
      }
      return q;
    });
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.title = `${localization('title')}`;
    this.loadData(
      this.props.match.params['semester'],
      this.props.match.params['course'],
      this.props.match.params['survey']
    );
  }

  onDelete = (questionId, commentId, answerId, teacherId) => {
    var comment = this.state.questions[questionId].answers[
      answerId
    ].reactions.find(
      (a) => a.userName.toUpperCase() === this.props.user.userName
    );

    var index = this.state.questions[questionId].answers[
      answerId
    ].reactions.indexOf(comment);

    var filtered = this.state.questions[questionId].answers[
      answerId
    ].reactions.filter(function(value, indexIn, arr) {
      return indexIn !== index;
    });

    axios
      .delete('reactions', {
        data: {
          evaluationAnswerKey: commentId,
          idTeacher: teacherId,
          semesterCode: this.props.match.params['semester'],
          surveyKey: Number(this.props.match.params['survey']),
        },
      })
      .catch((e) => {
        this.props.danger(localization('alerts_connection_failure'));
      });

    this.state.questions[questionId].answers[answerId].reactions = filtered;

    this.forceUpdate();
  };

  render() {
    return (
      <CourseDetailPage
        surveyName={
          localization('locale') === 'cz'
            ? this.state.surveyNameCz
            : this.state.surveyNameEn
        }
        semester={this.props.match.params['semester']}
        course={this.props.match.params['course']}
        isLoading={this.state.isLoading}
        courseName={this.state.courseName}
        subscribedStudents={this.state.subscribedStudents}
        finishedStudents={this.state.finishedStudents}
        grades={this.state.grades}
        surveyFilledCount={this.state.surveyFilledCount}
        rating={this.state.rating}
        averageRating={this.state.averageRating}
        questions={this.state.questions}
        onChangeVote={this.handleChangeVote}
        onAddReaction={this.addReaction}
        onAddGlobalReaction={this.addGlobalReaction}
        teachers={this.state.teachers}
        isTeacher={this.state.isTeacher}
        surveyId={this.props.match.params['survey']}
        history={this.state.history}
        myReaction={this.state.myReaction}
        onDelete={this.onDelete}
        userName={this.props.user.userName}
        onDeleteGlobalReaction={this.deleteGlobalReaction}
        myReaction={this.state.myReaction}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    danger: danger(dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    user: state.user,
    refresh: state.refresh,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseDetail);
