import * as actionTypes from './actionTypes';


export const submitSurveyActionCreator = surveyId => {
    return {
        type: actionTypes.SURVEY_SUBMIT,
        surveyId: surveyId
    }
}

export const submitSubjectActionCreator = (surveyId, subjectId) => {
    return {
        type: actionTypes.SUBJECT_SUBMIT,
        surveyId: surveyId,
        subjectId: subjectId
    }
}

export const changeLocale = dispatch => { 
    return locale => {
        dispatch({
            type: actionTypes.LOCALE_CHANGE,
            data: {
                locale: locale
            }
        });
        dispatch({
            type: actionTypes.STORE_RELOAD
        });
        return Promise.resolve();
    }
}