import locale_CZ from '../Assets/locale_CZ';
import locale_EN from '../Assets/locale_EN';
import store from '../store';
import { format } from './stringUtils';

export const localize = (key, ...args)  => {
    const currentLocaleKey = store.getState().locale.current.key;
    const locale = locales.find(x => x.key === currentLocaleKey)
    if (locale === undefined || locale === null) {
        return "UNDEFINED LOCALIZATION KEY";
    }
    const value = mapToFile(locale.key)[key];
    if (value === undefined || value === null) {
        return "UNDEFINED LOCALIZATION VALUE";
    }
    if (args.length === 0) {
        return value;
    }
    return format(value, ...args)
}

export const getLocProp = (obj, name) => {
    const key = store.getState().locale.current.key;
    if (obj[LOCALIZEDPROPERTIES][key] === undefined || obj[LOCALIZEDPROPERTIES][key] === null) {
        return "UNDEFINED LOCALIZATION KEY";
    }
    return obj[LOCALIZEDPROPERTIES][key][name];
}

const mapToFile = key => {
    switch(key) {
        case localeKeys.cz: {
            return locale_CZ;
        }
        case localeKeys.en: {
            return locale_EN;
        }
        default: return {};
    }
}

export const localeKeys = {
    cz: "CZ",
    en: "EN"
}

export const locales = [
    { key: localeKeys.cz, name: "Česky" },
    { key: localeKeys.en, name: "English" }
]

export const LOCALIZEDPROPERTIES = "localizedProperties";

export default localize

