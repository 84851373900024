import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Row, Progress, Col, Button} from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import CourseFilters from './CourseFilters';
import localization from '../../lib/localization';
import {saveAs} from 'file-saver';
import {Parser} from 'json2csv';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileDownload} from '@fortawesome/free-solid-svg-icons';

class CoursesTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      download: false,
    };
  }

  render() {
    function getColor(value) {
      //value from 0 to 1
      var hue = (value * 120).toString(10);
      return ['hsl(', hue, ',100%,40%)'].join('');
    }

    const coursesColumns = [
      {
        Header: '',
        id: 'row',
        maxWidth: 50,
        filterable: false,
        sortable: false,
        Cell: (row) => {
          return (
            <small style={{color: '#999'}}>
              {row.viewIndex + row.page * row.pageSize}
            </small>
          );
        },
      },
      {
        Header: localization('table_head_code'),
        accessor: 'code', // String-based value accessors!
        width: 80,
        // Filter: ({filter, onChange}) => (
        //   <input
        //     onChange={(event) => onChange(event.target.value)}
        //     value={filter ? filter.value : ''}
        //     style={{
        //       width: '100%',
        //     }}
        //     placeholder={localization('filter')}
        //   />
        // ),
        Cell: (row) => {
          return (
            <Link
              to={
                '/results/semesters/' +
                this.props.semesterCode +
                '/surveys/' +
                this.props.surveyId +
                '/courses/' +
                row.value
              }
            >
              {row.value}
            </Link>
          );
        },
        filterMethod: (filter, row) =>
          row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
        sortMethod: (a, b, desc) => {
          return a.localeCompare(b, 'cs', {sensitivity: 'base'});
        },
        width: 150,
      },
      {
        Header: localization('table_head_name'),
        accessor: 'name',
        // Filter: ({filter, onChange}) => (
        //   <input
        //     onChange={(event) => onChange(event.target.value)}
        //     value={filter ? filter.value : ''}
        //     style={{
        //       width: '100%',
        //     }}
        //     placeholder={localization('filter')}
        //   />
        // ),
        filterMethod: (filter, row) =>
          row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
        sortMethod: (a, b, desc) => {
          return a.localeCompare(b, 'cs', {sensitivity: 'base'});
        },
        Cell: (row) => {
          return (
            <Link
              to={
                '/results/semesters/' +
                this.props.semesterCode +
                '/surveys/' +
                this.props.surveyId +
                '/courses/' +
                row.original.code
              }
            >
              {row.value}
            </Link>
          );
        },
      },
      {
        Header: localization('table_head_success'),
        accessor: 'successRate',
        width: 200,
        Cell: (row) => {
          var color = 'success';
          if (row.value <= 50) {
            color = 'danger';
          } else if (row.value <= 75) {
            color = 'warning';
          }
          return (
            <div className='progress'>
              <div
                className='progress-bar'
                style={{
                  width: row.value + '%',
                  backgroundColor: getColor(row.value * 0.01),
                }}
                role='progressbar'
                aria-valuenow={row.value}
                aria-valuemin='0'
                aria-valuemax='100'
              >
                {row.value}%
              </div>
            </div>
            // <Progress color={color} value={row.value}>
            //   {row.value + '%'}
            // </Progress>
          );
        },
        filterable: false,
      },
      {
        Header: localization('table_head_grade'),
        accessor: 'rating',
        width: 120,
        style: {textAlign: 'center'},
        filterable: false,
        sortMethod: (a, b, desc) => {
          if (desc) {
            a = a === undefined || a === null ? -Infinity : a;
            b = b === null || b === undefined ? -Infinity : b;
          } else {
            a = a === undefined || a === null ? Infinity : a;
            b = b === null || b === undefined ? Infinity : b;
          }
          if (a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
          // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
          return 0;
        },
      },
      {
        Header: localization('table_head_filled'),
        id: 'filledRate',
        accessor: (row) => row.numberOfRated / row.subscribed,
        width: 120,
        style: {textAlign: 'center'},
        filterable: false,
        Cell: (row) => {
          if (row.original.numberOfRated === null)
            row.original.numberOfRated = 0;
          return (
            <small>
              {row.original.numberOfRated}/{row.original.subscribed} (
              <b
                style={{
                  color: getColor(
                    row.original.numberOfRated / row.original.subscribed
                  ),
                }}
              >
                {Math.round(
                  (row.original.numberOfRated / row.original.subscribed) * 100
                )}
                %
              </b>
              )
            </small>
          );
        },
      },
    ];

    return (
      <>
        <Row className='mb-3 mt-3 mb-lg-3 pl-2 pr-2'>
          <CourseFilters
            facultiesOptions={this.props.facultiesOptions}
            departmentsOptions={this.props.departmentsOptions}
            onCheckAttended={this.props.onCheckAttended}
            onCheckRated={this.props.onCheckRated}
            onCheckTeached={this.props.onCheckTeached}
            isCheckedRated={this.props.isCheckedRated}
            isCheckedAttended={this.props.isCheckedAttended}
            isCheckedTeached={this.props.isCheckedTeached}
            onSelectedDepartments={this.props.onSelectedDepartments}
            onSelectedFaculties={this.props.onSelectedFaculties}
            isLoading={this.props.isLoading}
            selectedFaculties={this.props.selectedFaculties}
            selectedDepartments={this.props.selectedDepartments}
            isTeacher={this.props.isTeacher}
          />
        </Row>
        <Row className='mb-3 mt-3 mb-lg-3 pl-2 pr-2'>
          <Col>
            <Button
              color='primary'
              onClick={() => {
                if (!this.table) return;
                const parser = new Parser();
                const csv = parser.parse(
                  this.table
                    .getResolvedState()
                    .sortedData.map((e) => e._original)
                );

                const blob = new Blob([csv], {
                  type: 'text/csv;charset=utf-8;',
                });
                saveAs(
                  blob,
                  this.props.surveyName.replace(/ /g, '_') + '_courses.csv'
                );
              }}
            >
              Export <FontAwesomeIcon icon={faFileDownload} className='ml-1' />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <ReactTable
              ref={(r) => (this.table = r)}
              loading={this.props.isLoading}
              filterable
              previousText={localization('table_previous')}
              nextText={localization('table_next')}
              loadingText={localization('table_loading')}
              noDataText={localization('table_no_data')}
              pageText={localization('table_page')}
              ofText={localization('table_of')}
              rowsText={localization('table_rows')}
              // Accessibility Labels
              pageJumpText={localization('table_page_jump')}
              rowsSelectorText={localization('table_rows_selector')}
              data={this.props.coursesRows}
              columns={coursesColumns}
              className='-highlight'
              minRows='1'
              pageSizeOptions={[15, 25, 50, 100, 1000]}
              defaultPageSize={25}
              page={this.props.page}
              pageSize={this.props.pageSize}
              sorted={this.props.sorted}
              filtered={this.props.filtered}
              onPageChange={this.props.onPageChange}
              onSortedChange={this.props.onSortedChange}
              onFilteredChange={this.props.onFilteredChange}
              onPageSizeChange={this.props.onPageSizeChange}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default CoursesTab;

// class CoursesTab extends Component {
//   constructor(this.props) {
//     super(this.props);
//     this.state = {
//       // coursesRows: this.props.coursesRows,
//       filteredCoursesRows: this.props.coursesRows,
//       selectedFaculties: [],
//       selectedDepartments: [],
//       checkedAttended: false,
//       checkedRated: false,
//     };
//   }

//   componentWillReceiveProps(nextProps) {
//     this.filterCoursesWithCheckboxes(nextProps.coursesRows);
//     // if (this.state.coursesRows != nextProps.coursesRows) {
//     //   this.setState({coursesRows: nextProps.coursesRows}, () => {
//     //     this.filterCoursesWithCheckboxes();
//     //   });
//     // }
//     //console.log(this.props.departmentsOptions);
//   }

//   handleCheckAttended = (e) => {
//     this.setState(
//       {
//         checkedAttended: e,
//       },
//       () => {
//         this.filterCoursesWithCheckboxes();
//       }
//     );
//   };

//   handleCheckRated = (e) => {
//     this.setState(
//       {
//         checkedRated: e,
//       },
//       () => {
//         this.filterCoursesWithCheckboxes();
//       }
//     );
//   };

//   handleSelectDepartments = (e) => {};

//   handleSelectFaculties = (e) => {
//     this.props.onSelectedFaculties(e);
//   };

//   filterCoursesWithCheckboxes = (coursesRows = this.props.coursesRows) => {
//     var newCourses = coursesRows
//       .filter((el) => {
//         if (!this.state.checkedAttended) {
//           return true;
//         } else {
//           return el.attended;
//         }
//       })
//       .filter((el) => {
//         if (!this.state.checkedRated) {
//           return true;
//         } else {
//           return el.rated;
//         }
//       });

//     this.setState({
//       filteredCoursesRows: newCourses,
//     });
//   };

//   render() {
//     const columns = [
//       {
//         Header: localization('table_head_code'),
//         accessor: 'code', // String-based value accessors!
//         Cell: (row) => {
//           return (
//             <Link
//               to={
//                 '/a/results/semesters/' +
//                 this.props.semesterCode +
//                 '/faculties/' +
//                 row.original.faculty +
//                 '/courses/' +
//                 row.value
//               }
//             >
//               {row.value}
//             </Link>
//           );
//         },
//         filterMethod: (filter, row) =>
//           row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
//         sortMethod: (a, b, desc) => {
//           return a.localeCompare(b);
//         },
//         width: 150,
//       },
//       {
//         Header: localization('table_head_name'),
//         accessor: 'nameCz',
//         filterMethod: (filter, row) =>
//           row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
//         sortMethod: (a, b, desc) => {
//           return a.localeCompare(b);
//         },
//       },
//       {
//         Header: localization('table_head_success'),
//         accessor: 'success',
//         Cell: (row) => {
//           var color = 'success';
//           if (row.value <= 50) {
//             color = 'danger';
//           } else if (row.value <= 75) {
//             color = 'warning';
//           }
//           return (
//             <Progress color={color} value={row.value}>
//               {row.value + '%'}
//             </Progress>
//           );
//         },
//         width: 150,
//         filterable: false,
//       },
//       {
//         Header: localization('table_head_grade'),
//         accessor: 'rating',
//         width: 100,
//         style: {textAlign: 'center'},
//         filterable: false,
//       },
//     ];

//     return (
//       <>
//         <Row className='mb-3'>
//           <CourseFilters
//             facultiesOptions={this.props.facultiesOptions}
//             departmentsOptions={this.props.departmentsOptions}
//             onCheckAttended={this.handleCheckAttended}
//             onCheckRated={this.handleCheckRated}
//             onSelectedDepartments={this.props.onSelectedDepartments}
//             onSelectedFaculties={this.props.onSelectedFaculties}
//             isLoading={this.props.isLoading}
//             selectedFaculties={this.props.selectedFaculties}
//             selectedDepartments={this.props.selectedDepartments}
//           />
//         </Row>
//         <Row>
//           <Col>
//             <ReactTable
//               loading={this.props.isLoading}
//               filterable
//               previousText={localization('table_previous')}
//               nextText={localization('table_next')}
//               loadingText={localization('table_loading')}
//               noDataText={localization('table_no_data')}
//               pageText={localization('table_page')}
//               ofText={localization('table_of')}
//               rowsText={localization('table_rows')}
//               // Accessibility Labels
//               pageJumpText={localization('table_page_jump')}
//               rowsSelectorText={localization('table_rows_selector')}
//               data={this.state.filteredCoursesRows}
//               columns={columns}
//               className='-highlight'
//               // resolveData={(data) => {
//               //   data.filter((row) => {});
//               // }}
//             />
//           </Col>
//         </Row>
//       </>
//     );
//   }
// }

// export default CoursesTab;
