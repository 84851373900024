import React, {Component} from 'react';
import {Col} from 'reactstrap';
import Select from 'react-select';
import localization from '../../lib/localization';

class TeacherFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {/* <Col xs='4'>
          <Select
            placeholder={localization('coursefilter_placeholder_faculty')}
            isMulti
            options={this.props.facultiesOptions}
            onChange={this.props.onSelectedFaculties}
            noOptionsMessage={() => localization('coursefilter_no_options')}
            isLoading={this.props.isLoading}
            value={this.props.selectedFaculties}
          />
        </Col> */}
        <Col xs='6'>
          <Select
            placeholder={localization('coursefilter_placeholder_department')}
            isMulti
            options={this.props.departmentsOptions}
            onChange={this.props.onSelectedDepartments}
            noOptionsMessage={() => localization('coursefilter_no_options')}
            isLoading={this.props.isLoading}
            value={this.props.selectedDepartments}
          />
        </Col>
        <Col xs='6'>
          <Select
            placeholder={localization('coursefilter_placeholder_course')}
            isMulti
            options={this.props.coursesOptions}
            onChange={this.props.onSelectedCourses}
            noOptionsMessage={() => localization('coursefilter_no_options')}
            isLoading={this.props.isLoading}
            value={this.props.selectedCourses}
          />
        </Col>
      </>
    );
  }
}

export default TeacherFilters;
