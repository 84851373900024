import React, {Component} from 'react';
import {Tooltip} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestion} from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';

class CardTooltip extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }

  render() {
    // TODO dodelat
    return (
      <>
        <FontAwesomeIcon
          style={this.props.style}
          id={this.props.id}
          className={this.props.className}
          icon={faQuestion}
          size='lg'
        />

        <Tooltip
          placement='right'
          isOpen={this.state.tooltipOpen}
          target={this.props.id}
          toggle={this.toggle}
        >
          <ReactMarkdown
            source={this.props.content}
            className='markdown-body markdown-white'
          />
        </Tooltip>
      </>
    );
  }
}

export default CardTooltip;
