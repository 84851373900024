import React, {Component} from 'react';
import {Row, Col, Card, CardBody} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import localization from '../lib/localization';
import ReactGA from 'react-ga';
import {Link} from 'react-router-dom';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.title = `${localization('about_app')} | ${localization('title')}`;
  }

  render() {
    return (
      <div>
        <Card>
          <CardBody>
            <Row className='mb-2'>
              <Col>
                <Link to='/'>{localization('back_home')}</Link>
              </Col>
            </Row>
            <Row>
              <Col>
                <ReactMarkdown
                  className='markdown-body'
                  source={localization('about_markdown')}
                />
              </Col>
            </Row>
            <Row className='mt-3 pt-3 mb-3 pb-3 justify-content-center'>
              <Col
                lg='3'
                className='d-flex align-items-center text-center img-hover-zoom'
              >
                <a href='https://reactjs.org/' target='_blank'>
                  <img
                    style={{maxWidth: '40%'}}
                    src={process.env.PUBLIC_URL + '/react.png'}
                  />
                </a>
              </Col>
              <Col
                lg='3'
                className='d-flex align-items-center text-center img-hover-zoom'
              >
                <a
                  href='https://spring.io/projects/spring-boot'
                  target='_blank'
                >
                  <img
                    style={{maxWidth: '40%'}}
                    src={process.env.PUBLIC_URL + '/springboot.png'}
                  />
                </a>
              </Col>
              <Col
                lg='3'
                className='d-flex align-items-center text-center img-hover-zoom'
              >
                <a href='https://git-scm.com/' target='_blank'>
                  <img
                    style={{maxWidth: '40%'}}
                    src={process.env.PUBLIC_URL + '/git.png'}
                  />
                </a>
              </Col>
              <Col
                lg='3'
                className='d-flex align-items-center text-center img-hover-zoom'
              >
                <a
                  href='https://about.gitlab.com/product/continuous-integration/'
                  target='_blank'
                >
                  <img
                    style={{maxWidth: '40%'}}
                    src={process.env.PUBLIC_URL + '/gitlabci.png'}
                  />
                </a>
              </Col>
              <Col
                lg='6'
                className='d-flex align-items-center text-center img-hover-zoom pt-4'
              >
                <a href='https://slack.com/' target='_blank'>
                  <img
                    style={{maxWidth: '40%'}}
                    src={process.env.PUBLIC_URL + '/slack.png'}
                  />
                </a>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default About;
