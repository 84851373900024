// export const UPDATE_SUBJECT_RATING = "update_subject_rating";
// export const UPDATE_EXAM_RATING = "update_exam_rating";
// export const UPDATE_PROFESSOR_RATING = "update_professor_rating";
// export const RESET_RATING = "reset_rating";

export const SUBJECT_SUBMIT = 'subject_submit';

export const SURVEYS_LOADING = 'surveys_loading';
export const SURVEYS_LOADED = 'surveys_loaded';
export const SURVEY_SUBMIT = 'survey_submit';

export const QUESTIONS_LOADING = 'question_loading';
export const QUESTIONS_LOADED = 'question_loaded';

export const TEACHERS_LOADING = 'teachers_loading';
export const TEACHERS_LOADED = 'teachers_loaded';

export const SUBJECTS_LOADING = 'subjects_loading';
export const SUBJECTS_LOADED = 'subjects_loaded';

export const LOCALE_CHANGE = 'locale_change';

export const STORE_RESET = 'store_reset';
export const STORE_RELOAD = 'store_reload';

export const AUTH_LOGGING = 'auth_logging';
export const AUTH_LOGIN_SUCCESS = 'auth_login_success';
export const AUTH_LOGIN_FAILURE = 'auth_login_failure';
export const AUTH_LOGOUT = 'auth_logout';

export const TEXT_OPTIONS_LOADING = 'text_options_loading';
export const TEXT_OPTIONS_LOADED = 'text_options_loaded';
export const TEXT_OPTIONS_SURVEY_LOADING = 'text_options_survey_loading';
export const TEXT_OPTIONS_SURVEY_LOADED = 'text_options_survey_loaded';

export const ALERT = 'alert';

export const CACHE_SEMESTERS = 'cache_semesters';
