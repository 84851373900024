import { locales, localeKeys } from '../lib/localization';
import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
    current: locales.find(x => x.key === localeKeys.cz),
    options: locales
}


const locale = (state = defaultState, action) => {
    switch(action.type) {
        case actionTypes.LOCALE_CHANGE: {
            const newState = Object.assign({}, state);
            newState.current = action.data.locale;
            return newState;
        }
        default: {
            return state;
        }
    }
}

export default locale