import * as actionTypes from '../Actions/actionTypes';


const defaultState = {
    const: "CONST"
}

const refresh = (state = defaultState, action) => {
    switch(action.type) {
        case actionTypes.STORE_RELOAD: {
            return Object.assign({}, state);
        }
        default: {
            return state;
        }
    }
}

export default refresh;