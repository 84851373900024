import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faThumbsUp,
  faThumbsDown,
  faStar,
  faFireAlt,
} from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import axios from 'axios';
import {UncontrolledTooltip} from 'reactstrap';
import {Badge} from 'reactstrap';
import {connect} from 'react-redux';
import {danger} from '../../Actions/alertActions';
import localization from '../../lib/localization';

class Voting extends Component {
  // static propTypes = {
  //   /** Stav palce, nabyva hodnot `up`, `down` nebo prazdny retezec.*/
  //   voted: PropTypes.string,
  //   /** Vysledne skore. */
  //   karma: PropTypes.number,
  //   /** Id, ktere bude poslano do onChangeVote. */
  //   id: PropTypes.number.isRequired,
  //   /**
  //    * Zavola se po stisknuti palce.
  //    *
  //    * @param {Number} id Id, ktere bylo predano propem `id`.
  //    * @param {String} voted Novy stav, ktery ma nastat.
  //    */
  //   onChangeVote: PropTypes.func.isRequired,
  //   /** Priznak nejlepsiho komentare. */
  //   top: PropTypes.bool,
  //   /** Priznak zhaveho komentare. */
  //   trending: PropTypes.bool,
  // };

  static defaultProps = {
    voted: '',
    karma: 0,
    top: false,
    trending: false,
  };

  deVote = () => {
    if (this.props.voted === 'disable') return false;
    axios
      .post('answers/rate', {
        vote: 'none',
        evaluationAnswerKey: this.props.id,
        semesterCode: this.props.semesterCode,
        surveyKey: Number(this.props.surveyKey),
      })
      .catch((e) => {
        this.props.danger(localization('alerts_connection_failure'));
      });
    this.props.onChangeVote(this.props.id, '');
  };

  upVote = () => {
    if (this.props.voted === 'disable') return false;
    if (this.props.voted === 'up') {
      this.deVote();
    } else {
      axios
        .post('answers/rate', {
          vote: 'up',
          evaluationAnswerKey: this.props.id,
          semesterCode: this.props.semesterCode,
          surveyKey: Number(this.props.surveyKey),
        })
        .catch((e) => {
          this.props.danger(localization('alerts_connection_failure'));
        });
      this.props.onChangeVote(this.props.id, 'up');
    }
  };

  downVote = () => {
    if (this.props.voted === 'disable') return false;
    if (this.props.voted === 'down') {
      this.deVote();
    } else {
      axios
        .post('answers/rate', {
          vote: 'down',
          evaluationAnswerKey: this.props.id,
          semesterCode: this.props.semesterCode,
          surveyKey: Number(this.props.surveyKey),
        })
        .catch((e) => {
          this.props.danger(localization('alerts_connection_failure'));
        });
      this.props.onChangeVote(this.props.id, 'down');
    }
  };

  getKarmaBadgeColor = (karma) => {
    if (karma > 0) return 'success';
    else if (karma < 0) return 'danger';
    else return 'secondary';
  };

  render() {
    return (
      <span className='mr-2'>
        <Badge
          className='badge-pill'
          color={this.getKarmaBadgeColor(this.props.karma)}
        >
          {this.props.karma}
        </Badge>
        <FontAwesomeIcon
          icon={faThumbsUp}
          size='sm'
          className={classnames('ml-2 mr-1', 'upvote', {
            upvoted: this.props.voted === 'up',
            disabledvote: this.props.voted === 'disable',
          })}
          onClick={this.upVote}
        />

        <FontAwesomeIcon
          icon={faThumbsDown}
          size='sm'
          className={classnames('downvote ml-1 mr-1', {
            downvoted: this.props.voted === 'down',
            disabledvote: this.props.voted === 'disable',
          })}
          onClick={this.downVote}
        />

        {/* <FontAwesomeIcon
          icon={faHeart}
          size='sm'
          className={classnames('ml-1 mr-1 heart', {
            hearted: this.props.hearted,
          })}
        /> */}

        {/* {this.props.top ? (
          <>
            <FontAwesomeIcon
              icon={faStar}
              style={{color: 'gold'}}
              size='sm'
              className='ml-1'
              id={`star-${this.props.id}`}
            />
            <UncontrolledTooltip
              placement='bottom'
              target={`star-${this.props.id}`}
            >
              {' '}
              Toto je nejlepe hodnoceny komentar!
            </UncontrolledTooltip>
          </>
        ) : (
          ''
        )}

        {this.props.trending ? (
          <FontAwesomeIcon
            icon={faFireAlt}
            size='sm'
            className='ml-1'
            style={{color: 'orange'}}
          />
        ) : (
          ''
        )} */}
      </span>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    danger: danger(dispatch),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Voting);
