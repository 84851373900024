import React, { Component } from 'react';
import { connect } from 'react-redux';
import PictoRating from './PictoRating';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Tooltip } from 'reactstrap';
import help from '../Assets/help.svg';
import localization from '../lib/localization';

class TeacherAdder extends Component {

    constructor(props) {
        super(props);
        let tooltipState = {};
        const selectedProfessors = this.props.questions.filter(p => p.isProf)
        selectedProfessors.forEach( p => {
            tooltipState[p.id] = false;
        });
        this.state = {
            dropdownOpen: false,
            tooltipState: tooltipState,
          };

    }

    toggleTooltip = id => {
        const newTooltipState = Object.assign({}, this.state.tooltipState)
        newTooltipState[id] = !this.state.tooltipState[id]
        this.setState({
            tooltipState: newTooltipState
        })
    }

    toggle = () => {
        this.setState({
          dropdownOpen: !this.state.dropdownOpen
        });
      }

      renderTooltip = prof => {
        if (prof.textHelp !== null && prof.textHelp !== undefined) {
            return (
                <div>
                    <img style={{width: "20px", float: "left"}} src={help} alt="" id={"tooltipHelp_teacher" + prof.id}></img>
                    <Tooltip isOpen={this.state.tooltipState[prof.id]} target={"tooltipHelp_teacher" + prof.id} placement="right" toggle={() => this.toggleTooltip(prof.id)}>{prof.textHelp}</Tooltip>
                </div>
            )
        }
    }

    renderPictoProf = () => {
        const list = [];
        const profs = this.props.questions.filter(p => p.isProf);
        profs.forEach(prof => {
            list.push(
                <div key={prof.id}>
                    <div className="form-group row">
                        <div className="col-md-7">
                            <label className="col-form-label" style={{float: "left"}}>{prof.professor.name}, {prof.professor.id} </label>
                            {this.renderTooltip(prof)}
                            <button type="button" className="close" aria-label="Close" onClick={this.props.removeProfessor(prof.id)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="col">
                            <PictoRating selected={prof.value} handleClick={this.props.handleValueClick(prof.id)}/>
                        </div>
                    </div>
                </div>
            );
        });
        return list;
    }

    renderItems = () => {
        const list = [];
        const selectedProfessors = this.props.questions.filter(p => p.isProf)
        this.props
            .possibleProfessors
            .filter(p => !selectedProfessors.some(x => x.professorId === p.professorId))
            // .filter(p => this.props.professors.professors.some(x => x.id === p.professorId))
            // .map(p => this.props.professors.professors.find(x => x.id === p.professorId))
            .sort((a, b) => a.professor.name.localeCompare(b.professor.name))
            .forEach(prof => {
                list.push(
                    <DropdownItem onClick={this.props.handleProfessorClick(prof.id)} key={prof.id}>{prof.professor.name}, {prof.professor.id}</DropdownItem>
                )
            });
        return list;
    }

    render() {
        const { possibleProfessors, questions } = this.props;
        let selectedProfessors = questions.filter(x => x.isProf);
        const disabled =  possibleProfessors.length <= selectedProfessors.length;
        const text = disabled ? localization("surveys_teachers_dropdown_noItems") : localization("surveys_teachers_dropdown_text");
        return (
            <div>
                {this.renderPictoProf()}
                <div className="row">
                    <div className="col">
                        <ButtonDropdown disabled={disabled} isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle disabled={disabled} caret color="secondary">
                                {text}
                            </DropdownToggle>
                            <DropdownMenu>
                                {this.renderItems()}
                            </DropdownMenu>
                        </ButtonDropdown>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        professors: state.professors,
        refresh: state.refresh
    };
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TeacherAdder);