import React from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Spinner,
} from 'reactstrap';
import classnames from 'classnames';
import CoursesTab from './CoursesTab';
import TeachersTab from './TeachersTab';
import ResultsBreadcrumbs from './ResultsBreadcrumbs';
import HelpModal from './HelpModal';
import localization from '../../lib/localization';
import {withRouter} from 'react-router-dom';

// TODO brat data z props

const CoursesPage = (props) => {
  return (
    <div>
      <ResultsBreadcrumbs surveyName={props.surveyName} />

      <div className='row'>
        <div className='col-10'>
          {props.surveyName ? (
            <h2>{props.surveyName}</h2>
          ) : (
            <Spinner color='primary' />
          )}
        </div>

        <div className='col-2'>
          <div className='float-right'>
            <div style={{display: 'inline-flex'}}>
              {/* <HelpModal
                className='ml-3'
                markdownContent={localization('courses_help_markdown')}
              /> */}
            </div>
          </div>
        </div>
      </div>

      <Nav tabs className='mb-0'>
        <NavItem>
          <NavLink
            className={classnames({active: props.activeTab === '1'})}
            onClick={() => {
              props.toggle('1');
            }}
          >
            {localization('courses_tab_courses')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({active: props.activeTab === '2'})}
            onClick={() => {
              props.toggle('2');
            }}
          >
            {localization('courses_tab_teachers')}
          </NavLink>
        </NavItem>
        {props.isTeacher ? (
          <NavItem>
            <NavLink
              onClick={() => {
                props.history.push(
                  `/results/semesters/${props.semesterCode}/surveys/${props.surveyId}/teachers/${props.teacherId}`
                );
              }}
            >
              {localization('my_teacher_page')}
            </NavLink>
          </NavItem>
        ) : (
          ''
        )}
      </Nav>
      <TabContent activeTab={props.activeTab}>
        <TabPane tabId='1'>
          <Row>
            <Col>
              <CoursesTab
                facultiesOptions={props.facultiesOptions}
                departmentsOptions={props.departmentsOptions}
                onSelectedFaculties={props.onSelectedFaculties}
                onSelectedDepartments={props.onSelectedDepartments}
                semesterCode={props.semesterCode}
                coursesRows={props.filteredCourses}
                isLoading={props.isLoading}
                selectedFaculties={props.selectedFaculties}
                selectedDepartments={props.selectedDepartments}
                isCheckedAttended={props.isCheckedAttended}
                isCheckedRated={props.isCheckedRated}
                isCheckedTeached={props.isCheckedTeached}
                onCheckAttended={props.onCheckAttended}
                onCheckRated={props.onCheckRated}
                onCheckTeached={props.onCheckTeached}
                surveyId={props.surveyId}
                isTeacher={props.isTeacher}
                page={props.coursesPage}
                pageSize={props.coursesPageSize}
                onPageChange={props.onCoursesPageChange}
                onPageSizeChange={props.onCoursesPageSizeChange}
                onFilteredChange={props.onCoursesFiltersChange}
                filtered={props.coursesFilters}
                sorted={props.coursesSorts}
                onSortedChange={props.onCoursesSortsChange}
                surveyName={props.surveyName}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId='2'>
          <Row>
            <Col>
              <TeachersTab
                surveyName={props.surveyName}
                facultiesOptions={props.facultiesOptions}
                departmentsOptions={props.departmentsOptions}
                coursesOptions={props.coursesOptions}
                onSelectedFaculties={props.onSelectedFaculties}
                onSelectedDepartments={props.onSelectedDepartments}
                onSelectedCourses={props.onSelectedCourses}
                semesterCode={props.semesterCode}
                teacherRows={props.filteredTeachers}
                isLoading={props.isLoading}
                selectedFaculties={props.selectedFaculties}
                selectedDepartments={props.selectedDepartments}
                selectedCourses={props.selectedCourses}
                surveyId={props.surveyId}
                page={props.teachersPage}
                pageSize={props.teachersPageSize}
                onPageChange={props.onTeachersPageChange}
                onPageSizeChange={props.onTeachersPageSizeChange}
                onFilteredChange={props.onTeachersFiltersChange}
                filtered={props.teachersFilters}
                sorted={props.teachersSorts}
                onSortedChange={props.onTeachersSortsChange}
              />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default withRouter(CoursesPage);
