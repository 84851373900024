import ResultsBreadcrumbs from './ResultsBreadcrumbs';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import HelpModal from './HelpModal';
import localization from '../../lib/localization';
import React from 'react';
import {Link} from 'react-router-dom';
import {Progress, Badge} from 'reactstrap';

const Semesters = (props) => {
  const columns = [
    {
      Header: localization('table_head_semester'),
      accessor: 'semesterCode', // String-based value accessors!
      Cell: (row) => {
        return (
          <>
            <Link
              to={`/results/semesters/${row.original.semesterCode}/surveys/${row.original.id}`}
            >
              {row.value}
            </Link>
          </>
        );
      },
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
      sortMethod: (a, b, desc) => {
        return a.localeCompare(b, 'cs', {sensitivity: 'base'});
      },
      width: 150,
    },
    {
      Header: localization('table_head_name'),
      accessor:
        localization('locale') === 'cz' ? 'surveyNameCz' : 'surveynameEn',
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
      sortMethod: (a, b, desc) => {
        return a.localeCompare(b, 'cs', {sensitivity: 'base'});
      },
      Cell: (row) => {
        return (
          <>
            <Link
              to={`/results/semesters/${row.original.semesterCode}/surveys/${row.original.id}`}
            >
              {row.value}
            </Link>{' '}
            {!row.original.isCourseSurvey ? (
              <Badge size='sm' className='ml-2' color='primary'>
                {localization('non_course')}
              </Badge>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      Header: localization('table_head_filled'),
      accessor: 'surveyFilled',
      Cell: (row) => {
        return <Progress value={row.value}>{Math.round(row.value)}%</Progress>;
      },
      width: 150,
      filterable: false,
      Aggregated: (row) => {
        return '';
      },
    },
  ];

  return (
    <div>
      <ResultsBreadcrumbs />
      <div className='row mb-2'>
        <div className='col-9'>
          <h2>{localization('courses_results_of_semesters')}</h2>
        </div>
        <div className='col'>
          <div className='float-right'>
            {/* <HelpModal
              markdownContent={localization('semesters_help_markdown')}
            /> */}
          </div>
        </div>
      </div>
      {/* <ListOfSemesters semesters={this.state.semesters} /> */}
      <ReactTable
        loading={props.isLoading}
        filterable
        // pivotBy={['semesterCode']}
        previousText={localization('table_previous')}
        nextText={localization('table_next')}
        loadingText={localization('table_loading')}
        noDataText={localization('table_no_data')}
        pageText={localization('table_page')}
        ofText={localization('table_of')}
        rowsText={localization('table_rows')}
        // Accessibility Labels
        pageJumpText={localization('table_page_jump')}
        rowsSelectorText={localization('table_rows_selector')}
        data={props.semesters}
        columns={columns}
        className='-highlight'
        minRows='1'
        pageSizeOptions={[25, 50, 100, 1000]}
        defaultPageSize={25}
      />
    </div>
  );
};

export default Semesters;
