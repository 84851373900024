import React, {Component} from 'react';
import {connect} from 'react-redux';
import localization from '../lib/localization';
import {formatTextOption} from '../lib/stringUtils';
import {Tooltip} from 'reactstrap';
import help from '../Assets/help.svg';

import ReactMde, {commands} from 'react-mde';
import * as Showdown from 'showdown';
import 'react-mde/lib/styles/css/react-mde-all.css';
import xssFilter from 'showdown-xss-filter';
// from https://github.com/andrerpena/react-mde

const MAX_CHAR_LIMIT = 3500;

class TextRating extends Component {
  constructor(props) {
    super(props);
    const optionIds = [];
    for (var key in this.props.options) {
      optionIds.push(key);
    }
    const tooltipOpen = {};
    if (optionIds !== undefined && optionIds !== null) {
      this.props.textOptions.textOptions
        .filter((x) => optionIds.includes(x.id))
        .forEach((e) => {
          tooltipOpen[e.id] = false;
        });
      this.state = {
        tooltipOpen: tooltipOpen,
        optionIds: optionIds,
      };
    }

    this.state.tab = 'write';
    this.state.mdeValue = '';

    this.state.helpOpen = false;

    this.toggleHelp = this.toggleHelp.bind(this);

    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true,
      extensions: [xssFilter],
    });
  }

  toggleHelp() {
    this.setState({
      tooltipHelp: !this.state.tooltipHelp,
    });
  }

  handleTabChange = (tab) => {
    this.setState({tab});
  };

  toggleTooltip = (id) => (e) => {
    this.setState((prevState) => {
      const open = Object.assign({}, prevState.tooltipOpen);
      open[id] = !prevState.tooltipOpen[id];
      return Object.assign({}, prevState, {tooltipOpen: open});
    });
  };

  renderTooltip = (o) => {
    if (o.helpText !== null && o.helpText !== undefined) {
      return (
        <span>
          <img
            style={{width: '20px'}}
            alt=''
            src={help}
            id={'tooltipHelp_options_' + o.id}
          />
          <Tooltip
            isOpen={this.state.tooltipOpen[o.id]}
            target={'tooltipHelp_options_' + o.id}
            placement='top'
            toggle={this.toggleTooltip(o.id)}
          >
            {o.helpText}
          </Tooltip>
        </span>
      );
    }
    return null;
  };
  handleMdeChange = (input, handler) => {
    if (input.length <= MAX_CHAR_LIMIT) {
      this.setState({mdeValue: input});
      handler(input);
    }
  };

  renderOptions = () => {
    const list = [];
    const id = this.props.question.id;
    const optionIds = this.state.optionIds;
    if (optionIds === undefined || optionIds === null) {
      return null;
    }
    list.push(
      <p key={-1} className='font-weight-bold'>
        {localization('surveys_answer_textField_toggles_heading')}
      </p>
    );
    this.props.textOptions.textOptions
      .filter((x) => optionIds.includes(x.id))
      .sort((a, b) => a.id - b.id)
      .forEach((e) => {
        const formated = formatTextOption(e);
        list.push(
          <div key={formated.id} className='form-check'>
            <div style={{display: 'flex'}}>
              <input
                id={formated.id + id}
                className='form-check-input'
                onChange={this.props.handleOption(formated.id)}
                checked={this.props.options[formated.id].value}
                type='checkbox'
              />
              <label htmlFor={formated.id + id}>
                {formated.name} {/*this.renderTooltip(formated) TODO*/}
              </label>
            </div>
          </div>
        );
      });
    return list;
  };

  renderBadgeWarning = (checked, type) => {
    const threshold = 1;
    if (checked && this.props.user[type] <= threshold)
      return (
        <span
          className='anon-warning badge badge-danger'
          data-toggle='tooltip'
          data-placement='right'
          data-trigger='hover focus manual'
          title={localization(
            'surveys_answer_anonymity_warnign',
            this.props.user[type]
          )}
        >
          !
        </span>
      );
  };

  getRemainingChars = () => {
    return MAX_CHAR_LIMIT - this.state.mdeValue.length;
  };

  renderOptions = () => {
    const list = [];
    const optionIds = this.state.optionIds;
    if (optionIds === undefined || optionIds === null) {
      return null;
    }
    list.push(
      <p key={-1} className='font-weight-bold'>
        {localization('surveys_answer_textField_toggles_heading')}
      </p>
    );
    this.props.textOptions.textOptions
      .filter((x) => optionIds.includes(x.id))
      .sort((a, b) => a.id - b.id)
      .forEach((e) => {
        const formated = formatTextOption(e);
        list.push(
          <div key={formated.id} className='form-check'>
            <div style={{display: 'flex'}}>
              <input
                className='form-check-input'
                onChange={this.props.handleOption(formated.id)}
                checked={this.props.options[formated.id].value}
                type='checkbox'
              />
              <label htmlFor='subject-negative-anon-name'>
                {formated.name} {/*this.renderTooltip(formated) TODO*/}
              </label>
            </div>
          </div>
        );
      });
    return list;
  };

  render() {
    let hrefId = '',
      text = '',
      headline = '',
      description = '',
      placeholder = '';
    const {type, handleTextareaQuestion, handleTextCloseQuestion} = this.props;

    // const textValue =
    //   this.props.question.text === null ? '' : this.props.question.text;
    const {value, textName, id} = this.props.question;
    const isPos = value === null || value === undefined || value < 4;
    headline = textName;
    text = isPos
      ? this.props.question.textQuestion
      : this.props.textQuestionBad;
    description = isPos
      ? this.props.question.textDescription
      : this.props.question.textDescriptionBad;
    placeholder = isPos
      ? this.props.question.textPlaceholder
      : this.props.question.textPlaceholderBad;

    hrefId = type + id;

    const listCommands = [
      {
        commands: [
          commands.headerCommand,
          commands.boldCommand,
          commands.italicCommand,
          commands.strikeThroughCommand,
          commands.linkCommand,
          commands.quoteCommand,
          commands.orderedListCommand,
          commands.unorderedListCommand,
        ],
      },
    ];

    return (
      <div id={hrefId} className='form-group p-4 border-top'>
        <div>
          <button
            type='button'
            className='close'
            aria-label='Close'
            onClick={handleTextCloseQuestion(id)}
          >
            <span aria-hidden='true'>&ndash;</span>
          </button>
          <h3>{headline}</h3>
        </div>
        <p>{description}</p>
        <div className='row'>
          <div className='col-md-9'>
            <label
              htmlFor='subject-negative-text-rating'
              className='font-weight-bold'
            >
              {text}
            </label>
            <ReactMde
              placeholder={placeholder}
              value={this.state.mdeValue}
              onChange={(e) =>
                this.handleMdeChange(e, handleTextareaQuestion(id))
              }
              generateMarkdownPreview={(markdown) =>
                Promise.resolve(this.converter.makeHtml(markdown))
              }
              commands={listCommands}
              selectedTab={this.state.tab}
              onTabChange={this.handleTabChange}
              l18n={localization('markdown_editor')}
            />
            <i>
              {localization('chars_remaining_1')}
              {this.getRemainingChars()}
              {localization('chars_remaining_2')}{' '}
              <span
                style={{textDecoration: 'underline'}}
                href='#'
                id={'helptooltip' + id}
              >
                {localization('chars_remaining_help')}
              </span>
              <Tooltip
                placement='bottom'
                isOpen={this.state.tooltipHelp}
                target={'helptooltip' + id}
                toggle={this.toggleHelp}
              >
                {localization('chars_remaining_markdown_tutorial')}
              </Tooltip>
            </i>
          </div>
          <div className='col-md-3'>{this.renderOptions()}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    refresh: state.refresh,
    textOptions: state.textOptions,
  };
}

export default connect(mapStateToProps)(TextRating);
