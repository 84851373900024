import React, { Component } from "react";
import TextRating from "./TextRating";
import localization from "../lib/localization";
import { connect } from "react-redux";

class TextRatings extends Component {
  renderQuestions = () => {
    const list = [];
    const questions = this.props.questions;
    questions
      .sort((a, b) => a.priority - b.priority)
      .forEach(q => {
        if (q.isText) {
          if (q.isTextOpen) {
            list.push(
              <TextRating
                type={q.isProf ? "professor" : "question"}
                key={q.id}
                question={q}
                handleTextareaQuestion={this.props.handleTextareaQuestion}
                handleTextCloseQuestion={this.props.handleTextCloseQuestion}
                handleOption={this.props.handleOption}
                options={this.props.options}
                refresh={this.props.refresh}
                survey={this.props.survey}
              />
            );
          } else {
            list.push(
              <a
                key={q.id}
                href={(q.isProf ? "#professor" : "#question") + q.id}
                className="list-group-item list-group-item-action text-primary"
                onClick={this.props.toggleQuestion(q.id)}
              >
                {q.isProf
                  ? localization(
                      "surveys_answer_textField_teacher_toAdd_text",
                      q.professor.name
                    )
                  : localization(
                      "surveys_answer_textField_question_toAdd_text",
                      q.textName
                    )}
              </a>
            );
          }
        }
      });
    return list;
  };

  // renderTextareasQuestion = () => {
  //     const list = []
  //     const questions = this.props.questions;
  //     questions.forEach(q => {
  //         if (q.isTextOpen && q.isText) {
  //             list.push(
  //                 <TextRating type={q.isProf ? "professor" : "question"}
  //                             key={q.id}
  //                             question={q}
  //                             handleTextareaQuestion={this.props.handleTextareaQuestion}
  //                             handleTextCloseQuestion={this.props.handleTextCloseQuestion}
  //                             handleOption={this.props.handleOption}
  //                             options={this.props.options}
  //                             refresh={this.props.refresh}
  //                             survey={this.props.survey}/>
  //             );
  //         }
  //     });
  //     return list;
  // }

  render() {
    return (
      <div>
        <div className="list-group list-group-flush">
          {this.renderQuestions()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    refresh: state.refresh
  };
};

export default connect(mapStateToProps)(TextRatings);
