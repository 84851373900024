import React, {Component} from 'react';
import Navigation from './Navigation';
import SideNav from './SideNav';
import Footer from './Footer';
import {Route, Switch, withRouter} from 'react-router-dom';
import Ticket from './Ticket';
import Error404 from './Error404';
import Survey from './Survey';
import SurveysOverview from './SurveysOverview';
import AlertBoard from './AlertBoard';
import SemestersPage from './results';
import Courses from './results/courses';
import CourseDetail from './results/courseDetail';
import TeacherDetail from './results/teacherDetail';
import PrivateRoute from './results/PrivateRoute';
import Homepage from './Homepage';
import About from './About';
import SlackFeedback from 'react-slack-feedback';
import Axios from 'axios';
import localization from '../lib/localization';
import DataLossPage from './DataLossPage';
import DataLossPageEn from './DataLossPageEn';
import config from './../config';
import Changelog from './Changelog';

class Layout extends Component {
  render() {
    return (
      <div>
        <SlackFeedback
          theme={{
            header: {
              backgroundColor: '#002d56',
            },
            trigger: {
              hoverBackgroundColor: '#002d56',
              borderRadius: '0px',
            },
          }}
          translations={localization('feedback_translations')}
          onSubmit={(payload, success, error) => {
            var color = 'good';
            if (payload.attachments[0].title === 'improvement')
              color = 'warning';

            if (payload.attachments[0].title === 'bug') color = 'danger';

            Axios.post(
              '/slack',
              {
                fallback: `Zpětná vazba (${payload.attachments[0].title})`,
                color: color,
                title: payload.attachments[0].title,
                title_link: window.location.href,
                text: payload.attachments[0].text,
                footer: `${
                  config.environmentType === 'stg' ? '[STAGING] ' : ''
                }React Slack Feedback`,
                author_name: '',
              }
              // { "fallback": "Feedback (bug)", "author_name": "Anonymous", "color": "danger", "title": "bug", "title_link": "http://localhost:3000/html/anketa/results/semesters/B182/surveys/1", "text": "sss", "footer": "React Slack Feedback" }
            )
              .then((r) => {
                success();
              })
              .catch((e) => {
                error();
                //error();
              });
          }}
          feedbackTypes={[
            {value: 'bug', label: localization('feedback_bug')},
            {value: 'improv', label: localization('feedback_improvement')},
            {value: 'opinion', label: localization('feedback_opinion')},
          ]}
          // onImageUpload={(image, success, error) => {
          //   var form = new FormData();
          //   form.append('image', image);

          //   return fetch('http://10.4.85.252:8080/slack/image', {
          //     method: 'POST',
          //     data: form,
          //   })
          //     .then(({url}) => success(url))
          //     .catch((err) => error(err));
          // }}
        />
        <Navigation />
        <AlertBoard />
        <div className='container'>
          {/* <div className='row mt-5'>
            <div className='col'>
              <ResultsBreadcrumbs />
            </div>
          </div> */}
          <div className='row pt-4'>
            <Switch>
              <Route path='/results' />
              <Route path='/about' />
              <Route path='/data-loss' />
              <Route path='/data-loss-en' />
              <Route path='/changelog' />
              <Route>
                <div className='col-sm-12 col-md-3'>
                  <SideNav />
                </div>
              </Route>
            </Switch>
            <div className='col'>
              <Switch>
                <PrivateRoute
                  exact
                  path='/data-loss'
                  component={DataLossPage}
                />
                <PrivateRoute
                  exact
                  path='/data-loss-en'
                  component={DataLossPageEn}
                />
                <PrivateRoute exact path='/results' component={SemestersPage} />
                <PrivateRoute exact path='/about' component={About} />
                <PrivateRoute exact path='/changelog' component={Changelog} />
                <Route exact path='/' component={Homepage} />
                <Route exact path='/overview' component={SurveysOverview} />

                <PrivateRoute
                  exact
                  path='/results/semesters/:semester/surveys/:survey/courses/:course'
                  component={CourseDetail}
                />
                <PrivateRoute
                  exact
                  path='/results/semesters/:semester/surveys/:survey/teachers/:teacher'
                  component={TeacherDetail}
                />
                <PrivateRoute
                  exact
                  path='/results/semesters/:semester/surveys/:survey/'
                  component={Courses}
                />

                {/* <Route exact path="/a/surveys" component={SurveysOverview}/> */}
                <PrivateRoute
                  exact
                  path='/surveys/:surveyId'
                  component={Survey}
                />
                <PrivateRoute
                  path='/surveys/:surveyId/ticket/:subjectId'
                  component={Ticket}
                />

                <Route component={Error404} />
              </Switch>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Layout);
