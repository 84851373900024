import './lib/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './Style/index.scss';
import {unregister as unregisterServiceWorker} from './registerServiceWorker';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import store from './store';
import {Provider} from 'react-redux';
import Layout from './Components/Layout';
// import 'bootstrap/dist/css/bootstrap.min.css';
import config from './config';
import axios from 'axios';
import LandingPage from './Components/LandingPage';
import LoginPage from './Components/login';
// import Results from "./Components/results/Results";
import './Style/results/index.css';
import './Style/results/login.css';
import './Style/results/markdown.css';
import './Style/results/voting.css';
import './Style/results/comments.scss';
import ReactGA from 'react-ga';
import PrivateRoute from './Components/results/PrivateRoute';
import cookie from 'react-cookies';
import * as Sentry from '@sentry/browser';
import {RouterToUrlQuery} from 'react-url-query';
import SetPassword from './Components/set-password';

if (process.env.REACT_APP_SENTRY_DSN)
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: config.environmentType,
    release: process.env.REACT_APP_COMMIT_HASH,
  });

ReactGA.initialize('UA-144717233-1');
ReactGA.set({dimension2: process.env.REACT_APP_VERSION});
ReactGA.pageview(window.location.pathname + window.location.search);

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.post['crossDomain'] = true;

const Root = () => (
  <Provider store={store}>
    <Router basename={config.urlSubDirectory}>
      <RouterToUrlQuery>
        <div>
          <Switch>
            {cookie.load('Authorization') ? (
              <Route exact path='/' component={Layout} />
            ) : (
              <Route exact path='/' component={LandingPage} />
            )}

            <Route exact path='/login' component={LoginPage} />
            <Route path='/set-password' component={SetPassword} />
            <PrivateRoute path='/' component={Layout} />
          </Switch>
        </div>
      </RouterToUrlQuery>
    </Router>
  </Provider>
);

ReactDOM.render(Root(), document.getElementById('root'));
unregisterServiceWorker();
