import React, {Component} from 'react';
import {Row, Button, Badge} from 'reactstrap';
import QuestionComment from './QuestionComment';
import localization from '../../lib/localization';
import {Link} from 'react-router-dom';

class QuestionTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      perPage: 10,
    };
  }

  getAnswersOnPage = (page) => {
    if (
      this.props.showAll &&
      this.state.page !==
        Math.ceil(this.props.answers.length / this.state.perPage) + 1
    ) {
      this.setState({
        page: Math.ceil(this.props.answers.length / this.state.perPage) + 1,
      });
    }
    var i;
    var results = []; // TODO tady omezit, kdyz jich bude min nez 5
    for (
      i = 0;
      i < (page - 1) * this.state.perPage + this.state.perPage &&
      i < this.props.answers.length;
      i++
    ) {
      results.push(this.props.answers[i]);
    }
    return results;
  };

  render() {
    return (
      <>
        <div>
          {/* <CardHeader> */}
          <div className='sticky-question clearfix'>
            <h4 className='d-inline'>
              {/* <FontAwesomeIcon icon={faComments} className='mr-2' size='lg' /> */}
              {this.props.courseCode ? (
                <Link
                  to={`/results/semesters/${this.props.semesterCode}/surveys/${this.props.survey}/courses/${this.props.courseCode}`}
                >
                  {this.props.question}
                </Link>
              ) : (
                this.props.question
              )}{' '}
              {this.props.courseCode ? (
                <Link
                  to={`/results/semesters/${this.props.semesterCode}/surveys/${this.props.survey}/courses/${this.props.courseCode}`}
                >
                  <Badge color='primary' className='ml-2'>
                    {this.props.courseCode}
                  </Badge>
                </Link>
              ) : (
                ''
              )}
            </h4>
            <span className='float-right'>
              {this.props.answers.length} {localization('answers')}
            </span>
          </div>
          {/* </CardHeader> */}
          {/* <ListGroup flush> */}
          {this.getAnswersOnPage(this.state.page).map((r, i) => {
            return (
              <QuestionComment
                noVoting={this.props.noVoting}
                karma={r.karma}
                averageGrade={r.studentAverageGrade}
                programCode={r.studentProgramCode}
                grade={r.studentGrade}
                name={r.studentName}
                year={r.studentYear}
                markdown={r.text}
                vote={r.vote}
                index={i + 1}
                id={r.courseEvaluationAnswerKey || r.studentEvaluationKey}
                onChangeVote={this.props.onChangeVote}
                top={r.karma >= this.props.answers[0].karma && r.karma !== 0}
                reactions={r.reactions}
                onAddReaction={this.props.onAddReaction}
                questionNumber={this.props.questionNumber}
                semesterCode={this.props.semesterCode}
                survey={this.props.survey}
                isTeacher={this.props.isTeacher}
                onDelete={this.props.onDelete}
                answerAlreadyExists={r.reactions.find(
                  (e) => e ? (e.userName === this.props.userName) : false
                )}
                isCensored={r.isCensored}
                seen={r.seen}
              />
            );
          })}
        </div>
        <Row className='justify-content-center mt-2 mb-2'>
          {this.state.page * this.state.perPage > this.props.answers.length ? (
            ''
          ) : (
            <Button
              color={
                this.state.page * this.state.perPage > this.props.answers.length
                  ? 'secondary'
                  : 'primary'
              }
              size='md'
              onClick={() => this.setState((prev) => ({page: prev.page + 1}))}
            >
              {localization('more_comments')}
            </Button>
          )}
        </Row>
      </>
    );
  }
}

export default QuestionTab;
