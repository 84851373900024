import React, {Component} from 'react';
import {Row, Col, Spinner, Button, Badge} from 'reactstrap';
import ScrollUpButton from 'react-scroll-up-button';
import VisualizationCard from './VisualizationCard';
import TeachersCard from './TeachersCard';
import ResultsBreadcrumbs from './ResultsBreadcrumbs';
import HelpModal from './HelpModal';
import localization from '../../lib/localization';
import QuestionTab from './QuestionTab';
import HistoryChartCard from './HistoryChartCard';
import CourseTableOfContents from './CourseTableOfContents';
import {Card, CardBody, CardHeader} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCommentDots} from '@fortawesome/free-solid-svg-icons';
import CardTooltip from './CardTooltip';

class CourseDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAll: false,
    };
  }

  handleExpandAll = () => {
    this.setState({
      showAll: true,
    });
  };

  render() {
    if (this.props.isLoading) {
      return (
        <>
          <Row>
            <Col>
              <ResultsBreadcrumbs
                courseCode={this.props.course}
                semesterCode={this.props.semester}
                facultyCode={this.props.faculty}
              />
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Spinner color='primary' />
          </Row>
        </>
      );
    }

    return (
      <>
        <ScrollUpButton style={{top: 30, zIndex: 1500}} />
        <Row>
          <Col>
            <ResultsBreadcrumbs surveyName={this.props.surveyName} />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='float-right'>
              <div style={{display: 'inline-flex'}}>
                {/* <SemesterCourseDropdowns /> */}
                {/* <HelpModal
                  className='ml-3'
                  markdownContent={localization('courses_help_markdown')}
                /> */}
              </div>
            </div>

            <h2>
              {this.props.courseName}{' '}
              <Badge className='ml-2' color='primary'>
                {this.props.course}
              </Badge>
            </h2>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            <CourseTableOfContents questions={this.props.questions} />
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            <VisualizationCard
              subscribed={this.props.subscribedStudents}
              finished={this.props.finishedStudents}
              grades={this.props.grades}
              filled={this.props.surveyFilledCount}
              rating={this.props.rating}
              averageRating={this.props.averageRating}
            />
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            <HistoryChartCard history={this.props.history} />
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col xs='12'>
            <TeachersCard
              teachers={this.props.teachers}
              isTeacher={this.props.isTeacher}
              semester={this.props.semester}
              surveyId={this.props.surveyId}
              onAddGlobalReaction={this.props.onAddGlobalReaction}
              myReaction={this.props.myReaction}
              onDeleteGlobalReaction={this.props.onDeleteGlobalReaction}
            />
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col>
            <Card id='questions'>
              <CardHeader>
                <FontAwesomeIcon
                  icon={faCommentDots}
                  size='lg'
                  className='mr-2'
                />
                {localization('course_detail_questions')}
                <span className='float-right'>
                  <Button
                    onClick={this.handleExpandAll}
                    color='primary'
                    size='sm'
                    disabled={this.state.showAll}
                    className='mr-3'
                  >
                    {localization('show_all')}
                  </Button>
                  <CardTooltip
                    content={localization('tooltip_course_questions')}
                    id='tooltip-course-questions'
                  />
                </span>
              </CardHeader>
              <CardBody>
                {this.props.questions.length !== 0
                  ? this.props.questions.map((q, i) => (
                      <Row
                        className='mt-0'
                        id={`question-${i}`}
                        key={`question-${i}`}
                      >
                        <Col>
                          <QuestionTab
                            showAll={this.state.showAll}
                            onChangeVote={this.props.onChangeVote}
                            question={
                              localization('locale') === 'cz'
                                ? q.questionCz
                                : q.questionEn
                            }
                            answers={q.answers}
                            onAddReaction={this.props.onAddReaction}
                            questionNumber={i}
                            isTeacher={this.props.isTeacher}
                            semesterCode={this.props.semester}
                            survey={this.props.surveyId}
                            onDelete={this.props.onDelete}
                            userName={this.props.userName}
                          />
                        </Col>
                      </Row>
                    ))
                  : ''}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default CourseDetailPage;
