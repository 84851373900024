import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Spinner} from 'reactstrap';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import localization from '../../lib/localization';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHome} from '@fortawesome/free-solid-svg-icons';

class ResultsBreadcrumbs extends Component {
  render() {
    return (
      <Breadcrumb tag='nav' listTag='div'>
        <BreadcrumbItem>
          <Link to={'/'}>
            <FontAwesomeIcon icon={faHome} className='mr-2' />
            {localization('bread_home')}
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem active={this.props.surveyName === undefined}>
          {this.props.surveyName === undefined ? (
            localization('bread_results')
          ) : (
            <Link to={'/results'}>{localization('bread_results')}</Link>
          )}
        </BreadcrumbItem>

        {this.props.surveyName !== undefined && (
          <>
            <BreadcrumbItem
              active={this.props.match.params['course'] === undefined}
            >
              {' '}
              {this.props.match.params['course'] === undefined &&
              this.props.match.params['teacher'] === undefined ? (
                this.props.surveyName ? (
                  this.props.surveyName
                ) : (
                  <Spinner color='primary' size='sm' />
                )
              ) : (
                <Link
                  to={`/results/semesters/${this.props.match.params[
                    'semester'
                  ].toUpperCase()}/surveys/${
                    this.props.match.params['survey']
                  }`}
                >
                  {this.props.surveyName}
                </Link>
              )}
            </BreadcrumbItem>
            {this.props.match.params['course'] !== undefined && (
              <>
                <BreadcrumbItem active>
                  {localization('bread_course')}{' '}
                  {this.props.match.params['course'].toUpperCase()}
                </BreadcrumbItem>
              </>
            )}
            {this.props.teacherName !== undefined && (
              <>
                <BreadcrumbItem active>
                  {localization('bread_teacher')} {this.props.teacherName}
                </BreadcrumbItem>
              </>
            )}
          </>
        )}
      </Breadcrumb>
    );
  }
}

export default withRouter(ResultsBreadcrumbs);
