import React, {Component} from 'react';
import Chart from 'react-apexcharts';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChartLine} from '@fortawesome/free-solid-svg-icons';
import localization from '../../lib/localization';
import CardTooltip from './CardTooltip';

class HistoryChartCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      ratings: [],
      successRates: [],
    };
  }

  componentDidMount() {
    var newSuccessRates = [];
    var newRatings = [];
    var newLabels = [];
    this.props.history.map((h) => {
      newLabels.push(h.semesterCode);
      newRatings.push(h.averageRating);
      newSuccessRates.push(h.successRate);
      return true;
    });

    this.setState({
      categories: newLabels,
      ratings: newRatings,
      successRates: newSuccessRates,
    });
  }

  render() {
    const history = this.props.history;
    const options = {
      chart: {
        toolbar: {
          show: false,
        },
        //height: '100px',
        type: 'line',
        stacked: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: ['#FF1654', '#247BA0'],

      stroke: {
        width: [4, 4],
      },
      plotOptions: {
        bar: {
          columnWidth: '20%',
        },
      },
      xaxis: {
        categories: this.state.categories,
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#FF1654',
          },
          labels: {
            style: {
              color: '#FF1654',
            },
          },
          title: {
            text: localization('history_chart_rating'),
          },
          min: 1,
          max: 5,
          decimalsInFloat: 2,
          reversed: true,
        },
        {
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#247BA0',
          },
          labels: {
            style: {
              color: '#247BA0',
            },
          },
          title: {
            text: localization('history_chart_success_rate'),
          },
          min: 0,
          max: 100,
          decimalsInFloat: 2,
        },
      ],
      // tooltip: {
      //   enabled: true,
      //   // shared: false,
      //   // intersect: true,
      //   // x: {
      //   //   show: false,
      //   // },
      // },
      tooltip: {
        x: {
          formatter: function(value, {series, seriesIndex, dataPointIndex, w}) {
            return `${localization('semester')} ${
              history[dataPointIndex].semesterCode
            }`;
          },
        },
        y: {
          formatter: function(value, {series, seriesIndex, dataPointIndex, w}) {
            if (
              seriesIndex === 1 &&
              value &&
              history[dataPointIndex].finished &&
              history[dataPointIndex].subscribed
            )
              return `${value.toFixed(2)}% (${
                history[dataPointIndex].finished
              }/${history[dataPointIndex].subscribed})`;
            else if (
              seriesIndex === 0 &&
              value &&
              history[dataPointIndex].courseFilledCount &&
              history[dataPointIndex].subscribed
            )
              return `${value.toFixed(2)} (${
                history[dataPointIndex].courseFilledCount
              }/${history[dataPointIndex].subscribed})`;
            else if (value) return value.toFixed(2);
          },
        },
      },
      legend: {
        horizontalAlign: 'left',
        offsetX: 40,
      },
    };

    const series = [
      {
        name: localization('history_chart_rating'),
        data: this.state.ratings,
      },
      {
        name: localization('history_chart_success_rate'),
        data: this.state.successRates,
      },
    ];

    return (
      <Card id='progress'>
        <CardHeader>
          <FontAwesomeIcon className='mr-2' size='lg' icon={faChartLine} />
          {localization('history_chart_evolution')}
          <CardTooltip
            style={{color: 'black'}}
            content={localization('tooltip_course_history_chart')}
            className='float-right'
            id='history-chart-tooltip'
          />
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Chart
                options={options}
                series={series}
                type='line'
                height='300'
                width='100%'
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default HistoryChartCard;
