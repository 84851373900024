import React, {Component} from 'react';
import {Row, Col, Card, CardBody} from 'reactstrap';
import {Link} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import localization from '../lib/localization';
import ReactGA from 'react-ga';
import localeEn from '../Assets/locale_EN';

class DataLossPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.title = `${localeEn.data_loss} | ${localeEn.title}`;
  }

  render() {
    return (
      <>
        <Card>
          <CardBody>
            <Row className='mb-2'>
              <Col>
                <Link to='/'>{localeEn.back_home}</Link>
              </Col>
            </Row>
            <Row>
              <Col>
                <ReactMarkdown
                  className='markdown-body'
                  source={localeEn.data_loss_markdown}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default DataLossPage;
