import React, {Component} from 'react';
import i1 from '../Assets/1.svg';
import i2 from '../Assets/2.svg';
import i3 from '../Assets/3.svg';
import i4 from '../Assets/4.svg';
import i5 from '../Assets/5.svg';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Alert} from 'reactstrap';
import {withRouter} from 'react-router-dom';
import {localize, getLocProp} from '../lib/localization';
import {formatSubject} from '../lib/stringUtils';

class SubjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertIsOpen: this.props.location.search.includes('?alert'),
    };
  }

  closeAlert = (e) => {
    this.setState({alertIsOpen: false});
  };

  createList = (survey) => {
    const items = this.props.subjects
      .filter((x) => survey.subjectToRateIds.some((y) => x.id === y))
      .map((e) => formatSubject(e));
    const list = [];
    items.forEach((element) => {
      list.push(
        <li
          key={element.id}
          className='list-group-item list-group-item-action d-flex justify-content-between align-items-center'
        >
          <Link to={'/surveys/' + survey.id + '/ticket/' + element.id}>
            {element.code + ' ' + element.name}
          </Link>
          <span className='mini-rating'>
            <Link
              to={'/surveys/' + survey.id + '/ticket/' + element.id + '?s=5'}
            >
              <img src={i5} alt='' style={{width: '1.3em'}} />
            </Link>
            <Link
              to={'/surveys/' + survey.id + '/ticket/' + element.id + '?s=4'}
            >
              <img src={i4} alt='' style={{width: '1.3em'}} />
            </Link>
            <Link
              to={'/surveys/' + survey.id + '/ticket/' + element.id + '?s=3'}
            >
              <img src={i3} alt='' style={{width: '1.3em'}} />
            </Link>
            <Link
              to={'/surveys/' + survey.id + '/ticket/' + element.id + '?s=2'}
            >
              <img src={i2} alt='' style={{width: '1.3em'}} />
            </Link>
            <Link
              to={'/surveys/' + survey.id + '/ticket/' + element.id + '?s=1'}
            >
              <img src={i1} alt='' style={{width: '1.3em'}} />
            </Link>
          </span>
        </li>
      );
    });
    if (list.length === 0)
      list.push(
        <li
          key='0'
          className='list-group-item list-group-item-action d-flex justify-content-between align-items-center'
        >
          {localize('surveys_subject_allSubjectsRated')}
        </li>
      );
    return <ul className='list-group my-3'>{list}</ul>;
  };

  render() {
    const selectedSurvey = this.props.surveys.surveys.find(
      (x) => x.id === this.props.surveyId
    );
    if (selectedSurvey === undefined) {
      return (
        <div>
          <h3>No subjects available</h3>
        </div>
      );
    }
    return (
      <div>
        <Alert
          color='success'
          isOpen={this.state.alertIsOpen}
          toggle={this.closeAlert}
        >
          {localize('alerts_survey_send_success')}
        </Alert>
        <h2>{getLocProp(selectedSurvey, 'longName')}</h2>
        {this.createList(selectedSurvey)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    subjects: state.subjects.subjects,
    user: state.user,
    surveys: state.surveys,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SubjectList)
);
