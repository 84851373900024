import React, {Component} from 'react';
import {Row, Col, Card, CardBody} from 'reactstrap';
import {Link} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import localization from '../lib/localization';
import ReactGA from 'react-ga';

class DataLossPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.title = `${localization('data_loss')} | ${localization('title')}`;
  }

  render() {
    return (
      <>
        <Card>
          <CardBody>
            <Row className='mb-2'>
              <Col>
                <Link to='/'>{localization('back_home')}</Link>
              </Col>
            </Row>
            <Row>
              <Col>
                <ReactMarkdown
                  className='markdown-body'
                  source={localization('data_loss_markdown')}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default DataLossPage;
