import * as actionTypes from '../Actions/actionTypes';
import axios from 'axios';
import { localeKeys, LOCALIZEDPROPERTIES, localize } from '../lib/localization';
import { dangerAction } from '../Actions/alertActions';

export const getSubjects = dispatch => {
    return () => {
        dispatch({
            type: actionTypes.SUBJECTS_LOADING
        });
        axios.get('/courses')
        .then(res => {
            dispatch({
                type: actionTypes.SUBJECTS_LOADED,
                data: res.data.data.sort((a, b) => a.id - b.id).map(mapSubject)
            });
        })
        .catch( err => {
            if(err.response.status === 401){
                dispatch(dangerAction(localize("alerts_not_authenticated")))
            }
            else {
                dispatch(dangerAction(localize("alerts_connection_failure")));
            }
        });
    }
}

const mapNumber = n => n.toString();

const mapSubject = data => {
    const res = {
        id: data.id.toString(),
        professorIds: data.teacherIds.map(mapNumber),
        questionIds: data.questionIds.map(mapNumber),
        code: data.code
    };

    res[LOCALIZEDPROPERTIES] = {};

    res[LOCALIZEDPROPERTIES][localeKeys.cz] = {
        name: data.nameCz,
    };
    res[LOCALIZEDPROPERTIES][localeKeys.en] = {
        name: data.nameEn,
    };

    return res;
}