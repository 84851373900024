import * as actionTypes from '../Actions/actionTypes';

const defaultState = {
    questions: []
}


const questions = (state = defaultState, action) => {
    switch(action.type) {
        case actionTypes.QUESTIONS_LOADED: {
            return Object.assign({}, state, {questions: action.data});
        }
        default: return state; 
    }
}

export default questions;