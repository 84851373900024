import React, { Component } from 'react';

class PictoRating extends Component {
    
    getClazz = num => {
        let clazz = "smiley"
        return num === this.props.selected ? clazz + " selected" : clazz;
    }
    
    render() {
        const { handleClick} = this.props;
        const wrapper = "col-xs smiley-wrapper";
        return (
            <div>
                <div className="row">
                    <div className={wrapper}>
                        <div className={this.getClazz(5)}>
                            <svg onClick={handleClick(5)} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{"enableBackground": "new 0 0 512 512"}} xmlSpace="preserve"><g><path d="M341.333,213.333c23.573,0,42.667-19.093,42.667-42.667C384,147.093,364.907,128,341.333,128 c-23.573,0-42.667,19.093-42.667,42.667C298.667,194.24,317.76,213.333,341.333,213.333z"/><path d="M170.667,213.333c23.573,0,42.667-19.093,42.667-42.667c0-23.573-19.093-42.667-42.667-42.667 C147.093,128,128,147.093,128,170.667C128,194.24,147.093,213.333,170.667,213.333z"/><path d="M255.996,256.006c-60.999,0-117.879,41.261-139.932,99.062c-4.2,11.008,1.319,23.337,12.327,27.537 s23.337-1.319,27.537-12.327c15.887-41.64,57.194-71.605,100.068-71.605c42.858,0,84.166,29.95,100.072,71.573 c4.206,11.006,16.537,16.518,27.543,12.313s16.518-16.537,12.313-27.543C373.846,297.243,316.971,256.006,255.996,256.006z"/></g>
                            </svg>
                        </div>
                    </div>
                    <div className={wrapper}>
                        <div className={this.getClazz(4)}>
                            <svg onClick={handleClick(4)} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{"enableBackground": "new 0 0 512 512"}} xmlSpace="preserve"><g><path d="M341.333,213.333c23.573,0,42.667-19.093,42.667-42.667C384,147.093,364.907,128,341.333,128 c-23.573,0-42.667,19.093-42.667,42.667C298.667,194.24,317.76,213.333,341.333,213.333z"/><path d="M170.667,213.333c23.573,0,42.667-19.093,42.667-42.667c0-23.573-19.093-42.667-42.667-42.667 C147.093,128,128,147.093,128,170.667C128,194.24,147.093,213.333,170.667,213.333z"/><path d="M240.915,326.248c-26.463,26.464-71.367,26.464-97.83,0c-8.331-8.331-21.839-8.331-30.17,0 c-8.331,8.331-8.331,21.839,0,30.17c43.126,43.126,115.044,43.126,158.17,0c26.463-26.464,71.367-26.464,97.83,0 	c8.331,8.331,21.839,8.331,30.17,0c8.331-8.331,8.331-21.839,0-30.17C355.959,283.123,284.041,283.123,240.915,326.248z"/></g>
                            </svg>
                        </div>
                    </div>                                      
                    <div className={wrapper}>
                        <div className={this.getClazz(3)}>
                            <svg onClick={handleClick(3)} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{"enableBackground": "new 0 0 512 512"}} xmlSpace="preserve"><g><path d="M341.333,213.333c23.573,0,42.667-19.093,42.667-42.667C384,147.093,364.907,128,341.333,128 c-23.573,0-42.667,19.093-42.667,42.667C298.667,194.24,317.76,213.333,341.333,213.333z"/><path d="M170.667,213.333c23.573,0,42.667-19.093,42.667-42.667c0-23.573-19.093-42.667-42.667-42.667 C147.093,128,128,147.093,128,170.667C128,194.24,147.093,213.333,170.667,213.333z"/><path d="M384,320H128c-11.782,0-21.333,9.551-21.333,21.333c0,11.782,9.551,21.333,21.333,21.333h256 c11.782,0,21.333-9.551,21.333-21.333C405.333,329.551,395.782,320,384,320z"/></g>
                            </svg>
                        </div>
                    </div>                      
                    <div className={wrapper}>
                        <div className={this.getClazz(2)}>
                            <svg onClick={handleClick(2)} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{"enableBackground": "new 0 0 512 512"}} xmlSpace="preserve"><g><path d="M341.333,213.333c23.573,0,42.667-19.093,42.667-42.667C384,147.093,364.907,128,341.333,128 c-23.573,0-42.667,19.093-42.667,42.667C298.667,194.24,317.76,213.333,341.333,213.333z"/><path d="M170.667,213.333c23.573,0,42.667-19.093,42.667-42.667c0-23.573-19.093-42.667-42.667-42.667 C147.093,128,128,147.093,128,170.667C128,194.24,147.093,213.333,170.667,213.333z"/><path d="M392.203,300.307c-10.876-4.531-23.366,0.614-27.896,11.49c-25.456,61.112-93.807,81.792-164.104,52.51 c-10.876-4.53-23.366,0.614-27.896,11.49c-4.531,10.876,0.614,23.366,11.49,27.896c90.538,37.714,184.595,9.256,219.896-75.49 C408.224,317.327,403.079,304.837,392.203,300.307z"/></g>
                            </svg>
                        </div>
                    </div>                    
                    <div className={wrapper}>
                        <div className={this.getClazz(1)}>
                            <svg onClick={handleClick(1)} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{"enableBackground": "new 0 0 512 512"}} xmlSpace="preserve"><g><path d="M341.333,213.333c23.573,0,42.667-19.093,42.667-42.667C384,147.093,364.907,128,341.333,128 c-23.573,0-42.667,19.093-42.667,42.667C298.667,194.24,317.76,213.333,341.333,213.333z"/><path d="M170.667,213.333c23.573,0,42.667-19.093,42.667-42.667c0-23.573-19.093-42.667-42.667-42.667 C147.093,128,128,147.093,128,170.667C128,194.24,147.093,213.333,170.667,213.333z"/><path d="M384,256c-11.782,0-21.333,9.551-21.333,21.333C362.667,333.434,312.101,384,256,384s-106.667-50.566-106.667-106.667 c0-11.782-9.551-21.333-21.333-21.333c-11.782,0-21.333,9.551-21.333,21.333c0,79.665,69.669,149.333,149.333,149.333 s149.333-69.669,149.333-149.333C405.333,265.551,395.782,256,384,256z"/></g>
                            </svg>
                        </div>
                    </div>              
                </div>
            </div>
        );
    }
}

export default PictoRating;