import React, {Component} from 'react';
import LoginParticles from '../login/LoginParticles';
import ReactGA from 'react-ga';
import {login, logout, loginWithToken} from '../../Api/auth';
import {connect} from 'react-redux';
import {getSurveys} from '../../Api/surveyApi';
import {getQuestions} from '../../Api/questionApi';
import {getTeachers} from '../../Api/teacherApi';
import {getSubjects} from '../../Api/subjectsApi';
import {getOptions} from '../../Api/textOptionsApi';
import localization from '../../lib/localization';
import {withRouter} from 'react-router-dom';
import config from '../../config';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock} from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import queryString from 'query-string';
import {Row, FormText, FormGroup, Input, FormFeedback} from 'reactstrap';
import {Link} from 'react-router-dom';
import Axios from 'axios';

class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      control: '',
    };
    // queryString.parse(this.props.location.search).token
  }
  render() {
    // TODO pridat i query
    const {from} = this.props.location.state || {from: {pathname: '/'}};
    sessionStorage.setItem('from', from.pathname);

    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <LoginParticles />
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          className='login-wrapper text-center'
        >
          <form className='form-signin'>
            {/* <img
              className='mb-4'
              src='https://getbootstrap.com/docs/4.0/assets/brand/bootstrap-solid.svg'
              alt=''
              width='72'
              height='72'
            /> */}
            <h1 className='h3 mb-3 font-weight-normal'>Nastavení hesla</h1>
            <FormGroup>
              <Input
                valid={this.state.password.length >= 6}
                invalid={
                  this.state.passwordTouched && this.state.password.length < 6
                }
                type='password'
                id='inputEmail'
                className='form-control'
                placeholder={localization('password')}
                onChange={(e) => {
                  this.setState({
                    passwordTouched: true,
                    password: e.target.value,
                  });
                }}
                required
                autofocus
              />
            </FormGroup>
            <FormGroup>
              <Input
                valid={
                  this.state.control.length >= 6 &&
                  this.state.control === this.state.password
                }
                invalid={
                  this.state.controlTouched &&
                  !(
                    this.state.control.length >= 6 &&
                    this.state.control === this.state.password
                  )
                }
                type='password'
                id='inputPassword'
                className='form-control'
                placeholder='Ověření'
                onChange={(e) => {
                  this.setState({
                    controlTouched: true,
                    control: e.target.value,
                  });
                }}
                required
              />
              {this.state.control !== this.state.password ? (
                <FormFeedback>Hesla se neshodují</FormFeedback>
              ) : (
                ''
              )}
              <FormText className='mt-1 mb-3'>
                Heslo musí obsahovat alespoň 6 znaků.
              </FormText>
            </FormGroup>
            {/* <div className='checkbox mb-3'>
              <label>
                <input type='checkbox' value='remember-me' /> Remember me
              </label>
            </div> */}
            <button
              type='button'
              disabled={
                this.state.password !== this.state.control ||
                this.state.password.length < 6
              }
              className={classnames('btn btn-lg btn-block', {
                'btn-success': this.state.success,
                'btn-primary': !this.state.success,
              })}
              onClick={() => {
                Axios.post('/login/create', {
                  token: queryString.parse(this.props.location.search).token,
                  password: this.state.password,
                }).then((r) => {
                  this.setState({
                    success: true,
                  });
                });
              }}
            >
              {this.state.success ? 'Heslo úspěšně nastaveno!' : 'Odeslat'}
            </button>
            <Row className='justify-content-center mt-2'>
              <Link style={{color: 'white'}} to='/'>
                zpět domů
              </Link>
            </Row>
            <p className='mt-5 mb-3 text-muted'>&copy; 2019</p>
          </form>
          {/* You can render <Route> and <NavTabs /> here */}
        </div>
      </div>
    );
  }
}

export default SetPassword;
