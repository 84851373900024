import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import localization from '../lib/localization';
import {formatSurvey} from '../lib/stringUtils';
import config from '../config';

class SideNav extends Component {
  clazz = 'nav-link d-flex justify-content-between align-items-center';

  getclassStart = (path) => {
    return this.getClass(this.props.location.pathname.startsWith(path));
  };

  getclassExact = (path, path2) => {
    return this.getClass(
      this.props.location.pathname === path ||
        this.props.location.pathname === path2
    );
  };

  getClass = (b) => {
    return b ? this.clazz + ' active' : this.clazz;
  };

  renderBadge = (survey) => {
    if (survey.type === config.courseSurveyTypeName) {
      return (
        <span className='badge badge-warning'>
          {survey.subjectToRateIds.length} {localization('remains')}
        </span>
      );
    }
  };

  renderLink = (survey) => {
    return (
      <Link
        key={survey.id}
        className={this.getclassStart('/surveys/' + survey.id)}
        to={'/surveys/' + survey.id}
      >
        {survey.name} {this.renderBadge(survey)}
      </Link>
    );
  };

  renderLinks = () => {
    const list = [];
    this.props.surveys.surveys
      .sort((a, b) => {
        if (
          a.type === config.generalSurveyTypeName &&
          b.type === config.courseSurveyTypeName
        ) {
          return 1;
        }
        if (
          a.type === config.courseSurveyTypeName &&
          b.type === config.generalSurveyTypeName
        ) {
          return -1;
        }
        return a - b;
      })
      .forEach((e) => {
        let formated = Object.assign({}, e, formatSurvey(e));
        list.push(this.renderLink(formated));
      });
    return list;
  };

  render() {
    return (
      <div>
        <nav className='nav nav-pills nav-scroll' id='sidebar'>
          <Link className={this.getclassExact('/')} to='/'>
            {localization('sideNav_homepage')}
          </Link>
          <Link
            className={this.getclassExact('/overview', '/surveys')}
            to='/overview'
          >
            {localization('sideNav_mainPage')}
          </Link>
          <Link className={this.getclassStart('/results')} to='/results'>
            {localization('sideNav_results')}
          </Link>
          {this.renderLinks()}
        </nav>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    surveys: state.surveys,
  };
}

export default withRouter(connect(mapStateToProps)(SideNav));
