import React, {Component} from 'react';
import LoginParticles from './LoginParticles';
import ReactGA from 'react-ga';
import {login, logout, loginWithToken} from '../../Api/auth';
import {connect} from 'react-redux';
import {getSurveys} from '../../Api/surveyApi';
import {getQuestions} from '../../Api/questionApi';
import {getTeachers} from '../../Api/teacherApi';
import {getSubjects} from '../../Api/subjectsApi';
import {getOptions} from '../../Api/textOptionsApi';
import localization from '../../lib/localization';
import {withRouter} from 'react-router-dom';
import config from '../../config';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock} from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import {UncontrolledCollapse} from 'reactstrap';
import classNames from 'classnames';
import CardTooltip from '../results/CardTooltip';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: config.environmentType === 'stg' ? config.defaultUser : '',
      password: '',
      loginState: '',
    };
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.title = `${localization('login')} | ${localization('title')}`;
  }

  handleLogin = () => {
    // this.props.login('test', 'password').then(() => {
    //   this.props.getSurveys();
    //   this.props.getOptions();
    //   this.props.getQuestions();
    //   this.props.getTeachers();
    //   this.props.getSubjects();
    // });
    //this.props.history.push('/a');
    const {from} = this.props.location.state || {from: {pathname: '/'}};

    Axios.post('/login/external', {
      userId: this.state.username,
      password: this.state.password,
    })
      .then((r) => {
        const {from} = this.props.location.state || {from: {pathname: '/'}};
        sessionStorage.setItem('from', from.pathname);
        window.location =
          window.location.origin.toString() +
          config.urlSubDirectory +
          '?token=' +
          r.data;
      })
      .catch((e) => {
        this.setState({loginState: 'error'});
      });
  };

  handleLoginLDAP = () => {
    // this.props.login('test', 'password').then(() => {
    //   this.props.getSurveys();
    //   this.props.getOptions();
    //   this.props.getQuestions();
    //   this.props.getTeachers();
    //   this.props.getSubjects();
    // });
    //this.props.history.push('/a');
    const {from} = this.props.location.state || {from: {pathname: '/'}};

    Axios.post('/login/externalLdap', {
      userId: this.state.username,
      password: this.state.password,
    })
      .then((r) => {
        const {from} = this.props.location.state || {from: {pathname: '/'}};
        sessionStorage.setItem('from', from.pathname);
        window.location =
          window.location.origin.toString() +
          config.urlSubDirectory +
          '?token=' +
          r.data;
      })
      .catch((e) => {
        this.setState({loginState: 'error'});
      });
  };


  handleSSO = () => {
    window.location = config.ssoLoginUrl;
  };

  handleTestLogin = () => {
    window.location = config.ssoLoginUrl + this.state.username;
  };

  handleChangeUsername = (e) => {
    this.setState({username: e.target.value});
  };

  handleChangePassword = (e) => {
    this.setState({password: e.target.value});
  };

  render() {
    // TODO pridat i query
    const {from} = this.props.location.state || {from: {pathname: '/'}};
    sessionStorage.setItem('from', from.pathname);

    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <LoginParticles />
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          className='login-wrapper text-center'
        >
          <form className='form-signin'>
            {/* <img
              className='mb-4'
              src='https://getbootstrap.com/docs/4.0/assets/brand/bootstrap-solid.svg'
              alt=''
              width='72'
              height='72'
            /> */}
            <h1 className='h3 mb-3 font-weight-normal'>
              <FontAwesomeIcon icon={faLock} className='mr-3' />
              {localization('survey_login')}
            </h1>
            <button
              type='button'
              className='btn btn-lg btn-primary btn-block mb-3'
              onClick={this.handleSSO}
              disabled={config.environmentType === 'stg'}
            >
              {localization('log_in_sso')}
            </button>
            
            <CardTooltip
              content={localization('tooltip_LDAP_login')}
              id='tooltip-LDAP'
            />
              

           <button
              className='btn btn-lg btn-light btn-block mb-3'
              id='toggler2'
              style={{marginBottom: '1rem'}}
            >
              LDAP přihlášení
            </button>

            <UncontrolledCollapse toggler='#toggler2'>
              <label for='inputEmail' className='sr-only'>
                {localization('username')}
              </label>
              <input
                type='text'
                id='inputEmail'
                className='form-control'
                placeholder={localization('username')}
                value={this.state.username}
                onChange={this.handleChangeUsername}
                required
                autofocus
              />
              <label for='inputPassword' className='sr-only'>
                {localization('password')}
              </label>
              <input
                type='password'
                id='inputPassword'
                className='form-control'
                placeholder={localization('password')}
                value={this.state.password}
                onChange={this.handleChangePassword}
                required
              />
              {/* <div className='checkbox mb-3'>
              <label>
                <input type='checkbox' value='remember-me' /> Remember me
              </label>
            </div> */}
              <button
                className={classNames('btn btn-lg btn-block btn-success')}
                onClick={this.handleLoginLDAP}
                type='button'
              >
                {localization('log_in')}
              </button>
              <p class='mt-2' style={{color: 'red'}}>
                {this.state.loginState ? 'Špatné heslo nebo username' : ''}
              </p>
            </UncontrolledCollapse>

            <button
              className='btn btn-lg btn-light btn-block mb-3'
              id='toggler'
              style={{marginBottom: '1rem'}}
            >
              Interní přihlášení
            </button>

            <UncontrolledCollapse toggler='#toggler'>
              <label for='inputEmail' className='sr-only'>
                {localization('username')}
              </label>
              <input
                type='text'
                id='inputEmail'
                className='form-control'
                placeholder={localization('email')}
                value={this.state.username}
                onChange={this.handleChangeUsername}
                required
                autofocus
              />
              <label for='inputPassword' className='sr-only'>
                {localization('password')}
              </label>
              <input
                type='password'
                id='inputPassword'
                className='form-control'
                placeholder={localization('password')}
                value={this.state.password}
                onChange={this.handleChangePassword}
                required
              />
              {/* <div className='checkbox mb-3'>
              <label>
                <input type='checkbox' value='remember-me' /> Remember me
              </label>
            </div> */}
              <button
                className={classNames('btn btn-lg btn-block btn-success')}
                onClick={this.handleLogin}
                type='button'
              >
                {localization('log_in')}
              </button>
              <p class='mt-2' style={{color: 'red'}}>
                {this.state.loginState ? 'Špatné heslo nebo username' : ''}
              </p>
            </UncontrolledCollapse>


            {config.environmentType === 'stg' ? (
              <button
                type='button'
                className='btn btn-lg btn-danger btn-block mt-3'
                onClick={this.handleTestLogin}
              >
                {localization('log_in_test')}
              </button>
            ) : (
              ''
            )}
            <p className='mt-5 mb-3 text-muted'>&copy; 2019</p>
          </form>
          {/* You can render <Route> and <NavTabs /> here */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    refresh: state.refresh,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginWithToken: loginWithToken(dispatch),
    login: login(dispatch),
    logout: logout(dispatch),
    getSurveys: getSurveys(dispatch),
    getQuestions: getQuestions(dispatch),
    getSubjects: getSubjects(dispatch),
    getTeachers: getTeachers(dispatch),
    getOptions: getOptions(dispatch),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoginPage)
);
