import React, {Component} from 'react';
import {Button, Card, Col, Row, CardHeader, CardBody} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import QuestionCommentReactionRow from './QuestionCommentReactionRow';
import localization from '../../lib/localization';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBriefcase} from '@fortawesome/free-solid-svg-icons';
import CardTooltip from './CardTooltip';
import {Link} from 'react-router-dom';

class SemesterOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditor: false,
    };
  }
  render() {
    return (
      <Card>
        <CardHeader>
          <FontAwesomeIcon className='mr-2' size='lg' icon={faBriefcase} />
          {localization('semester_reaction')}
          <span className='float-right'>
            {this.props.isMe ? (
              <>
                <Button
                  onClick={() => {
                    this.setState({showEditor: true});
                  }}
                  color='primary'
                  size='sm'
                  className='mr-2'
                >
                  {this.props.myReaction
                    ? localization('global_change')
                    : localization('global_react')}
                </Button>
                <Button
                  color='danger'
                  className='mr-3'
                  size='sm'
                  disabled={!this.props.myReaction}
                  onClick={() => {
                    this.props.onDeleteSemesterReaction(
                      this.props.questionNumber,
                      this.props.id,
                      this.state.value
                    );
                  }}
                >
                  {localization('global_delete')}
                </Button>
              </>
            ) : (
              ''
            )}
            <CardTooltip
              content={localization('tooltip_teacher_overview')}
              id='tooltip-overall-reaction'
            />
          </span>
        </CardHeader>
        <CardBody>
          {this.state.showEditor ? (
            <QuestionCommentReactionRow
              show={this.state.showEditor}
              onAddReaction={(a, b, c) => {
                this.setState({showEditor: false});
                this.props.onAddSemesterReaction(a, b, c);
              }}
              content={this.props.myReaction}
              className='mb-3'
            />
          ) : this.props.myReaction ? (
            <div className='media-body teacher-overall-comment'>
              <div className='mb-1'>
                <small>
                  <Link
                    to={`/results/semesters/${this.props.semesterCode}/surveys/${this.props.survey}/teachers/${this.props.teacher.id}`}
                  >
                    <FontAwesomeIcon
                      size='sm'
                      icon={faBriefcase}
                      className='mr-1'
                    />{' '}
                    {this.props.teacher.fullName}
                  </Link>
                </small>
              </div>
              <ReactMarkdown
                source={this.props.myReaction}
                className='markdown-body'
              />
            </div>
          ) : (
            <ReactMarkdown
              source={localization('teacher_has_not_reacted')}
              className='markdown-body'
            />
          )}
        </CardBody>
      </Card>
    );
  }
}

export default SemesterOverview;
