import React from 'react';
import Particles from 'react-particles-js';

const LoginParticles = () => {
  return (
    <div
      style={{
        position: 'absolute',
        background:
          'linear-gradient(to right, #002d56, #002d56, #002d56)' /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <Particles
        height='99vh'
        params={{
          particles: {
            number: {
              value: 50,
            },
            size: {
              value: 3,
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: 'repulse',
              },
            },
          },
        }}
      />
    </div>
  );
};

export default LoginParticles;
