import React from 'react';
import {Col, Label, Input, Row} from 'reactstrap';
import Select from 'react-select';
import localization from '../../lib/localization';
import {POINT_CONVERSION_COMPRESSED} from 'constants';

const CourseFilters = (props) => {
  return (
    <>
      {/* <Col xs='12' lg='4' className='mt-1 mt-lg-0 mb-1 mb-lg-0'>
        <Select
          placeholder={localization('coursefilter_placeholder_faculty')}
          isMulti
          options={props.facultiesOptions}
          onChange={props.onSelectedFaculties}
          noOptionsMessage={() => localization('coursefilter_no_options')}
          isLoading={props.isLoading}
          value={props.selectedFaculties}
        />
      </Col> */}

      <Col xs='12' lg='6' className='mt-1 mt-lg-0 mb-1 mb-lg-0'>
        <Select
          placeholder={localization('coursefilter_placeholder_department')}
          isMulti
          options={props.departmentsOptions}
          onChange={props.onSelectedDepartments}
          noOptionsMessage={() => localization('coursefilter_no_options')}
          isLoading={props.isLoading}
          value={props.selectedDepartments}
        />
      </Col>

      <Col xs='12' lg='6' className='mt-1 mt-lg-0 mb-1 mb-lg-0'>
        <Row>
          <Col>
            <Label check className='ml-4 ml-lg-0'>
              <Input
                checked={props.isCheckedAttended}
                type='checkbox'
                onChange={props.onCheckAttended}
              />{' '}
              {localization('coursefilter_only_attended')}
            </Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label check className='ml-4 ml-lg-0'>
              <Input
                type='checkbox'
                checked={props.isCheckedRated}
                onChange={props.onCheckRated}
              />{' '}
              {localization('coursefilter_only_rated')}
            </Label>
          </Col>
        </Row>
        {props.isTeacher ? (
          <Row>
            <Col>
              <Label check className='ml-4 ml-lg-0'>
                <Input
                  type='checkbox'
                  checked={props.isCheckedTeached}
                  onChange={props.onCheckTeached}
                />{' '}
                {localization('coursefilter_only_teached')}
              </Label>
            </Col>
          </Row>
        ) : (
          ''
        )}
      </Col>
    </>
  );
};

// CourseFilters.propTypes = {
//   filterAttendedChecked: PropTypes.bool.isRequired,
//   filterRatedChecked: PropTypes.bool.isRequired,
//   onSelectedDepartments: PropTypes.func.isRequired,
//   onSelectedFaculties: PropTypes.func.isRequired,
//   onCheckAttended: PropTypes.func.isRequired,
//   onCheckRated: PropTypes.func.isRequired,
//   isLoading: PropTypes.bool.isRequired,
//   selectedDepartments: PropTypes.array,
//   selectedFaculties: PropTypes.array,
//   facultiesOptions: PropTypes.array.isRequired,
//   departmentsOptions: PropTypes.array.isRequired,
// };

export default CourseFilters;
