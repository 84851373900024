import React, {Component} from 'react';
import {Card, CardHeader, CardBody, Button, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUsers, faBriefcase} from '@fortawesome/free-solid-svg-icons';
import QuestionCommentReactionRow from './QuestionCommentReactionRow';
import localization from '../../lib/localization';
import {Link} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import CardTooltip from './CardTooltip';

class TeachersCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditor: false,
    };
  }

  render() {
    return (
      <Card id='teachers'>
        <CardHeader>
          <FontAwesomeIcon icon={faUsers} className='mr-2' size='lg' />
          {localization('teachers')}
          <span className='float-right'>
            {this.props.isTeacher ? (
              <>
                <Button
                  onClick={() => {
                    this.setState({showEditor: true});
                  }}
                  className='mr-2'
                  color='primary'
                  size='sm'
                >
                  {this.props.myReaction
                    ? localization('global_change')
                    : localization('global_react')}
                </Button>
                <Button
                  color='danger'
                  className='mr-3'
                  size='sm'
                  disabled={!this.props.myReaction}
                  onClick={(a, b, c) => {
                    this.setState({showEditor: false});
                    this.props.onDeleteGlobalReaction(a, b, c);
                  }}
                >
                  {localization('global_delete')}
                </Button>
              </>
            ) : (
              ''
            )}
            <CardTooltip
              content={localization('tooltip_course_teachers')}
              id='tooltip-teachers'
            />
          </span>
        </CardHeader>

        <CardBody>
          <QuestionCommentReactionRow
            show={this.state.showEditor}
            onAddReaction={(a, b, c) => {
              this.setState({showEditor: false});
              this.props.onAddGlobalReaction(a, b, c);
            }}
            content={this.props.myReaction}
            className='mb-3'
          />
          {this.props.teachers.length === 0
            ? localization('no_teachers_to_show')
            : ''}
          {this.props.teachers
            ? this.props.teachers.map((t, i) => {
                if (t.answer)
                  return (
                    <div className='mt-2 mb-2 ' key={`teacher-${i}`}>
                      <div className='media teacher-overall-comment'>
                        <div className='media-body'>
                          <div className='mb-2'>
                            <Link
                              to={`/results/semesters/${this.props.semester}/surveys/${this.props.surveyId}/teachers/${t.id}`}
                            >
                              <FontAwesomeIcon
                                icon={faBriefcase}
                                className='mr-1'
                                size='sm'
                              />{' '}
                              {t.fullName}
                            </Link>
                            :
                          </div>
                          <ReactMarkdown className='markdown-body'>
                            {t.answer}
                          </ReactMarkdown>
                        </div>
                      </div>
                    </div>
                  );
                else
                  return (
                    <div className='mt-1 mb-1' key={`teacher-${i}`}>
                      <div className='media teacher-no-reaction'>
                        <div className='media-body'>
                          <Link
                            to={`/results/semesters/${this.props.semester}/surveys/${this.props.surveyId}/teachers/${t.id}`}
                          >
                            <FontAwesomeIcon
                              icon={faBriefcase}
                              className='mr-1'
                              size='sm'
                            />{' '}
                            {t.fullName}
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
              })
            : ''}
        </CardBody>
      </Card>
    );
  }
}

export default TeachersCard;
