import React, {Component} from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import ReactGA from 'react-ga';
import localization from '../lib/localization';
import {login, logout, loginWithToken} from '../Api/auth';
import {connect} from 'react-redux';
import {getSurveys} from '../Api/surveyApi';
import {getQuestions} from '../Api/questionApi';
import {getTeachers} from '../Api/teacherApi';
import {getSubjects} from '../Api/subjectsApi';
import {getOptions} from '../Api/textOptionsApi';
import {withRouter} from 'react-router-dom';
import config from '../config';
import cookie from 'react-cookies';
import {Card, CardBody} from 'reactstrap';
import axios from 'axios';
import {danger} from '../Actions/alertActions';
// import {getHttpError} from '../Assets/utils'


const ReactMarkdown = require('react-markdown');

class LandingPage extends Component {
  // componentDidMount() {
  //   //ReactGA.pageview(window.location.pathname + window.location.search);
  // }

  markDown = '';

  constructor(props) {
    super(props)
    this.state = {
      markdown: 'loading'
    }
  }

  componentDidMount = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.title = `Vítejte | ${localization('title')}`;
    axios
      .get(`noticeBoard/landingPage/${localization('locale')}`)
      .then((r) => {
        this.setState({
          markdown: r.data.text
        })
      })
      .catch((e) => {
        alert()
        console.log(this.props.danger)
        this.props.danger('aaa');
      });
    let token = undefined;
    const parameters = this.props.location.search;
    if (parameters !== '' && parameters !== undefined && parameters !== null) {
      token = parameters
        .substr(1)
        .split('&')
        .find((e) => e.includes(config.queryTokenName));
      if (token !== undefined) {
        token = token.split('=')[1];
      } else {
        token = undefined;
      }
    }
    if (token === undefined) {
      token = cookie.load('Authorization');
    }
    if (token !== undefined && token !== 'undefined') {
      const expires = new Date(Date.now() + 1000 * 60 * 60 * 48);
      cookie.save('authorizedUntil', expires.toISOString(), {
        path: '/',
        expires,
        //httpOnly: true
      });
      cookie.save('Authorization', String(token), {
        path: '/',
        expires,
      });
      this.props.loginWithToken(token).then(() => {
        this.props.getSurveys();
        this.props.getQuestions();
        this.props.getTeachers();
        this.props.getSubjects();
        this.props.getOptions();
      });
      if (sessionStorage.getItem('from') === '/') {
        this.props.history.push('/');
        window.location.reload();
      } else {
        this.props.history.push(sessionStorage.getItem('from'));
        sessionStorage.setItem('from', '');
        window.location.reload();
      }
    }
  };

  render() {
    return (
      <div>
        <Navigation />

        <div className='container mt-5'>
          <Card>
            <CardBody>
              <h1 className='mb-3'>{localization('landing_title')}</h1>
              <ReactMarkdown
                className='markdown-body'
                source={this.state.markdown}
              />
            </CardBody>
          </Card>
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    refresh: state.refresh,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginWithToken: loginWithToken(dispatch),
    login: login(dispatch),
    logout: logout(dispatch),
    getSurveys: getSurveys(dispatch),
    getQuestions: getQuestions(dispatch),
    getSubjects: getSubjects(dispatch),
    getTeachers: getTeachers(dispatch),
    getOptions: getOptions(dispatch),
    danger: danger(dispatch),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LandingPage)
);