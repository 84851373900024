import React, {Component} from 'react';
import {connect} from 'react-redux';
import Ticket from './Ticket';
import SubjectList from './SubjectList';
import config from '../config';
import ReactGA from 'react-ga';

class Survey extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const surveyId = this.props.match.params['surveyId'];
    if (surveyId === undefined || surveyId === null) {
      this.props.history.push('/');
    }
    const survey = this.props.surveys.surveys.find((x) => x.id === surveyId);
    if (survey === undefined) {
      this.props.history.push('/');
    }
  }

  childKey = 0;
  lastSurveyId = '';

  render() {
    const surveyId = this.props.match.params['surveyId'];

    if (surveyId !== this.lastSurveyId) {
      ++this.childKey;
      this.lastSurveyId = surveyId;
    }

    if (surveyId === undefined || surveyId === null) {
      return null;
    }
    const survey = this.props.surveys.surveys.find((x) => x.id === surveyId);
    if (survey === undefined) {
      return null;
    }
    if (survey.type === config.generalSurveyTypeName) {
      return (
        <div>
          <Ticket key={this.childKey} {...this.props} />
        </div>
      );
    } else if (survey.type === config.courseSurveyTypeName) {
      return (
        <div>
          <SubjectList surveyId={surveyId} />
        </div>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    surveys: state.surveys,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Survey);
