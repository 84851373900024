import axios from 'axios';
import * as actionTypes from '../Actions/actionTypes';
import {dangerAction} from '../Actions/alertActions';
import {localize} from '../lib/localization';
import cookie from 'react-cookies';

export const saveToken = (token) => {
  //sessionStorage.setItem('Authorization', token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${cookie.load(
    'Authorization'
  )}`;
};

export const getSavedToken = () => {
  return cookie.load('Authorization');
};

const parseJwt = (token) => {
  var data = token.split('.')[1];
  var replaced = data.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(replaced));
};

export const loginWithToken = (dispatch) => (token) => {
  saveToken(token);
  dispatch(loginSuccessAction(parseJwt(token)));
  return Promise.resolve();
};

export const login = (dispatch) => {
  return (userName, password) => {
    dispatch(loggingAction());
    return axios
      .post(
        '/login/login',
        {
          userName: userName,
          password: password,
        },
        {
          headers: {
            Authorization: null,
          },
        }
      )
      .then((res) => {
        saveToken(res.data);
        dispatch(loginSuccessAction(parseJwt(res.data)));
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.status);
        console.log(err.status);
        console.log(err.response);
        if(401 === err.response.status )
        {
          dispatch(loginFailureAction());
          dispatch(dangerAction(localize('alerts_not_authenticated')));

        }
        else {
          dispatch(loginFailureAction());
          dispatch(dangerAction(localize('alerts_connection_failure')));
        }
      });
  };
};

export const logout = (dispatch) => {
  return () => {
    dispatch(logoutAction());
    dispatch(storeReset());
    sessionStorage.clear();
    cookie.remove('Authorization', {path: '/'});
    return Promise.resolve();
  };
};

const storeReset = () => {
  return {
    type: actionTypes.STORE_RESET,
  };
};

const loggingAction = () => {
  return {
    type: actionTypes.AUTH_LOGGING,
  };
};

const loginSuccessAction = (token) => {
  return {
    type: actionTypes.AUTH_LOGIN_SUCCESS,
    data: token,
  };
};

const loginFailureAction = () => {
  return {
    type: actionTypes.AUTH_LOGIN_FAILURE,
  };
};

const logoutAction = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};
