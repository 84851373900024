import { getLocProp } from './localization';


export const format = (text, ...args) => {
    var s = text,
        i = args.length;

    while (i--) {
        s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), args[i]);
    }
    return s;
}

export const formatText = (text, key, value) => {
    return format(text.replace("@" + key, "{0}"), value);
}

export const formatTeacherQuestionText = (text, teacher) => {
    return formatText(text, "teacherName", teacher.name);
}


export const formatTeacherQuestion = (question, teacher) => {
    const result = {
        textName: formatTeacherQuestionText(getLocProp(question, "textName"), teacher),
        textHelp: formatTeacherQuestionText(getLocProp(question, "textHelp"), teacher),
        textPlaceholder: formatTeacherQuestionText(getLocProp(question, "textPlaceholder"), teacher),
        textPlaceholderBad: formatTeacherQuestionText(getLocProp(question, "textPlaceholderBad"), teacher),
        textDescription: formatTeacherQuestionText(getLocProp(question, "textDescription"), teacher),
        textDescriptionBad: formatTeacherQuestionText(getLocProp(question, "textDescriptionBad"), teacher),
        textQuestion: formatTeacherQuestionText(getLocProp(question, "textQuestion"), teacher),
        textQuestionBad: formatTeacherQuestionText(getLocProp(question, "textQuestionBad"), teacher)
    };
    return Object.assign({}, question, result);
}

export const formatSubjectQuestion = (question, subject) => {
    const result = {
        textName: getLocProp(question, "textName"),
        textHelp: getLocProp(question, "textHelp"),
        textPlaceholder: getLocProp(question, "textPlaceholder"),
        textPlaceholderBad: getLocProp(question, "textPlaceholderBad"),
        textDescription: getLocProp(question, "textDescription"),
        textDescriptionBad: getLocProp(question, "textDescriptionBad"),
        textQuestion: getLocProp(question, "textQuestion"),
        textQuestionBad: getLocProp(question, "textQuestionBad")
    };
    return Object.assign({}, question, result);
} 

export const formatGeneralQuestion = question => {
    const result = {
        textName: getLocProp(question, "textName"),
        textHelp: getLocProp(question, "textHelp"),
        textPlaceholder: getLocProp(question, "textPlaceholder"),
        textPlaceholderBad: getLocProp(question, "textPlaceholderBad"),
        textDescription: getLocProp(question, "textDescription"),
        textDescriptionBad: getLocProp(question, "textDescriptionBad"),
        textQuestion: getLocProp(question, "textQuestion"),
        textQuestionBad: getLocProp(question, "textQuestionBad")
    };
    return Object.assign({}, question, result);
}

export const formatSurvey = survey => {
    const result = {
        name: getLocProp(survey, "shortName"),
        longName: getLocProp(survey, "longName")
    };
    return Object.assign({}, survey, result);
}

export const formatSubject = subject => {
    const res = {
        name: getLocProp(subject, "name")
    };
    return Object.assign({}, subject, res);
}

export const formatTextOption = option => {
    const res = {
        name: getLocProp(option, "name"),
        helpText: getLocProp(option, "helpText")
    };
    return Object.assign({}, option, res);
}

export default format;