import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import localization from '../lib/localization';
import LocaleSwitcher from './LocaleSwitcher';
import Login from './Login';
import {Collapse, Navbar, NavbarToggler, NavbarBrand, Nav} from 'reactstrap';
import config from '../config';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTools} from '@fortawesome/free-solid-svg-icons';
import {ReactComponent as Logo} from '../Assets/cvut.svg';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    return (
      <div>
        <Navbar dark expand='xs' className='bg-cvut'>
          <NavbarBrand
            tag={() => (
              <Link className='navbar-brand' to='/'>
                <Logo
                  className='ml-2 mr-2'
                  style={{
                    height: '35px',
                    width: '35px',
                    paddingBottom: '3px',
                  }}
                />
                {localization('title')}

                {config.environmentType === 'stg' ? (
                  <FontAwesomeIcon icon={faTools} className='mr-2' />
                ) : (
                  ''
                )}
              </Link>
            )}
          />
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className='ml-auto' navbar>
              <Login />
              <LocaleSwitcher />
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Navigation;
