import React, {Component} from 'react';
import {Card, CardBody, CardHeader} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faListOl} from '@fortawesome/free-solid-svg-icons';
import localization from '../../lib/localization';

class CourseTableOfContents extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Card
        // style={{
        //   position: 'fixed',
        //   left: '0',
        //   top: '0',
        //   marginTop: '80px',
        //   marginLeft: '30px',
        //   zIndex: '1000',
        // }}
        >
          <CardHeader>
            <FontAwesomeIcon icon={faListOl} className='mr-2' size='lg' />
            {localization('toc')}
          </CardHeader>
          <CardBody>
            <ul>
              <li>
                <a href={`#statistics`}>{localization('vis_statistics')}</a>
              </li>
              <li>
                <a href={`#progress`}>
                  {localization('history_chart_evolution')}
                </a>
              </li>
              <li>
                <a href={`#teachers`}>{localization('teachers')}</a>
              </li>
              <li>
                <a href={`#questions`}>
                  {localization('course_detail_questions')}
                </a>
                <ol>
                  {this.props.questions
                    ? this.props.questions.map((q, i) => (
                        <li key={`toc-question-${i}`}>
                          <a href={`#question-${i}`}>
                            {localization('locale') === 'cz'
                              ? q.questionCz
                              : q.questionEn}
                          </a>
                        </li>
                      ))
                    : ''}
                </ol>
              </li>
            </ul>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default CourseTableOfContents;
