import * as actionTypes from '../Actions/actionTypes';
import axios from 'axios';
import { localeKeys, LOCALIZEDPROPERTIES, localize } from '../lib/localization';
import { dangerAction } from '../Actions/alertActions';

export const getOptions = dispatch => () => {
    dispatch({
        type: actionTypes.TEXT_OPTIONS_LOADING
    });
    return axios.get("/publicOptions").then(res => {
        let converted = res.data.data.map(mapTextOptions);
        dispatch({
            type: actionTypes.TEXT_OPTIONS_LOADED,
            data: converted
        });
    }).catch(err => {
            if(err.response.status === 401){
                dispatch(dangerAction(localize("alerts_not_authenticated")))
            }
            else {
                dispatch(dangerAction(localize("alerts_connection_failure")));
            }
    });
}

const mapTextOptions = data => {
    let res = {
        id: data.id.toString()
    }

    res[LOCALIZEDPROPERTIES] = {};

    res[LOCALIZEDPROPERTIES][localeKeys.cz] = {
        name: data.nameCz,
        helpText: data.helpTextCz
    };

    res[LOCALIZEDPROPERTIES][localeKeys.en] = {
        name: data.nameEn,
        helpText: data.helpTextEn
    };

    return res;
}