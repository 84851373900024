import React, {Component} from 'react';
import axios from 'axios';
import SemestersPage from './SemestersPage';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';
import localization from '../../lib/localization';
import {danger} from '../../Actions/alertActions';
// import {getHttpError} from '../../Assets/utils'
class Semesters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      semesters: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.title = `${localization('semesters_page')} | ${localization(
      'title'
    )}`;

    // this.props.dispatch({type: 'CACHE_SEMESTERS'});

    axios
      .get('results/semesters')
      .then((r) => {
        this.setState({semesters: r.data.data, isLoading: false});
      })
      .catch((e) => {
        this.props.danger(localization('alerts_connection_failure'));
      });
  }

  render() {
    return <SemestersPage {...this.state} columns={this.columns} />;
  }
}

const mapStateToProps = (state) => {
  return {
    abc: state.semesterCache.efg,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    danger: danger(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Semesters);
