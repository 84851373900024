import React, {Component} from 'react';
import TeacherDetailPage from '../TeacherDetailPage';
import localization from '../../../lib/localization';
import axios from 'axios';
import {connect} from 'react-redux';
import {danger} from '../../../Actions/alertActions';

class TeacherDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teacherName: '',
      teacher: null,
      isLoading: true,
    };
  }

  loadTeacherData = () => {
    axios
      .get(
        `results/semesters/${this.props.match.params['semester']}/surveys/${
          this.props.match.params['survey']
        }/teachers/${this.props.match.params['teacher'].toUpperCase()}`
      )
      .then((r) => {
        this.setState(
          {
            teacher: r.data.data,
            isLoading: false,
            teacherName: r.data.data.fullName,
            surveyNameCz: r.data.data.surveyNameCz,
            surveyNameEn: r.data.data.surveyNameEn,
            reaction: r.data.data.reaction,
            isTeacher: r.data.data.isMe,
          },
          () => {
            document.title = `${localization('bread_teacher')} ${
              this.state.teacherName
            } - ${
              localization('locale') === 'cz'
                ? this.state.surveyNameCz
                : this.state.surveyNameEn
            } | ${localization('title')}`;
          }
        );
      })
      .catch((e) => {
        this.props.danger(localization('alerts_connection_failure'));
      });
  };

  onDelete = (questionId, commentId, answerId, teacherId) => {
    var comment = this.state.teacher.courses[questionId].answers[
      answerId
    ].reactions.find(
      (a) => a.userName.toUpperCase() === this.state.teacher.userName
    );

    var index = this.state.teacher.courses[questionId].answers[
      answerId
    ].reactions.indexOf(comment);

    var filtered = this.state.teacher.courses[questionId].answers[
      answerId
    ].reactions.filter(function(value, indexIn, arr) {
      return indexIn !== index;
    });

    axios
      .delete('reactions', {
        data: {
          evaluationAnswerKey: commentId,
          idTeacher: teacherId,
          semesterCode: this.props.match.params['semester'],
          surveyKey: Number(this.props.match.params['survey']),
        },
      })
      .catch((e) => {
        this.props.danger(localization('alerts_connection_failure'));
      });

    this.state.teacher.courses[questionId].answers[
      answerId
    ].reactions = filtered;

    this.forceUpdate();
  };

  addSemesterReaction = (a, b, c) => {
    this.setState({
      reaction: c,
    });

    if (c)
      axios
        .put('/reactions/semester', {
          idTeacher: this.state.teacher.id,
          text: c,
          semesterCode: this.props.match.params['semester'],
          surveyKey: this.props.match.params['survey'],
        })
        .catch((e) => {
          this.props.danger(localization('alerts_connection_failure'));
        });
    else {
      axios
        .delete('/reactions/semester', {
          data: {
            idTeacher: this.state.teacher.id,
            semesterCode: this.props.match.params['semester'],
            surveyKey: this.props.match.params['survey'],
          },
        })
        .catch((e) => {
          this.props.danger(localization('alerts_connection_failure'));
        });
    }
  };

  deleteSemesterReaction = (a, b, c) => {
    this.setState({
      reaction: c,
    });
    axios
      .delete('/reactions/semester', {
        data: {
          idTeacher: this.state.teacher.id,
          semesterCode: this.props.match.params['semester'],
          surveyKey: this.props.match.params['survey'],
        },
      })
      .catch((e) => {
        this.props.danger(localization('alerts_connection_failure'));
      });
  };

  saveReaction = (questionId, commentId, markdown) => {
    var comment = this.state.teacher.courses[questionId].answers.find(
      (a) => a.courseEvaluationAnswerKey === commentId
    );

    comment.reactions = [
      {
        fullName: this.state.teacher.fullName,
        id: this.state.teacher.id,
        userName: this.state.teacher.userName,
        text: markdown,
      },
    ];

    if (markdown)
      axios
        .put('reactions', {
          evaluationAnswerKey: commentId,
          idTeacher: this.props.match.params['teacher'],
          semesterCode: this.props.match.params['semester'],
          surveyKey: Number(this.props.match.params['survey']),
          text: markdown,
        })
        .catch((e) => {
          this.props.danger(localization('alerts_connection_failure'));
        });
    else
      axios
        .delete('reactions', {
          evaluationAnswerKey: commentId,
          idTeacher: this.props.match.params['teacher'],
          semesterCode: this.props.match.params['semester'],
          surveyKey: Number(this.props.match.params['survey']),
        })
        .catch((e) => {
          this.props.danger(localization('alerts_connection_failure'));
        });

    this.forceUpdate();
  }; // NEFUNGUJE, POTREBA PREPSAT CELY KOMPONENT

  componentDidMount() {
    // https://gitlab.fit.cvut.cz/stechvo1/anketa-backend/tree/dev/docs/api/results/semesters/semesterCode/surveys/surveyId/teachers/teacherName
    document.title = `${localization('bread_teacher')} ${
      this.state.teacherName
    } ${localization('bread_in_semester')} ${
      this.props.match.params['semester']
    } | ${localization('title')}`;

    this.loadTeacherData();
  }

  createSeries = () => {
    var toReturn = [];

    if (!this.state.teacher) return toReturn;

    this.state.teacher.courses.map((c) => {
      var chartEntity = {
        name: c.code,
        data: c.rating,
        average: c.averageRating,
        fullName: c.name,
      };
      toReturn.push(chartEntity);
    });
    return toReturn;
  };

  render() {
    return (
      <TeacherDetailPage
        onAddReaction={this.saveReaction}
        surveyName={
          localization('locale') === 'cz'
            ? this.state.surveyNameCz
            : this.state.surveyNameEn
        }
        isLoading={this.state.isLoading}
        teacher={this.state.teacher}
        semesterCode={this.props.match.params['semester']}
        surveyId={this.props.match.params['survey']}
        onDelete={this.onDelete}
        onDeleteSemesterReaction={this.deleteSemesterReaction}
        userName={this.props.user.userName}
        isTeacher={this.state.isTeacher}
        reaction={this.state.reaction}
        onAddSemesterReaction={this.addSemesterReaction}
        series={this.createSeries()}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    danger: danger(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeacherDetail);
