const locale_CZ = {
  locale: 'cz',
  language_name: 'Česky',
  title: 'Anketa',
  user_logout_button: 'Odhlásit se',
  user_login_button: 'Přihlásit se',
  user_login_sso: 'Přihlášení',
  error_404_heading: 'Stránka nenalezena',
  error_404_button: 'domů',
  footer_copyright: '2019 Anketa ČVUT',
  footer_helpLink: 'Jak to funguje?',
  footer_contact_text: 'Pomozte Anketu zlepšit!',
  footer_contact_link: 'Kontaktujte vývojáře.',
  sideNav_mainPage: 'Nástěnka',
  sideNav_homepage: 'Domovská stránka',
  sideNav_results: 'Výsledky',
  surveys_subject_allSubjectsRated: 'Všechny předměty ohodnoceny',
  surveys_noActiveSurvey: 'Žádná anketa k dispozici',
  surveys_subject_group_heading: 'Anketní lístky předmětů',
  surveys_general_group_heading: 'Další anketní lístky',
  alerts_survey_send_success: 'Odpovědi úspěšně odeslány',
  alerts_connection_failure: 'Problém s připojením k síti!',
  alerts_not_authenticated: 'Pro uživatele nejsou data.',
  surveys_teachers_dropdown_noItems: 'Nejsou dostupní žádní vyučující',
  surveys_teachers_dropdown_text: 'Přidat hodnocení vyučujícího',
  surveys_answer_anonymity_warnign:
    'Nalezen pouze {0} student v této kategorii', // number of students in combination
  surveys_answer_textField_teacher_toAdd_text: 'Přidat komentář: {0}', // teacher name
  surveys_answer_textField_question_toAdd_text: 'Přidat komentář: {0}', // question text name
  surveys_answer_textField_toggles_heading: 'Zveřejnit:',
  surveys_answer_textField_toggles_name: 'Mé jméno',
  surveys_answer_textField_toggles_department: 'Můj obor studia',
  surveys_answer_textField_toggles_year: 'Můj ročník studia',
  surveys_answer_textField_toggles_marks: 'Můj studijní průměr',
  surveys_answer_textField_toggles_role: 'Roli předmětu v mém stud. plánu',
  surveys_subject_title: 'Hodnotíte',
  surveys_buttons_submit: 'Uložit a odeslat',
  surveys_buttons_delete: 'Zahodit vyplněná data',
  surveys_modal_warning:
    'Po odeslání hodnocení se již nebudete moci vrátit zpět. Opravdu si přejete hodnocení odeslat?',
  subject_buttons_save: 'Uložit data do mezipaměti',
  markdown_editor: {
    write: 'Psaní',
    preview: 'Náhled',
  },
  chars_remaining_1: 'Zbývá ',
  chars_remaining_2: ' znaků.',
  chars_remaining_help: 'Nápověda',
  chars_remaining_markdown_tutorial:
    'Podporovány jsou pouze markdown prvky, které poskytuje editor. Odkazy mimo doménu .cvut.cz budou odstraněny.',
  landing_page_markdown: `
## Aktuality:


## Studenti i vyučující

- **[Pokračujte, prosím, pomocí SSO přihlášení](${process.env.REACT_APP_URL_SUBDIR}/login)**

## [Informace o anketách](https://anketa.cvut.cz/reports/)

- Plán otevření a uzavření anket pro fakulty
- Anketní otázky
- Předměty v anketách
- Vyučující v předmětech
- Průběžná vyplněnost
`,
  homepage_markdown: `
## Aktuality:

## Studenti

- **Nástěnka** - zde najdete všechny lísky k vyplnění
- ankety fakult se **neotevírají stejně**
- pokud vám nějaký lístek chybí
  - podívejte se na plán otevření anket pro fakulty (viz níže)
  - nebo je vaše anketa otevřena v režimu "pro ukončené" a vy dosud nemáte předmět uzavřený
- vyplněné a odeslané anketní listky se vám již nenabízí


## [Informace o anketách](https://anketa.cvut.cz/reports/)

- Plán otevření a uzavření anket pro fakulty
- Anketní otázky
- Předměty v anketách
- Vyučující v předmětech
- Průběžná vyplněnost

 `,
  landing_title: 'Anketa ČVUT',
  remains: 'zbývají',
  semesters_help_markdown: `# Nové výsledkové rozhraní
Vítejte v novém rozhraní pro prohlížení výsledků ankety ČVUT. Pokud si s novým rozhraním nebudete vědět rady, v pravém horním rohu vždy naleznete **tuto nápovědu**. Pokud Vám přesto něco nebude jasné, nebo budete mít nějakou připomínku, prosíme, [kontaktujte nás](mailto:valenta@fit.cvut.cz?subject=[nova-anketa]).
## Drobečková navigace
Při prohlížení výsledků máte k dispozici přímý návrat do vyšších úrovních aplikace pomocí **drobečkové navigace** výše.
## Volba semestru
V následující tabulce můžete vidět seznam anket, ke kterým máme k dispozici výsledky k zobrazení.

**Pokračujte, prosím, kliknutím na nějakou z anket.**
 `,
  courses_help_markdown: `# Seznam předmětů a učitelů
Na aktuální stránce můžete vidět rozcestník jednotlivých výsledkových stránek. Obsahuje všechny informace, které máme pro Vaše uživatelské jméno, tedy zde uvidíte všechny výsledky, které jsou pro Vás relevantní.


## Filtrace
Do nového rozhraní jsme se snažili přidat pokročilé možnosti filtrování předmětů a učitelů.

Mezi seznamy můžete přepínat pomocí **záložek**.

Pomocí **rozbalovacích nabídek** můžete vybrat pouze předměty a učitele z určitých kateder (učitele pak také podle předmětů, ve kterých je angažován).

**Zaškrtávací políčka** Vám umožňují ukázat pouze předměty, které jste hodnotili, nebo které jste měli zapsány v semestru, který si nyní prohlížíte.
## Tabulka
V tabulce lze vyhledávat pomocí **okének nad sloupci**. Také je možné vzestupně i sestupně řadit, za přidržení \`shift\` lze řadit pomocí více sloupců naráz.`,
  courses_help_header: 'Nápověda',
  courses_help_close: 'Zavřít',
  coursefilter_placeholder_faculty: 'Fakulta',
  coursefilter_placeholder_department: 'Katedra',
  coursefilter_placeholder_course: 'Předmět',
  coursefilter_no_options: 'Žádné možnosti.',
  coursefilter_only_attended: 'Pouze mnou zapsané',
  coursefilter_only_rated: 'Pouze mnou hodnocené',
  coursefilter_only_teached: 'Pouze mnou učené',
  courses_results_of_semester: 'Výsledky semestru ',
  courses_results_of_semesters: 'Výsledky anket',
  courses_tab_courses: 'Předměty',
  courses_tab_teachers: 'Učitelé',
  courses_placeholder_semester: 'Semestr',
  table_previous: 'Předchozí',
  table_next: 'Další',
  table_loading: 'Načítání...',
  table_no_data: 'Žádné řádky nenalezeny.',
  table_page: 'Stránka',
  table_of: 'z',
  table_rows: 'řádků',
  table_page_jump: 'skočit na stránku',
  table_rows_selector: 'řádků na stránku',
  table_head_grade: 'Známka',
  table_head_fullname: 'Jméno',
  table_head_code: 'Kód',
  table_head_name: 'Název',
  table_head_success: 'Průchodnost',
  table_head_filled: 'Vyplněnost',
  table_head_semester: 'Semestr',
  table_head_rating_distribution: 'Rozložení',
  table_head_ratings: '# Známek',
  table_head_seen: 'Zhlédnuto',
  table_head_answered: '# Reakcí',
  table_head_received: '# Obdrženo',
  table_head_grade_tooltip:
    'Průměrná známka, kterou vyučující získal za všechny předměty, které vyučoval.',
  table_head_ratings_tooltip:
    'Počet známek, které vyučující získal ve všech předmětech, které vyučoval.',
  table_head_received_tooltip:
    'Počet  textových komentářů od studentů ze všech předmětů, které vyučoval.',
  table_head_answered_tooltip:
    'Kolik vlastních reakcí na anketu vyučující napsal. Reakce může být buď za celý předmět nebo za působení učitele v semestru nebo přímá odpověď na studentský komentář k vyučujícímu nebo k předmětu.',
  table_head_seen_tooltip:
    'Ikonka v tomto sloupečku znamená, že vyučující si studentské komentáře ke své osobě zobrazil.',
  res_bread_semesters: 'Semestry',
  course_detail_overview: 'deprecated',
  course_detail_questions: 'Otázky',
  vis_statistics: 'Statistiky',
  vis_fill_rate: 'Vyplněnost ankety',
  vis_success_rate: 'Průchodnost',
  vis_course_rating: 'Hodnocení předmětu v anketě',
  vis_grades: 'Známky z předmětu',
  tooltip_course_visualization: `Základní statistiky o běhu předmětu.

  Vyplněnost se měří podle počtu lidí, kteří odeslali anketní lístek / počet lidí, kterým byl lístek nabídnut.

  V grafu níže můžete vidět rozložení známek (smajlíků), které předmět v anketě obdržel. Každý uživatel mohl odeslat až dva smajlíky. Známka 1 = nejlepší smajlík.

  Napravo se poté nachází koláčový graf zobrazující distribuci finálních známek včetně nezískaných zápočtu.`,
  tooltip_course_history_chart: `Takto si předmět vedl v předchozích semestrech. Ne vždy jsou k dispozici data, možná tedy občas uvidíte přerušovanou křivku.`,
  tooltip_course_questions: `Odpovědi respondentů na otázky z anketních lístků. Pokud jste měl/a předmět zapsaný, máte možnost hodnotit relevanci jednotlivých komentářů pomocí palcového systému. Komentáře se podle palcového skóre budou následně řadit.

  Pro snadnější vyhledávání (ctrl + f) je k dispozici tlačítko "Zobrazit vše".`,
  tooltip_course_teachers: `Seznam učitelů, kteří se v daném předmětu angažovali.

  Tito učitelé mají navíc na této kartě k dispozici tlačítko pro vyjádření. Jejich zpráva se poté zobrazí u jejich jména.`,
  tooltip_teacher_questions: `Názory respondentů na daného učitele v daném předmětu.

  Role vyučujícího (přednášející, cvičící, zkoušející...) se v nové anketě již nerozlišují. Vyučující je v rámci předmětu hodnocen jednou známkou (smajlíkem) a  volitelně jedním komentářem.


  Pro snadnější vyhledávání (ctrl + f) je k dispozici tlačítko "Zobrazit vše".`,
  tooltip_teacher_overview:
    'Učitel má možnost sepsat své vyjádření na uplynulý semestr. Zde ho poté naleznete.',
  tooltip_teacher_statistics: `Spojení získaných hodnocení (smajlíků) napříč všemi vyučovanými předměty. Známka 1 = nejlepší smajlík.


Role vyučujícího (přednášející, cvičící, zkoušející...) se v nové anketě již nerozlišují. Vyučující je v rámci předmětu hodnocen jednou známkou (smajlíkem) a  volitelně jedním komentářem.`,
  answer_name: 'Jméno',
  answer_grade: 'Známka',
  answer_year: 'Ročník',
  answer_branch: 'Program',
  answer_average: 'Studijní průměr',
  history_chart_rating: 'Hodnocení',
  history_chart_success_rate: 'Průchodnost',
  history_chart_evolution: 'Vývoj',

  version: 'verze',
  bread_home: 'Domů',
  bread_results: 'Výsledky',
  bread_survey: 'Anketa',
  bread_course: 'Předmět',
  bread_in_semester: 'v semestru',
  bread_teacher: 'Učitel',
  answers: 'textových odpovědí',
  toc: 'Obsah',
  show_all: 'Zobrazit vše',
  answer: 'odpovědět',
  edit: 'změnit odpověď',
  delete: 'odstranit',
  global_react: 'Vyjádřit se',
  global_change: 'Změnit',
  global_delete: 'Odstranit',
  teachers: 'Učitelé',
  send: 'Odeslat',
  semesters_page: 'Výsledky',
  login: 'Přihlášení',
  survey_login: 'Přihlášení',
  username: 'Username',
  email: 'ID',
  password: 'Heslo',
  log_in: 'Přihlásit se',
  log_in_sso: 'Přihlásit se přes SSO',
  log_in_test: 'Testovací login',
  semester: 'Semestr',
  rating: 'Známka',
  no_teachers_to_show: 'Žádní učitelé k zobrazení.',
  more_comments: 'Zobrazit více',
  about_markdown: `# O Aplikaci
Současná verze Ankety je vyvíjena s podporou IP 2019 (Interní projekty ČVUT). Dle plánu implementace a nasazení by měla být v plném provozu v roce 2020.

Aplikace Anketa ČVUT pracuje podle [metodického pokynu č. 3/2022 Anketa ČVUT: dokumentace a metodika provozu systému](https://www.cvut.cz/sites/default/files/content/d1dc93cd-5894-4521-b799-c7e715d3c59e/cs/20221007-metodicky-pokyn-c-32022.pdf).

Aktuálně jsme ve fázi postupné implementace a pilotního testování jednotlivých částí (modulů).

* modul pro vylňování anketních lístků (má za sebou 2. pilotní běh)
* modul pro nahlížení výsledků a komentování výsledků vyučujícími (1. pilotní běh zprovozněn v září 2019)
* modul pro fakultní administrátory bude zprovozněn začátkem roku 2020


Oproti předchozím verzím si tato verze klade následující cíle:

* přitažení "mlčící většiny" - zvýšení vyplňenosti anket na všech fakultách
* moderní uživatelské rozhraní použitelné i z mobilních zařízení
* centrální přístup k vyplňování anketních lístků i náhledu výsledků
* flexibilnější možnosti reakcí vyučujících a vedení
* zobrazení historických trendů
* lepší podpora pro analýzu výsledků na úrovni kateder či ústavů
* lepší podpora pro další zpracování výsledků ankety

## Požadavky na spuštění

Aplikace běží ve webovém prohlížeči na straně klienta. Je tedy třeba mít povolené spouštění javascriptu. Pro svůj provoz také využívá [sessionStorage](https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage) a [cookies](https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies). Pokud nebude některá z těchto funkčností dostupná, **aplikace nemusí fungovat správně** (nebo vůbec).

Tyto techniky používá dnes mnoho moderních webových aplikací. V implicitním nastavení prohlížečů bývají zapnuté.

Pro vylepšení uživatelského zážitku využíváme analytické nástroje [Google Analytics](https://analytics.google.com/analytics/web/) a nástroj pro hlášení chyb [Sentry](https://sentry.io).


## Historie verzí

### 3.0.0

Vychází z uživatelských průzkumů, analýzy a návrhu provedeném v rámci bakálářské a následně magisterské práce Davida Knapa (bakalářská práce FIT, magisterská FEL).

Návrh celkové architektury aplikace a prvotní implementace modulu pro vyplňování anketních lístků byly provedeny v diplomové práci Vojtěcha Štechy (FIT).

Architektura systému i databáze jsou navrženy od začátku (úplně znovu).

#### Vývojový tým verze 3

* Michal Valenta
* Jakub Jun
* Nguyen Duc Thang
* Šimon Valenta
* Kristýna Sanická

podíleli se v rámci závěrečných prací:

* David Knap
* Vojtěch Štecha

### 2.0.0

Byla implementována v technologii Java. Zpočátku využívala JSP, později další frameworky, které z této technologie vycházely.

Prezentace výsledků zůstala v původní technologii PHP. Výsledkem vyhodnocení byla sada statických stránek, které byly dodány na příslušnou fakultu.

Zpočátku se jednalo o přepis původní ankety (verze 1) do nové technologie. Vývoj byl realizován formou bakalářských a magisterských prací na FEL.

S přibývajícími požadavky na změny rozhraní pro vyplňování anketních lístků, ale i celou logiku zpracování výsledků a jejich prazentací se systém stával čím dál hůže udržitelným.

V roce 2018 byl vývoj této verze zmrazen a započalo se s vývojem verze 3.

### 1.0.0

Vznikla jako pilotní projekt pro potřeby FEL. Byla napsána v technlogii PHP. Od začátku stavěla na datech z KOSu. Přibližně v roce 2005 byla aplikace zprovozněna pro všechny fakulty ČVUT.

## Kontakty
- [Michal Valenta](mailto:valenta@fit.cvut.cz)
## Použité technologie
`,
  about_app: 'O Aplikaci',
  feedback_translations: {
    close: 'zavřít',
    'error.unexpected': 'Neočekávaná chyba!',
    'header.title': 'Zpětná Vazba',
    'image.remove': 'Odebrat',
    'label.message': 'Zpráva',
    'label.type': 'Typ',
    placeholder: 'Vaše zpráva...',
    'submit.sending': 'Odesílám...',
    'submit.sent': 'Odesláno! Děkujeme.',
    'submit.text': 'Odeslat',
    'upload.text': 'Přiložit obrázek',
    'trigger.text': 'Pomozte Anketu zlepšit!',
  },
  feedback_bug: 'Chyba',
  feedback_improvement: 'Vylepšení',
  feedback_opinion: 'Názor',
  back_home: 'zpět na domovskou stránku',
  data_loss_markdown: `# Částečná ztráta dat

### Objevila se Vám možnost znovu vyplnit anketní lístek, který jste již vyplnili a odeslali?

Můžete zvolit jeden ze dvou níže uvedených scénárů:

#### scénář 1: Anketní lístek ignorovat

Vaše hodnocení předmětu je započítáno, pouze došlo ke ztrátě hodnocení vyučujícího v tomto předmětu. Anketní lístek je neúplný, ale počítá se.

#### scénář 2: Doplňkový anketní lístek

V novém (doplňkovém) anketním listku doplníte jenom to, co bylo ztraceno.

- Vyplňte tedy pouze část týkající se učitele. Zbylé části anketního lístku (tj. otázky na obsah předmětu a zakončení předmětu) již nevyplňujte, ty jsou již obsaženy ve vašem předchozím (neúplném) anketním lístku a do celkových výsledků se dostanou.

- Pokud jste zadali textový komentář k vyučujícímu a nechcete jej znovu vymýšlet, máte k dispozici [stránku ztracených komentářů](https://anketa.cvut.cz/nova/ztracene_komentare.html), kde jej můžete dohledat a zkopírovat.

- Jediná duplicita, která takto vznikne, bude ve "smajlíkovém" vyhodnocení otázky "Celkový dojem". Předpokládáme, že odpovíte stejným smajlíkem a my budeme schopni při vyhodnocení ankety tuto duplicitu částečně eliminovat.

- U 189 anketních lístků došlo k situaci, že identitu jednoho z hodnocených vyučujících jsme odhalili a doplnili (vyučující byl explicitně jmenován v textovém komentáři), u dalšího vyučujícího na tomtéž anketním lístku jsem ovšem již neuspěli. Pokud tedy "svůj" textový komentář k vyučujícímu vidíte ve výsledcích (a nevidíte jej na výše uvedené stránce ztracených textových komentářů), pak tohoto vyučujícího již znovu nehodnotťe. Ohodnťte pouze ty zbývající, jejichž hodnocení jsme ztratili.

## Vysvětlení

Během června při druhém testovacím běhu ankety došlo k chybě při nasazení nové verze systému. Chyba spočívala v tom, že u anketních lístků  se neukládaly odkazy na vyučující.  Než byla chyba zaznamenána a opravena, bylo uloženo 8 005 neúplných anketních lístků.

Dopad této chyby jsme se pokusili minimalizovat a cca 20% neúplných anketních lístků se nám podařilo kompletně opravit (tj. doplnit chybějící odkazy na vyučující).

Hodnocení vyučujících sestává ze dvou částí: "smajlíku", který vyjadřuje "oznámkování" vyučujícího, a nepovinného textového komentáře.  Při opravě neúplných lístků jsme mohli využít tyto indicie:

1. Předmět má v anketě pouze jednoho vyučujícího.

2. V textovém komentáři bylo jméno vyučujícího explicitně uvedeno.

Zbylo nám 1 658 textových komentářů, ke kterým nejsme schopni vyučujícího přiřadit. Tyto komentáře jsou umístěny na [stránce ztracených komentářů](https://anketa.cvut.cz/nova/ztracene_komentare.html). Jsou setříděné podle fakult a předmětů, ke kterým patří, abyste je mohli snadno dohledat a případně znovu použít.

Rádi bychom ještě více zmírnili ztrátu dat. Při tom se ovšem již neobejdeme bez pomoci těch, kteří anketu vyplnili.

## Opatření k dalšímu zmírnění ztraty dat

U neúplných anketních lístků jsem se pokusili odhadnout studenta, který je vyplnil. Nelze to udělat jednoznačně, protože anketa je anonymní a asociace mezi anketním lístkem a studentem, který ho vyplnil, se neukládá (výjimkou je situace, kdy student anketní lístek "podepíše").

U takto vytipovaných studentů jsme smazali informaci o tom, že student anketní lístek z příslušného předmětu již vyplnil. V rozhraní pro vyplnění anketních lístků se mu objeví možnost vyplnit jej znovu.

Pokud u vás tato situace nastala, tedy v rozhraní pro vyplnění anketních lístků se vám některý předmět nabízí k hodnocení podruhé, můžete si zvolit ze dvou scénářů uvedených na začátku této stránky.

## Omluva

Za způsobenou chybu se velmi omlouváme. Podnikáme kroky, aby se již neopakovala.

Věříme, že nové rozhraní pro vyplňování anketních lístků a zejména pro náhled výsledků anket, kterému věnujeme velké úsilí, bude pro vás určitou satisfakcí za tuto naši chybu, a hlavně, že vás nepřipraví o motivaci anketu vyplňit a zpětnou vazbu vyučujícím a fakultám poskytnout.

Michal Valenta (právce Anekty ČVUT)
`,
  overall_statistics: 'Celkové statistiky',
  average_obtained_rating: 'Průměrné získané hodnocení',
  teacher_has_not_reacted: 'Učitel se zatím nevyjádřil.',
  semester_reaction: 'Vyjádření učitele k uplynulému semestru',
  data_loss: 'Částečná ztrata dat - vysvětlení',
  non_course: 'Nepředmětová',
  number: 'Celkem',
  my_teacher_page: 'Moje stránka učitele',
  changelog: 'Changelog',
  changelog_markdown: `# Changelog
## 3.0.5
- Přidány nepředmětové ankety
- Přidána možnost přihlášení bez SSO

## 3.0.4
- Opravy malých chyb
- Nové sloupečky v seznamu učitelů
- Katedry jsou v rozbalovacím menu řazeny podle ID
- Tabulky si pamatuji stav po stisknutí "zpět"

## 3.0.3
- Opravy malých chyb
- Přidána čísla kateder do rozbalovacího menu
- Řazení podle hodnocení nyní nechává neohodnocené předměty a učitele na konci

## 3.0.2
- Přidán tento changelog
- Přidáno číslování řádků v tabulkách předmětů a učitelů
- Přidán sloupeček s vyplněností v tabulce předmětů
- Plynulejší přechody barev ve sloupci průchodnosti
- Malá grafická úprava celkových učitelských komentářů na stránce předmětu
- Vylepšen graf získaných hodnocení na stránce učitele
- Přidány hodnoty do grafu získaných hodnocení na stránce předětu
- Graf historie na stránce předmětu nyní zahrnuje i aktuální semestr
- Na stránce učitele můžete vidět u komentářů ikonu oka, která symbolizuje, že byl komentář učitelem spatřen. Po najetí myší uvidíte kdy.

## 3.0.1
- Zvětšení tlačítka pro zobrazení více komentářů
- Odstranění "ČVUT" z hlavičky
- Doplnění textů do tooltipů
- Dočasné odstranění nápověd
- Opravena chyba s odesíláním feedbacku typu "vylepšení"
- Opravena konfigurace webserveru, při které nešlo sdílet odkazy
- Přidána možnost zobrazení až 1000 řádků s předměty / učiteli,`,
  censored:
    'Tento komentář byl rozhodnutím příslušné fakulty skryt, nebo nebyl schválen vyučujícím ke zveřejnění.',
  seen: 'Zobrazeno učitelem ',
  ratings: 'známek',
  teacher_has_seen_some_comments: 'Učitel viděl některé komentáře.',
  filter: 'Filtr',
  
  tooltip_LDAP_login: 'Použije student, který má již ukončené studium (typicky po státnicích) nebo externí vyučující, který aktuálně nemá platné DPP (Dohodu o Provedení Práce).'
};

export default locale_CZ;
