import React, { Component } from 'react';
import TicketControls from './TicketControls';
import TextRatings from './TextRatings';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../Actions/userActions';
import TeacherAdder from './TeacherAdder';
import QuestionsValue from './QuestionsValue';
import localization from '../lib/localization';
import { formatTeacherQuestion, formatSubject, formatSurvey, formatGeneralQuestion, formatSubjectQuestion, formatTextOption } from '../lib/stringUtils';
import config from '../config';
import { sendSubject } from '../Api/surveyApi';
import { success } from '../Actions/alertActions';
import ReactGA from 'react-ga'

class Ticket extends Component {
    constructor(props) {
		super(props);
		const surveyId = this.props.match.params['surveyId'];
		if (!this.props.surveys.loaded) {
			this.state={noRender:true};
			return;
		}
		
		if (surveyId === null || surveyId === undefined) {
			this.state={noRender:true};
			return;
		}
		const survey = this.props.surveys.surveys.find(x => x.id === surveyId);
		if (survey === undefined) {
			this.state={noRender:true};
			return;
		}
		
		this.state = {
			open: false,
			questions: [],
			possibleProfessors: [],
			survey: formatSurvey(survey),
			options: {},
			noRender: false
		};
		if (survey !== undefined) {
			let options = {};
			survey.publicOptions.forEach(e => {
				let text = this.props.textOptions.textOptions.find(x => x.id === e.id);
				if (text !== undefined) {
					options[text.id] = Object.assign({}, formatTextOption(text), { value: e.defaultValue } );
				}
			});
			this.state.options = options;
			if (survey.type === config.courseSurveyTypeName) {
				const subjectId = this.props.match.params['subjectId'];
				if (subjectId === null || subjectId === undefined) {
					this.state={noRender:true};
					return;
				}
				if (!survey.subjectToRateIds.includes(subjectId)) {
					this.state={noRender:true};
					return;
				}
				const subject = this.props.subjects.find(x => x.id === subjectId);
				if (subject !== null && subject !== undefined) {
					
					let profQuestion;
					this.state.subject = formatSubject(subject);
					subject.questionIds.forEach(e => {
						const tmp = this.props.questions.questions.find(x => x.id === e && x.isTeacher);
						if (tmp !== undefined && tmp !== null) {
							profQuestion = tmp;
						}
					});
					if (profQuestion !== null && profQuestion !== undefined) {
						subject.professorIds.forEach(element => {
							const prof = this.props.professors.professors.find(x => x.id === element);
							if (prof !== undefined) {
								this.state.possibleProfessors.push(Object.assign({}, formatTeacherQuestion(profQuestion, prof), { id: profQuestion.id + '-' + prof.id, questionId: profQuestion.id, professorId: prof.id, professor: prof, isProf: true }));
							}
						});
					}
					subject.questionIds.forEach(e => {
						const q = this.props.questions.questions.find(x => x.id === e);
						if (q !== undefined && (q.isValue || q.isText) && !q.isTeacher) {
							this.state.questions.push(Object.assign({}, formatSubjectQuestion(q, subject), {id: q.id, questionId: q.id, isTextOpen: false, value: null, text: null, isProf: false }));
						}
					});
					
				}
			} else if (survey.type === config.generalSurveyTypeName) {
				survey.questionToRateIds.forEach(e => {
					const q = this.props.questions.questions.find(x => x.id === e);
					if (q !== null && (q.isValue || q.isText)) {
						this.state.questions.push(Object.assign({}, formatGeneralQuestion(q), { questionId: q.id, isTextOpen: false, value: null, text: null, isProf: false }));
					}
				});
				
			}
		}
		this.state.questions.sort((a, b) => a.priority - b.priority);
	}

	componentDidMount = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
		if (!this.props.surveys.loaded) {
			this.props.history.push("/");
			return;
		}

		const surveyId = this.props.match.params['surveyId'];
		
		if (surveyId === null || surveyId === undefined) {
			this.props.history.push("/");
			return;
		}
		const survey = this.props.surveys.surveys.find(x => x.id === surveyId);
		if (survey === undefined) {
			this.props.history.push("/");
			return;
		}

		if (survey.type === config.courseSurveyTypeName) {
			const subjectId = this.props.match.params['subjectId'];
			if (subjectId === null || subjectId === undefined) {
				this.props.history.push("/");
				return;
			}
			if (!survey.subjectToRateIds.includes(subjectId)) {
				this.props.history.push("/");
				return;
			}
		}
		if (this.props.location.search) {
			let val = this.props.location.search.substr(3);
			this.setState(prev => {
				const newArray = [...prev.questions];
				newArray[0].value = parseInt(val);
				return Object.assign({}, prev, {questions: newArray}, {open: true});
			})
		}
	}

	copyState = state => {
		const newState = Object.assign({}, { open: state.open}, { questions: [...state.questions]}, { professors: [...state.professors]}, { possibleProfessors: [...state.possibleProfessors]});
		return newState;
	}

    open = () => {
        this.setState(() => {
            return {open: true};
          });
	}
	
	handleClickRating = id => {
		return value => {
			return event => {
				if (!this.state.open) {
					this.open();
				}
				const q = this.state.questions.find(x => x.id === id);
				if (q !== null) {
					this.setState(prevState => { 
						let change = {};
						change.value = value
						if (value >= 4) {
							change.isTextOpen = true;
						}
						return {
							questions: [...prevState.questions.filter(x => x.id !== id), Object.assign({}, q, change)]
						}; 
					});
				}		
			}
		}
	}

	handleTextareaQuestion = id =>  {
		const q = this.state.questions.find(x => x.id === id);
		return event => {
			const value = event;
			if (q !== undefined) {
				this.setState(prevState => {
					const change = {};
					change.text = value;
					return {
						questions: [...prevState.questions.filter(x => x.id !== id), Object.assign({}, q, change)]
					};				
				});
			}
		}
	}

	handleOption = optionId => event => {
		const option = this.state.options[optionId];
		if (option !== undefined || option !== null) {
			let value = event.target.checked;
			this.setState(prevState => { 
				const change = Object.assign({}, prevState.options);
				change[optionId].value = value	
				return {
					options: change
			}});
		}
	}

	toggleQuestion = id => {
		return e => {
			const q = this.state.questions.find(x => x.id === id)
			if (q !== undefined) {
				q.isTextOpen = !q.isTextOpen;
				this.setState({questions: [...this.state.questions]});
			}
		}
	}

	addProfessor = id => {
		return () => {
			const prof = this.state.possibleProfessors.find(x => x.id === id);
			if (prof !== undefined) {
				this.setState(prev => {
					const state = Object.assign({}, prev);
          state.questions = [...prev.questions, Object.assign({}, prof, { isTextOpen: false, value: null, text: null, priority: new Date().getTime() })];
					return state;
				})
			}
		}
	}

	removeProfessor = id => {
		return () => {
			this.setState(prev => {
				const state = Object.assign({}, prev);
				state.questions = prev.questions.filter(x => x.id !== id);
				return state;
			})
		}
	}
	
	handleTextCloseQuestion = id => {
		const question = this.state.questions.find(x => x.id === id);
		return () => {
			if (question !== undefined) {
				question.isTextOpen = false;
				this.setState({questions: [...this.state.questions]});
			}
		}
	}

	submit = () => {
		const submit = {};
		if (this.state.subject !== null && this.state.subject !== undefined) {
			submit.courseId =  this.state.subject.id;
		}
		const options = [];
		for (var key in this.state.options) {
			options.push({
				id: this.state.options[key].id,
				value: this.state.options[key].value
			});
		}
		submit.surveyId = this.state.survey.surveyId;
		submit.semesterCode = this.state.survey.semesterCode;
		submit.lang = this.props.locale.current.key;
		submit.questions = this.state.questions.map(x => {
			return {
				id: x.questionId,
				value: x.value,
				text: x.text,
				options: options,
				teacherId: x.professorId
			}
		})
		this.props.sendSubject(submit);

		this.props.success(localization("alerts_survey_send_success"));
		if (this.state.subject !== null && this.state.subject !== undefined) {
			this.props.userActions.submitSubjectActionCreator(this.state.survey.id, this.state.subject.id);
			if (this.state.survey.subjectToRateIds.length === 1) {
				this.props.history.push("/overview");	
			} else {
				this.props.history.push("/surveys/" + this.state.survey.id);
			}
		}
		else {
			this.props.userActions.submitSurveyActionCreator(this.state.survey.id);
			this.props.history.push("/overview");
		}
	}

	shouldSubmitBeDisabled = () => {
		return this.state.questions.filter(x => x.isMandatory).some(x => {
			return (!x.isValue || !x.value) && (!x.isText || !x.text);
		})
	}

	reset = () => {
		this.setState(prevState => {
			const state = this.copyState(prevState);
			const defaultOptions = {};
			this.state.survey.publicOptions.forEach(e => {
				defaultOptions[e.id] = e.defaultValue;
			});
			state.questions.forEach(x => {
				x.value = null;
				x.isTextOpen = false;
				x.text = null;
				for(var key in x.options) {
					x.options[key].value = defaultOptions[key]
				}
			});
			state.professors = [];
			state.open = false;
			return state;
		});
	}


    render() {
		if (this.state.noRender) {
			return null;
		}
		if (this.state.survey.type === config.generalSurveyTypeName) {
			if (!this.state.open) {
				return (
					<div>
						<h2><strong>{this.state.survey.longName}</strong></h2>
						<div className="border">
							<div className="bg-light rounded px-4 pt-4 p-5 quantitative-rating">
								<QuestionsValue questions={this.state.questions}
												handleClickRating={this.handleClickRating}
												primaryOnly
												refresh={this.props.refresh}/>
							</div>
						</div>
						<TicketControls submit={this.submit}
										save={null}
										reset={this.reset}
										submitDisabled={this.shouldSubmitBeDisabled()}
										refresh={this.props.refresh}/>
					</div>
				);
			}
			else {
				return (
					<div>
						<h2><strong>{this.state.survey.longName}</strong></h2>
							<div className="border">
								<div className="bg-light rounded px-4 pt-4 p-5 quantitative-rating">
									<QuestionsValue questions={this.state.questions}
													handleClickRating={this.handleClickRating}
													refresh={this.props.refresh}/>
								</div>
								<TextRatings questions={this.state.questions}
											toggleQuestion={this.toggleQuestion}
											handleTextareaQuestion={this.handleTextareaQuestion}
											handleTextCloseQuestion={this.handleTextCloseQuestion}
											refresh={this.props.refresh}
											survey={this.state.survey}
											handleOption={this.handleOption}
											options={this.state.options}/>
							</div>
							<TicketControls submit={this.submit}
										save={null}
										reset={this.reset}
										submitDisabled={this.shouldSubmitBeDisabled()}
										refresh={this.props.refresh}/>
					</div>
				);
			}
		}
		const item = this.state.subject;
		if (item === null || item === undefined) {
			this.props.history.push("/");
			return null;
		}
        if (!this.state.open) {
            return (
                <div>
                    <h2>{localization("surveys_subject_title")} <strong>{item.code + " " + item.name}</strong></h2>
					<div className="border">
						<div className="bg-light rounded px-4 pt-4 p-5 quantitative-rating">
							<QuestionsValue questions={this.state.questions}
											handleClickRating={this.handleClickRating}
											primaryOnly
											refresh={this.props.refresh}/>
						</div>
					</div>
					<TicketControls submit={this.submit}
									save={null}
									reset={this.reset}
									submitDisabled={this.shouldSubmitBeDisabled()}
									refresh={this.props.refresh}/>
                </div>
            );
        }
        else {
            return (
                <div>
                    <h2>{localization("surveys_subject_title")} <strong>{item.code + " " + item.name}</strong></h2>
						<div className="border">
							<div className="bg-light rounded px-4 pt-4 p-5 quantitative-rating">
								<QuestionsValue questions={this.state.questions}
												handleClickRating={this.handleClickRating}
												refresh={this.props.refresh}/>
								<TeacherAdder questions={this.state.questions} 
											possibleProfessors={this.state.possibleProfessors} 
											handleValueClick={this.handleClickRating}
											removeProfessor={this.removeProfessor}
											handleProfessorClick={this.addProfessor}
											refresh={this.props.refresh}/>
							</div>
							<TextRatings questions={this.state.questions}
										toggleQuestion={this.toggleQuestion}
										handleTextareaQuestion={this.handleTextareaQuestion}
										handleTextCloseQuestion={this.handleTextCloseQuestion}
										refresh={this.props.refresh}
										survey={this.state.survey}
										handleOption={this.handleOption}
										options={this.state.options}/>
						</div>
						<TicketControls submit={this.submit}
									save={null}
									reset={this.reset}
									submitDisabled={this.shouldSubmitBeDisabled()}
									refresh={this.props.refresh}/>
                </div>
            );
        }
        
    }
}

const  mapStateToProps = state => {
    return {
		tickets: state.ticket,
		subjects: state.subjects.subjects,
		professors: state.professors,
		questions: state.questions,
		user: state.user,
		surveys: state.surveys,
		refresh: state.refresh,
		textOptions: state.textOptions,
		locale: state.locale
    };
}

const mapDispatchToProps = dispatch => {
    return {
		userActions: bindActionCreators(userActions, dispatch),
		sendSubject: sendSubject(dispatch),
		success: success(dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Ticket);