import React, {Component} from 'react';
import ResultsBreadcrumbs from './ResultsBreadcrumbs';
import localization from '../../lib/localization';
import QuestionTab from './QuestionTab';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Row,
  Col,
  Spinner,
  Button,
  Badge,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import ScrollUpButton from 'react-scroll-up-button';
import {Card, CardBody, CardHeader} from 'reactstrap';
import {faCommentDots} from '@fortawesome/free-solid-svg-icons';
import CardTooltip from './CardTooltip';
import {faChartPie} from '@fortawesome/free-solid-svg-icons';
import Chart from 'react-apexcharts';
import axios from "axios";

class NonCoursal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const options = {
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: [
          localization('rating') + ' 1',
          localization('rating') + ' 2',
          localization('rating') + ' 3',
          localization('rating') + ' 4',
          localization('rating') + ' 5',
        ],
      },
      chart: {
        toolbar: {
          show: false,
        },
        stacked: true,
      },
    };

    const colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'];

    function getSum(total, num) {
      return total + Math.round(num);
    }

    function createSeries(series) {
      var toReturn = [];

      series.map((c) => {
        var chartEntity = {
          data: c,
        };
        toReturn.push(chartEntity);
      });
      return toReturn;
    }

    if (this.props.isLoading) {
      return (
        <>
          <Row>
            <Col>
              <ResultsBreadcrumbs
                surveyName={
                  localization('locale') === 'cz'
                    ? this.props.survey.surveyCz
                    : this.props.survey.surveyEn
                }
                semesterCode={this.props.semester}
                facultyCode={this.props.faculty}
              />
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Spinner color='primary' />
          </Row>
        </>
      );
    } else {
      return (
        <>
          <ScrollUpButton style={{top: 30, zIndex: 1500}} />

          <Row>
            <Col>
              <ResultsBreadcrumbs
                surveyName={
                  localization('locale') === 'cz'
                    ? this.props.survey.surveyCz
                    : this.props.survey.surveyEn
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='float-right'>
                <div style={{display: 'inline-flex'}}>
                  {/* <SemesterCourseDropdowns /> */}
                  {/* <HelpModal
                  className='ml-3'
                  markdownContent={localization('courses_help_markdown')}
                /> */}
                </div>
              </div>

              <h2>
                {localization('locale') === 'cz'
                  ? this.props.survey.surveyCz
                  : this.props.survey.surveyEn}
              </h2>
            </Col>
          </Row>
          {this.props.survey.questions.map((s) =>
            s.statisticQuestion ? (
              <Row className='mt-3'>
                <Col>
                  <Card>
                    <CardHeader>
                      <FontAwesomeIcon
                        className='mr-2'
                        size='lg'
                        icon={faChartPie}
                      />
                      {localization('locale') === 'cz' ? s.shortCz : s.shortEn}
                      {/* <CardTooltip
                      content={localization('tooltip_teacher_statistics')}
                      className='float-right'
                      id='tooltip-statistics'
                    /> */}
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          <Chart
                            options={options}
                            series={[
                              {
                                name: `${localization('number')}`,
                                data: s.statisticQuestion.rating,
                              },
                            ]}
                            type='bar'
                            height='350'
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              ''
            )
          )}

          <Row className='mt-3'>
            <Col>
              <Card id='questions'>
                <CardHeader>
                  <FontAwesomeIcon
                    icon={faCommentDots}
                    size='lg'
                    className='mr-2'
                  />
                  {localization('course_detail_questions')}
                  <span className='float-right'>
                    <Button
                      onClick={() => {
                        this.setState({
                          showAll: true,
                        });
                      }}
                      color='primary'
                      size='sm'
                      disabled={this.state.showAll}
                      className=''
                    >
                      {localization('show_all')}
                    </Button>
                    {/* <CardTooltip
                      content={localization('tooltip_course_questions')}
                      id='tooltip-course-questions'
                    /> */}
                  </span>
                </CardHeader>
                <CardBody>
                  {this.props.survey.questions.length !== 0
                    ? this.props.survey.questions.map((q, i) =>
                        !q.textAnswer ? null : (
                          <Row
                            className='mt-0'
                            id={`question-${i}`}
                            key={`question-${i}`}
                          >
                            <Col>
                              <QuestionTab
                                showAll={this.state.showAll}
                                onChangeVote={this.props.onChangeVote}
                                question={
                                  localization('locale') === 'cz'
                                    ? q.shortCz
                                    : q.shortEn
                                }
                                answers={
                                  q.textAnswer ? q.textAnswer.textAnswer : []
                                }
                                onAddReaction={(...args) => {
                                  this.props.onAddReaction(...args, q.questionKey)
                                }}
                                questionNumber={i}
                                isTeacher={this.props.isRepresentative}
                                semesterCode={null}
                                survey={null}
                                noncoursalQuestionId={q.questionKey}
                                onDelete={(...args) => {
                                  this.props.onDeleteReaction(...args, q.questionKey)
                                }}
                                userName={this.props.userName}
                                noVoting
                              />
                            </Col>
                          </Row>
                        )
                      )
                    : ''}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      );
    }
  }
}

export default NonCoursal;
