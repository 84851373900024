import * as ActionTypes from '../Actions/actionTypes';
import Axios from 'axios';
import { localeKeys, LOCALIZEDPROPERTIES, localize } from '../lib/localization';
import { dangerAction } from '../Actions/alertActions';

export const getSurveys = dispatch => {
    return () => {
        dispatch({
            type: ActionTypes.SURVEYS_LOADING
        });
        return Axios.get('/surveys')
        .then(res => {
            const converted = res.data.data.map(mapSurvey);
            dispatch({
                type: ActionTypes.SURVEYS_LOADED,
                data: converted
            });
        })
        .catch( err => {
            if(err.response.status === 401){
                dispatch(dangerAction(localize("alerts_not_authenticated")))
            }
            else {
                dispatch(dangerAction(localize("alerts_connection_failure")));
            }
        });
    }
}

const mapNumber = n => n.toString();

const mapSurvey = data => {
    const survey = {
        id: data.surveyId.toString() + data.semesterCode,
        surveyId: data.surveyId.toString(),
        semesterCode: data.semesterCode,
        type: data.type,
        subjectToRateIds: data.courseIds.map(mapNumber),
        subjectRatedIds: [],
        questionToRateIds: data.questionIds.map(mapNumber),
        questionRatedIds: [],
        publicOptions: data.publicOptions.map(mapPublicOptions)
    }

    survey[LOCALIZEDPROPERTIES] = {};

    survey[LOCALIZEDPROPERTIES][localeKeys.cz] = {
        shortName: data.shortNameCz,
        longName: data.nameCz,
    }

    survey[LOCALIZEDPROPERTIES][localeKeys.en] = {
        shortName: data.shortNameEn,
        longName: data.nameEn,
    }
    return survey;
}

const mapPublicOptions = options => {
    return {
        id: options.id.toString(),
        defaultValue: options.defaultValue
    }
}

export const sendSubject = dispatch => subject => {
    Axios.post("/surveys/courses", subject).then(res => {
        getSurveys(dispatch)();
    });
}