const config = {
  //change on deploy

  urlSubDirectory: process.env.REACT_APP_URL_SUBDIR,

  defaultUser: process.env.REACT_APP_DEFAULT_USER,
  environmentType: process.env.REACT_APP_ENVIRONMENT_TYPE,
  apiRoot: process.env.REACT_APP_BASE_URL,
  ssoLoginUrl: process.env.REACT_APP_SSO_LOGIN_URL,
  // footer links
  devContact: 'mailto:valenta@fit.cvut.cz?subject=[nova-anketa]',
  help: 'https://gitlab.fit.cvut.cz/anketa_cvut/nova-anketa-doc/wikis/home',

  // data for backend compatibility
  courseSurveyTypeName: 'course',
  generalSurveyTypeName: 'general',
  queryTokenName: 'token',
};

export default config;
